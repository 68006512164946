<template>
  <div class="icon-card-component">
    <div class="icon-card-content">
      <div class="content-title" v-if="setting.title">
        <h2>{{ setting.title }}</h2>
      </div>
      <div class="icon-card-list">
        <template v-for="(item, index) in setting.iconCardsData">
          <div
            class="icon-card-item wow fadeInRight"
            :key="index"
            :data-wow-delay="`${0.1 * (index + 1)}s`"
          >
            <div class="card-content">
              <div class="icon">
                <img :src="item.iconUrl" />
              </div>
              <p class="desc">
                {{ item.desc }}
              </p>
              <span>{{ formatIndex(index + 1, 2) }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconCard",
  props: {
    setting: {
      type: [Array, Object],
      default: null,
    },
  },
  methods: {
    formatIndex(num, index, str = "0") {
      return Array(index - String(num).length + 1).join(str) + num;
    },
  },
};
</script>

<style scoped>
.icon-card-component {
  background-color: #ffffff;
  padding: 1rem;
  position: relative;
}
.icon-card-content .content-title h2 {
  font-size: var(--h1title-size);
  font-weight: bold;
  color: #222222;
}
.icon-card-list {
  margin-top: 0.5rem;
  display: flex;
}
.icon-card-list .icon-card-item {
  padding: 0.3rem;
  width: 25%;
  box-sizing: border-box;
  height: 100%;
}
.icon-card-list .icon-card-item .card-content {
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(16, 32, 115, 0.1);
  border-radius: 0.2rem;
  padding: 0.5rem;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  font-size: 0;
  height: 3rem;
}
.card-content .icon {
  text-align: left;
}
.card-content .icon img {
  height: 0.7rem;
  width: auto;
  max-width: 100%;
}
.card-content .desc {
  margin-top: 0.45rem;
  font-size: var(--text-size);
  font-weight: 400;
  color: #333333;
}
.card-content span {
  font-size: 0.6rem;
  font-weight: bold;
  color: #f2f2f2;
  line-height: 1rem;
  position: absolute;
  right: 0.2rem;
  bottom: -0.38rem;
}

@media all and (max-width: 960px) {
  .icon-card-list {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  .icon-card-list .icon-card-item {
    padding: 0.3rem;
    width: 50%;
    box-sizing: border-box;
    height: 100%;
  }
  .card-content .icon img {
    height: 1.5rem;
  }
  .icon-card-list .icon-card-item .card-content {
    height: 8rem;
  }
  .card-content span {
    font-size: 1.5rem;
  }
}
</style>
