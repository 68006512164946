<template>
  <div class="page-banner">
    <img :src="imageUrl" mobilehide />
    <img :src="smallImageUrl ? smallImageUrl : imageUrl" pchide />
    <div class="banner-text wow fadeInUp" v-if="bannerText">
      <p>{{ bannerText }}</p>
    </div>
    <div class="crumbs">
      <ul class="wow fadeInLeft">
        <li class="icon">
          <svg-icon name="home"></svg-icon>
        </li>
        <li class="label-hover" @click="$router.replace({ path: `/` })">
          HomePage
        </li>
        <li class="icon">
          <svg-icon name="right"></svg-icon>
        </li>
        <li class="label-hover" @click="handelToback">News Center</li>
        <li class="icon">
          <svg-icon name="right"></svg-icon>
        </li>
        <li>Details</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "detailBanner",
  props: {
    imageUrl: {
      type: String,
      require: true,
    },
    smallImageUrl: {
      type: String,
      default: null,
    },
    bannerText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showImgUrl: this.imageUrl,
      levelList: null,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
  },
  created() {
    // this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      let matchedList = [{ path: "/", meta: { title: "首页" } }].concat(
        matched
      );
      this.levelList = matchedList.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    handelToback() {
      this.$emit("backPage");
    },
  },
};
</script>

<style scoped>
.page-banner {
  display: block;
  font-size: 0;
  position: relative;
  overflow: hidden;
}
.page-banner img {
  width: 100%;
}
.page-banner .banner-text {
  font-family: DINPro;
  font-size: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  color: #ffffff;
  transform: translate(-50%, -50%);
}
.crumbs ul {
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  left: 4.1rem;
  bottom: 2.5rem;
}
.crumbs li {
  color: #ffffff;
  font-size: var(--text-size);
  margin: 0 0.03rem;
}
@media all and (max-width: 960px) {
  .crumbs ul {
    bottom: 0.5rem;
    left: 1rem;
  }
}
</style>
