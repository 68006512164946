<template>
  <div class="function-display">
    <item-title
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="display-image wow fadeInUp" v-if="setting.imageUrl">
      <img :src="setting.imageUrl" />
    </div>
    <div
      class="display-img-list"
      v-if="setting.imageInfoList && !setting.imageUrl"
    >
      <template v-for="(item, index) in setting.imageInfoList">
        <div class="display-img-item" :key="item.imageUrl + index">
          <img :src="item.imageUrl" :alt="item.title" :title="item.title" />
          <p>{{ item.title }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "FunctionDisplay",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.function-display {
  background: #f9f9f9;
  padding: 1.6rem 1rem 1.5rem 1rem;
  overflow: hidden;
  margin-top: -1px;
}
.function-display .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.function-display .title h2 {
  font-size: 0.4rem;
  font-weight: 400;
  color: #222222;
}
.function-display .title span {
  font-size: 0.16rem;
  font-family: DINPro;
  font-weight: 400;
  color: #888888;
  position: relative;
}
.function-display .title span:after {
  content: "";
  position: absolute;
  left: -1.2rem;
  width: 1rem;
  height: 1px;
  background: #000000;
  opacity: 0.2;
  top: 50%;
  transform: translateY(-50%);
}
.display-image {
  margin-top: 0.8rem;
  text-align: center;
}
.display-image img {
  max-width: 100%;
}
.display-img-list {
  display: flex;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  align-items: end;
}
.display-img-list .display-img-item {
  text-align: center;
  margin: 0 0.25rem;
}
.display-img-list .display-img-item img {
  max-width: 100%;
}
.display-img-list .display-img-item p {
  font-size: var(--text-size);
  margin-top: 0.5rem;
}
@media all and (max-width: 960px) {
  .display-img-list {
    flex-direction: column;
    align-items: center;
  }
  .display-img-list .display-img-item {
    text-align: center;
    margin: 0.5rem 0;
  }
}
</style>
