import { getProjectList } from "@/api/public";

export default {
  namespaced: true,
  state: () => ({
    projectPageData: {
      projectList: [],
      projectListTotal: 0,
      projectListType: 0,
    },
  }),
  mutations: {
    setProjectPageData(state, { list, total, type }) {
      state.projectPageData.projectList = list;
      state.projectPageData.projectListTotal = total;
      state.projectPageData.projectListType = type;
    },
  },
  actions: {
    getProjectPageData({ commit, rootState }, reqData) {
      rootState.pageLoading = true;
      return getProjectList(reqData)
        .then((res) => {
          let { data } = res;
          commit("setProjectPageData", { ...data, type: reqData.type });
        })
        .finally(() => {
          rootState.pageLoading = false;
        });
    },
  },
  getters: {
    projectList: (state) => state.projectPageData.projectList,
    projectListType: (state) => state.projectPageData.projectListType,
    projectListTotal: (state) => state.projectPageData.projectListTotal,
  },
};
