<template>
  <div class="principle-explain">
    <item-title
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <p class="desc-text wow fadeInUp">
      {{ setting.descText }}
    </p>
    <div class="image-group wow fadeInUp">
      <div class="image-info">
        <template v-for="(imgItem, imgIndex) in setting.ExplainImageInfoArr">
          <div
            class="image-item"
            :key="imgItem.desc + imgIndex"
            :style="{ width: `${imgItem.percent}%` }"
          >
            <img :src="imgItem.imgUrl" />
            <p>{{ imgItem.desc }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "PrincipleExplain",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      rowNum: 0,
    };
  },
  created() {
    this.rowNum = Math.ceil(this.setting.ExplainImageInfoArr.length / 2);
  },
};
</script>

<style scoped>
.principle-explain {
  background: #f9f9f9;
  padding: 0.8rem 1rem;
  overflow: hidden;
}
.principle-explain .desc-text {
  font-size: var(--text-size);
  font-weight: 400;
  color: #222222;
  margin-top: 0.7rem;
}

.image-info {
  margin-top: 1rem;
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}
.principle-explain .image-item {
  text-align: center;
  /*margin-right: 1rem;*/
  margin-bottom: 1rem;
}
.principle-explain .image-item:nth-child(2) {
  margin-right: 0;
}
.principle-explain .image-item img {
  display: block;
  width: 100%;
}
.principle-explain .image-item p {
  margin-top: 0.4rem;
  font-size: var(--subtext-size);
  font-weight: 400;
  color: #222222;
  line-height: 1.5;
}
</style>
