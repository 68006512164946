<template>
  <a-spin :spinning="loading" style="font-size: 0">
    <button class="fl-button" @click="handelClick" :type="type">
      <slot></slot>
    </button>
  </a-spin>
</template>

<script>
export default {
  name: "FlButton",
  props: {
    type: {
      type: String,
      default: "button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handelClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.fl-button {
  min-width: 1.5rem;
  border-radius: 0.25rem;
  padding: 0.12rem 0.23rem;
  font-size: 0.16rem;
  background-color: #2178c9;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.15s ease-in;
}
.fl-button:hover {
  background-color: #1962a6;
}

@media screen and (max-width: 1200px) {
  .fl-button {
    min-width: 2rem;
    font-size: 0.3rem;
    border-radius: 0.6rem;
  }
}
@media screen and (max-width: 960px) {
  .fl-button {
    min-width: 5rem;
    font-size: 0.6rem;
    border-radius: 0.6rem;
  }
}
@media screen and (max-width: 750px) {
  .fl-button {
    min-width: 6rem;
    font-size: 0.6rem;
  }
}
</style>
