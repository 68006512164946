<template>
  <div class="describe-card" :class="setting.background ? 'back' : ''">
    <item-title
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="card-list">
      <template v-for="(item, index) in setting.DescribeCardData">
        <div
          class="card-item wow fadeInRight"
          :key="index"
          :data-wow-delay="`${0.1 * (index + 1)}s`"
        >
          <img :src="item.imgUrl" />
          <p>
            {{ item.desc }}
          </p>
          <span>{{ formatIndex(index + 1, 2) }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "DescribeCard",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  methods: {
    formatIndex(num, index, str = "0") {
      return Array(index - String(num).length + 1).join(str) + num;
    },
  },
};
</script>

<style scoped>
.describe-card {
  overflow: hidden;
  padding: 0.5rem 1rem 0.8rem 1rem;
}
.describe-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.describe-card-title h2 {
  font-size: 0.4rem;
  font-weight: 400;
  color: #222222;
}
.describe-card-title span {
  font-size: 0.16rem;
  font-family: DINPro;
  font-weight: 400;
  color: #888888;
  position: relative;
}
.describe-card-title span:after {
  content: "";
  position: absolute;
  left: -1.2rem;
  width: 1rem;
  height: 1px;
  background: #000000;
  opacity: 0.2;
  top: 50%;
  transform: translateY(-50%);
}
.card-list {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-between;
}
.card-list .card-item {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 0.3rem;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: calc(100% / 4 - 0.25rem);
}
.card-list .card-item:hover {
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(184, 194, 230, 0.25);
}
.card-list .card-item img {
  height: 0.68rem;
}
.card-list .card-item p {
  font-size: var(--text-size);
  font-weight: 400;
  color: #333333;
  margin-top: 0.3rem;
  flex: 1;
}
.card-list .card-item span {
  font-size: 0.6rem;
  font-family: DINPro;
  font-weight: 400;
  color: #333333;
  margin-top: 0.2rem;
}
@media all and (max-width: 960px) {
  .card-list {
    flex-wrap: wrap;
  }
  .card-list .card-item {
    height: auto;
    max-width: calc(100% / 2 - 0.2rem);
    flex: 1 1 50%;
    margin-top: 0.5rem;
  }
  .card-list .card-item img {
    height: 1.2rem;
  }
  .card-list .card-item span {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
}
</style>
