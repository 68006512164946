<template>
  <li
    class="menu-item"
    :class="
      itemData.children && itemData.children.length > 0 ? 'has-children' : ''
    "
  >
    <menu-item
      :item-data="itemData"
      @iconClick="handleClickIcon"
      @clickLink="handleClickLink"
      @clickLabel="handleClickLabel"
      :down="mobileTransition"
    ></menu-item>
    <template v-if="itemData.children && itemData.children.length > 0">
      <menu-transition :isMobile="mobile">
        <ul class="sub-menu" v-show="mobileTransition">
          <template v-for="(item, index) in itemData.children">
            <SubMenu :item-data="item" :key="item.id" :index="index" />
          </template>
        </ul>
      </menu-transition>
    </template>
  </li>
</template>

<script>
import MenuItem from "@/components/NavMenu/MenuItem";
import menuTransition from "@/components/NavMenu/menuTransition";
import { mapGetters } from "vuex";
export default {
  name: "SubMenu",
  props: {
    itemData: {
      type: Object,
      require: true,
    },
  },
  components: {
    MenuItem,
    menuTransition,
  },
  data() {
    return {
      mobileTransition: false,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
    mobile() {
      return this.windowsWidth <= 960;
    },
  },
  watch: {
    windowsWidth(newData) {
      if (newData <= 960) {
        this.mobileTransition = false;
      } else {
        this.mobileTransition = true;
      }
    },
  },
  mounted() {
    if (this.windowsWidth <= 960) {
      this.mobileTransition = false;
    } else {
      this.mobileTransition = true;
    }
  },
  methods: {
    handleClickIcon() {
      this.mobileTransition = this.mobileTransition ? false : true;
    },
    handleClickLabel(item) {
      location.href = item.menuRoute;
    },
    handleClickLink(item) {
      if (this.mobile) {
        if (item.children && item.children.length > 0) {
          this.mobileTransition = this.mobileTransition ? false : true;
        } else {
          location.href = item.menuRoute;
        }
      } else {
        location.href = item.menuRoute;
      }
    },
  },
};
</script>

<style scoped></style>
