<template>
  <div class="core-team-component">
    <div class="management-team" id="management-team">
      <item-title
        class="title-box"
        :title="setting.title"
        :sub-title="setting.subTitle"
      ></item-title>
      <div class="content-box">
        <div class="management-team-swiper">
          <swiper
            ref="mySwiper"
            :options="coreTeamSwiperOptions"
            @click-slide="handleClickSlide"
          >
            <template v-for="(item, index) in setting.leaders">
              <swiper-slide :key="index">
                <div class="team-list-item">
                  <img :src="item.portrait" alt="" srcset="" />
                  <div class="leader-box">
                    <h1>
                      {{ item.name }}<i>{{ item.position }}</i>
                    </h1>
                    <p v-html="item.describe"></p>
                  </div>
                </div>
              </swiper-slide>
            </template>
          </swiper>
          <div class="change-button-box">
            <i class="change-button-left wow fadeInLeft"
              ><svg-icon name="left"></svg-icon
            ></i>
            <i class="change-button-right wow fadeInRight"
              ><svg-icon name="right"></svg-icon
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "CoreTeam",
  components: {
    Swiper,
    SwiperSlide,
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      coreTeamSwiperOptions: {
        slidesPerView: "auto",
        loopedSlides: 3,
        centeredSlides: true,
        loop: true,
        navigation: {
          prevEl: ".change-button-left",
          nextEl: ".change-button-right",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    handleClickSlide(index) {
      this.swiper.slideTo(index);
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.management-team .content-box {
  position: relative;
}
.management-team-swiper {
  position: relative;
  width: 100%;
  padding-bottom: 0.8rem;
}
.management-team-swiper .swiper-slide {
  width: auto;
  transform: scale(1);
  transition: all 0.2s linear;
}

.management-team-swiper .swiper-slide.swiper-slide-prev {
  transform: scale(0.83) translateX(0.2rem);
}
.management-team-swiper .swiper-slide.swiper-slide-next {
  transform: scale(0.83) translateX(-0.2rem);
}

.team-list-item {
  height: 6rem;
  width: 12rem;
  background-color: #fff;
  transition: all 0.3s ease-in;
  transform: scale(1);
  border-radius: 0.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 0.3rem rgb(0 0 0 / 10%);
  z-index: 2;
  opacity: 1;
  margin: 1rem 0;
}
.team-list-item > img {
  height: 100%;
}
.team-list-item > .leader-box {
  margin-left: 0.3rem;
  padding-right: 0.2rem;
}
.team-list-item > .leader-box h1 {
  font-size: var(--h3title-size);
  font-weight: 400;
  color: #222;
  margin-bottom: 1.04167vw;
}
.team-list-item > .leader-box h1 i {
  font-style: normal;
  font-size: var(--text-size);
  margin-left: 0.2rem;
}
.team-list-item > .leader-box p {
  font-size: var(--text-size);
  color: #888;
}
.management-team-swiper .change-button-box {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 5;
  width: 12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.3rem;
}
.management-team-swiper .change-button-box i {
  height: 0.8rem;
  width: 0.8rem;
  cursor: pointer;
  text-align: center;
  line-height: 0.8rem;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 0.2s linear;
  color: #cccccc;
  position: relative;
}
.management-team-swiper .change-button-box i:hover {
  color: #ffffff;
  background-color: #2178c9;
}
.change-button-box .change-button-left {
  left: -0.4rem;
}

.change-button-box .change-button-right {
  right: -0.4rem;
}
@media all and (max-width: 1200px) {
  .team-list-item {
    width: 15.2rem;
  }
  .management-team-swiper .change-button-box {
    width: 15.2rem;
  }
}
@media all and (max-width: 960px) {
  .team-list-item {
    width: 100%;
    height: auto;
  }
  .team-list-item > img {
    width: 100%;
    height: auto;
  }

  .management-team-swiper .swiper-slide {
    width: 60%;
    transform: scale(1);
    transition: all 0.2s linear;
  }
  .management-team-swiper .change-button-box {
    display: none;
  }

  .team-list-item {
    flex-direction: column;
  }
  .team-list-item img {
    width: 100%;
  }
  .team-list-item > .leader-box {
    width: 100%;
    margin: 0;
    padding: 0.5rem;
    box-sizing: border-box;
  }
}
@media all and (max-width: 768px) {
  .management-team-swiper .swiper-slide {
    width: 80%;
  }
  .team-list-item > .leader-box p{
    font-size: 0.3rem;
  }
}
</style>
