<template>
  <div
    class="main-header"
    :class="{
      'top-fixed': topFixed,
      'absolute-position': positionStatus,
      'open-menu': openMenu,
    }"
  >
    <div class="header-logo">
      <a href="/" title="Home">
        <img
          :src="
            topFixed || openMenu
              ? logoImageUrls.colorLogo
              : logoImageUrls.whiteLogo
          "
          class="logo"
        />
      </a>
    </div>
    <div class="mobile-control-btn">
      <svg-icon
        :name="openMenu ? 'menu-close-btn' : 'menu-btn'"
        @click="handleControlMenu(false)"
      ></svg-icon>
    </div>
    <div class="header-menu">
      <nav-menu
        :menu-data="menuData"
        :topFixed="topFixed"
        @mobileMenuChange="changeHeaderStatus"
      ></nav-menu>
    </div>
  </div>
</template>

<script>
import NavMenu from "@/components/NavMenu/NavMenu";
import { mapGetters } from "vuex";
export default {
  name: "MainHeader",
  components: { NavMenu },
  props: {
    topFixed: {
      type: Boolean,
      default: true,
    },
    positionStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openMenu: false,
    };
  },
  computed: {
    ...mapGetters(["menuData", "logoImageUrls", "windowsWidth"]),
  },
  watch: {
    windowsWidth(newData) {
      if (newData > 960) {
        this.openMenu = false;
        this.changeHeaderStatus(false);
      }
    },
  },
  mounted() {},
  methods: {
    handleControlMenu() {
      this.openMenu = !this.openMenu;
      // this.showMask = is;
      this.changeHeaderStatus(this.openMenu);
    },
    changeHeaderStatus(open) {
      // this.openMenu = open;
      // this.$store.dispatch("changeShowMask", open);
      if (this.$isServer) return;
      if (open) {
        document.body.classList.add("noscroll");
      } else {
        document.body.classList.remove("noscroll");
      }
    },
  },
};
</script>

<style scoped>
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.15rem 1rem;
  box-sizing: border-box;
  position: relative;
  left: 0;
  right: 0;
  top: 0.6rem;
  z-index: 9;
  transition: background-color 0.1s linear;
}
.main-header.absolute-position {
  position: absolute;
}
.main-header.top-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.main-header .header-logo {
  font-size: 0;
}
.main-header .header-logo a {
}
.main-header .header-logo a img {
  max-width: 2.2rem;
}

.mobile-control-btn {
  font-size: 0.88rem;
  display: none;
  color: #ffffff;
  cursor: pointer;
}
.mobile-control-btn svg {
  padding: 0 0.5rem;
  box-sizing: content-box;
}
.open-menu .mobile-control-btn svg,
.top-fixed .mobile-control-btn svg {
  color: #333333;
}
@media all and (max-width: 1200px) {
  .main-header .header-logo a img {
    max-width: 3rem;
  }
  .main-header {
    padding: 0.15rem 0.5rem;
  }
}
@media all and (max-width: 960px) {
  .main-header {
    height: 80px;
    padding: 7px 50px;
    box-sizing: border-box;
    top: 0px;
  }
  .main-header.open-menu {
    background: #ffffff;
  }
  .main-header .header-logo a img {
    max-width: 150px;
  }
  .main-header .header-logo {
    /*height: 100%;*/
  }
  .main-header .header-logo a {
  }
  .mobile-control-btn {
    display: block;
    font-size: 30px;
  }
  .header-menu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    overflow: hidden;
    height: 0;
    transition: height 0.3s linear;
  }
  .main-header.open-menu .header-menu {
    height: calc(100vh - 80px);
    overflow-y: auto;
  }
}
@media all and (max-width: 750px) {
  .main-header {
    height: 80px;
    padding: 7px 15px;
    box-sizing: border-box;
  }
  .mobile-control-btn {
    font-size: 30px;
  }
}
</style>
