<template>
  <div class="error-500">
    <div class="error-contant">
      <img src="../../assets/images/logo-black.png" />
      <div class="err-code">500</div>
      <div class="err-text">
        An unknown error occurred.<br />You can click the button below to return to the home page.
      </div>
      <FlButton @click="handelToHome">Home</FlButton>
    </div>
  </div>
</template>

<script>
import FlButton from "@/components/FlButton/FlButton.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Error500",
  components: { FlButton },
  methods: {
    handelToHome() {
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
.error-500 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-contant {
  max-width: 50%;
  text-align: center;
  font-size: 16px;
}
.error-contant .err-code {
  font-size: 36px;
  font-weight: 600;
  color: #135294;
  margin: 10px 0;
}
.error-contant .err-text {
  margin: 10px 0;
}
</style>
