<template>
  <div class="product-center">
    <div class="text-box wow fadeInLeft">
      <p>{{ setting.subTitle }}</p>
      <p>{{ setting.title }}</p>
      <p>{{ setting.desc }}</p>
    </div>
    <div class="content-box">
      <ul>
        <template v-for="(item, index) in setting.productList">
          <li
            :data-wow-delay="`${0.2 * index}s`"
            class="content-item wow fadeInRight"
            :key="index"
            @click="handleToProduct(item)"
          >
            <div
              class="pic-box"
              :style="{ backgroundImage: `url(${item.imageUrl})` }"
            ></div>
            <div class="name">
              <p>
                <span>{{ item.title }}</span>
              </p>
              <p>
                <img src="../../../../assets/images/right-arrow.png" />
              </p>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCenter",
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      productScrollTimer: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleToProduct(item) {
      if (item.href) {
        this.$router.push(item.href);
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.product-center {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.product-center .text-box {
  width: calc(100vw * 0.3);
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 0.2rem;
  display: flex;
  flex-direction: column;
}
.product-center .text-box p:nth-child(1) {
  color: #888888;
  font-size: var(--entitle-size);
  font-family: "en-regular";
}
.product-center .text-box p:nth-child(2) {
  color: #222222;
  font-size: var(--h1title-size);
  font-weight: bold;
  margin-top: 0.18rem;
  margin-bottom: 0.28rem;
}
.product-center .text-box p:nth-child(3) {
  color: #2178c9;
  font-size: var(--text-highlight-size);
  font-weight: bold;
}
.content-box ul {
  white-space: nowrap;
  transform: translateX(0px);
  transition: all 0.15s ease-in;
  margin-bottom: 0;
}
.content-box ul .content-item {
  display: inline-block;
  overflow: hidden;
  background-color: #f8f8f8;
  color: #333333;
  cursor: pointer;
  justify-content: center;
}
.content-box ul .content-item .pic-box {
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: all 0.2s ease-in;
}
.content-box ul .content-item:hover .pic-box {
  transform: scale(1.1);
}
.content-box ul .content-item .name {
  width: 100%;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 8.89%;
  position: relative;
  height: 17.78%;
  font-size: var(--text-size);
}
.content-box ul .content-item .name p {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: inherit;
  transition: all 0.15s ease-in;
}
.content-box ul .content-item .name p:nth-child(1) {
  position: relative;
  left: 0;
}
.content-box ul .content-item .name p:nth-child(2) {
  display: none;
  position: relative;
  right: 0;
}
.content-box ul .content-item .name p:nth-child(2) img {
  margin-top: 3px;
  margin-left: 10px;
}

.product-center .content-box {
  width: calc(100vw - 100vw * 0.2);
  overflow: hidden;
}

.content-box ul .content-item {
  width: calc(100vw * 0.231);
  height: calc(100vw * 0.231);
  margin-right: calc(100vw * 0.01);
  border-radius: calc(100vw * 0.01);
}
.content-box ul .content-item:hover {
  color: #ffffff;
}
.content-box ul .content-item:hover .name {
  background-color: #2178c9;
}
.content-box ul .content-item:hover .name p:nth-child(1) {
  position: absolute;
  left: 2.89%;
}
.content-box ul .content-item:hover .name p:nth-child(2) {
  display: flex;
  position: absolute;
  right: 2.89%;
}
.content-box ul .content-item .pic-box {
  width: 100%;
  height: 82.22%;
}
.content-box ul .content-item .name p:nth-child(2) img {
  width: 0.15rem;
}
@media screen and (max-width: 1236px) {
  .content-box ul {
    overflow-x: auto;
  }
  .content-box ul::-webkit-scrollbar {
    display: none;
  }
  .content-box ul .content-item {
    width: calc(100vw * 0.3);
    height: calc(100vw * 0.3);
  }
  .content-box ul .content-item .name p:nth-child(2) img {
    width: 0.2rem;
  }
}
@media screen and (max-width: 960px) {
  .product-center {
    flex-direction: column;
  }
  .product-center .text-box {
    align-items: center;
    width: 100%;
    padding-left: 0rem;
  }

  .product-center .text-box p:nth-child(1) {
    order: 2;
    margin-bottom: 0.1rem;
  }
  .product-center .text-box p:nth-child(2) {
    order: 1;
    margin-top: 0;
    margin-bottom: 0.1rem;
  }
  .product-center .text-box p:nth-child(3) {
    order: 3;
    text-align: center;
  }

  .product-center .content-box {
    margin-top: 1rem;
    width: 100%;
  }

  .content-box ul {
    overflow-x: auto;
  }
  .content-box ul::-webkit-scrollbar {
    display: none;
  }
  .content-box ul .content-item {
    width: calc(100vw * 0.5);
    height: calc(100vw * 0.5);
    margin-left: calc(100vw * 0.03);
    border-radius: calc(100vw * 0.02);
  }
  .content-box ul .content-item:last-child {
    margin-right: calc(100vw * 0.04);
  }
  .content-box ul .content-item .pic-box {
    width: 100%;
    height: 80%;
  }
  .content-box ul .content-item .name {
    height: 20%;
    font-size: 0.3rem;
    /*font-weight: bold;*/
  }
  .content-box ul .content-item .name p:nth-child(2) img {
    width: 0.35rem;
  }
}
</style>
