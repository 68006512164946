<template>
  <div class="product-desc">
    <div class="product-content">
      <div class="desc-text wow fadeInLeft">
        <h1>{{ setting.title }}</h1>
        <p>
          {{ setting.desc }}
        </p>
        <div class="scope" v-if="setting.scope">
          <p class="scope-title">{{ setting.scope.title }}</p>
          <div v-if="setting.scope.infoList" class="scope-info">
            <template v-for="(item, index) in setting.scope.infoList">
              <p class="scope-info-item" :key="item + index">{{ item }}</p>
            </template>
          </div>
        </div>
      </div>
      <div class="desc-images wow fadeInRight" id="descImage">
        <swiper ref="descSwiper" :options="swiperOpting">
          <template v-for="(item, index) in setting.productImages">
            <swiper-slide :key="index">
              <div class="product-image">
                <img :src="item.imgUrl" />
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="control-btn" v-if="setting.productImages.length > 1">
          <div class="left-btn">
            <svg-icon name="left"></svg-icon>
          </div>
          <div class="right-btn">
            <svg-icon name="right"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "ProductDesc",
  components: { Swiper, SwiperSlide },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      descSwiper: null,
    };
  },
  computed: {
    swiperOpting() {
      return {
        navigation: {
          nextEl: ".right-btn",
          prevEl: ".left-btn",
        },
      };
    },
  },
};
</script>

<style scoped>
.product-desc {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
}
.product-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.product-content .desc-text {
  max-width: 6.85rem;
  text-align: left;
  margin-right: 1.49rem;
}
.product-content .desc-text h1 {
  font-size: var(--h1title-size);
  font-weight: bold;
  color: #222222;
}
.product-content .desc-text > p {
  font-size: var(--text-size);
  font-weight: 400;
  color: #888888;
  margin-top: 0.62rem;
}
.product-content .desc-text .scope {
  margin-top: 0.5rem;
  line-height: 1.5;
}
.product-content .desc-text .scope p {
  font-size: var(--text-size);
  color: #888888;
}
.product-content .desc-text .scope p.scope-title {
  font-weight: bold;
  color: #222222;
}

.product-content .desc-images {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/JuSVB8uj.png");
  background-position: center center;
  background-size: 6.96rem 6.96rem;
  background-repeat: no-repeat;
  background-color: #ffffff;
  /*max-width: 10rem;*/
  text-align: center;
  font-size: 0;
  height: 6.96rem;
  width: 6.96rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.product-content .desc-images .product-image {
  height: 6.96rem;
  padding: 1.2rem 0.1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-content .desc-images .product-image img {
  vertical-align: middle;
  /*max-width: 4rem;*/
  max-height: 100%;
  max-width: 100%;
}
.control-btn {
  width: 1.9rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.3rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.2rem;
  color: #2178c9;
  z-index: 5;
}
.control-btn .left-btn,
.control-btn .right-btn {
  width: 0.6rem;
  height: 0.6rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(4, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.left-btn.swiper-button-disabled,
.right-btn.swiper-button-disabled {
  color: #cccccc;
  cursor: no-drop;
}
@media screen and (max-width: 1400px) {
  .product-desc {
    background-color: #ffffff;
    padding: 1rem;
  }
  .product-content {
    flex-direction: column;
    align-items: center;
  }
  .product-content .desc-text {
    margin-right: 0;
    max-width: none;
  }
  .product-content .desc-images {
    background-size: 100% 100%;
    height: 15rem;
    width: 15rem;
    max-width: 15rem;
    max-height: 15rem;
  }
  .product-content .desc-images .product-image {
    height: 15rem;
    padding: 1rem 0.1rem 2rem 0.1rem;
  }

  .control-btn {
    width: 5rem;
    height: 1.3rem;
    font-size: 1rem;
    bottom: 0.5rem;
  }
  .control-btn .left-btn,
  .control-btn .right-btn {
    width: 1.6rem;
    height: 1.6rem;
  }
}
</style>
