<template>
  <div class="cooperative-partner">
    <div class="title-box wow fadeInLeft">
      <p>{{ setting.subTitle }}</p>
      <p>{{ setting.title }}</p>
    </div>
    <ul class="partner-logo">
      <template v-for="(item, index) in setting.partnerList">
        <li class="wow fadeInUp" data-wow-delay="0.2s" :key="item.id">
          <div class="partner-item" :index="index">
            <img :src="item.logoUrl" :alt="item.alt" />
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CooperativePartner",
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      partnerList: [
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/do4KFtiM.png",
          alt: "",
          title: "",
          href: "",
          id: 1,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/Z7bkKqXY.png",
          alt: "",
          title: "",
          href: "",
          id: 2,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/OqWkl7ef.png",
          alt: "",
          title: "",
          href: "",
          id: 3,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/rFJkkXhd.png",
          alt: "",
          title: "",
          href: "",
          id: 4,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/LkTY66iP.png",
          alt: "",
          title: "",
          href: "",
          id: 5,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/xtBvCJg2.png",
          alt: "",
          title: "",
          href: "",
          id: 6,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/yxtgCJfX.png",
          alt: "",
          title: "",
          href: "",
          id: 7,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/HUn4Irqg.png",
          alt: "",
          title: "",
          href: "",
          id: 8,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/cA2mpSVT.png",
          alt: "",
          title: "",
          href: "",
          id: 9,
        },
        {
          logoUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/nMFWIJrs.png",
          alt: "",
          title: "",
          href: "",
          id: 10,
        },
      ],
    };
  },
};
</script>

<style scoped>
.cooperative-partner {
  background-color: #f8f8f8;
  /*align-items: flex-start;*/
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem;
}
.cooperative-partner .title-box {
  width: calc(100vw * 0.151);
  /*margin-top: 1rem;*/
  display: flex;
  flex-direction: column;
}
.cooperative-partner .title-box p:nth-child(1) {
  font-size: var(--entitle-size);
  color: #888888;
  font-family: "DINPro";
}
.cooperative-partner .title-box p:nth-child(2) {
  font-size: var(--h1title-size);
  font-weight: bold;
  color: #222222;
  margin-top: 0.05rem;
}
.cooperative-partner .partner-logo {
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw * 0.74);
  margin-top: 0.54rem;
}
.cooperative-partner .partner-logo li {
  box-sizing: border-box;
  width: 20%;
  padding: 0.1rem 0.1rem;
  box-sizing: border-box;
}
.cooperative-partner .partner-logo li > div {
  height: 1.2rem;
  border-radius: 0.1rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  border: 1px solid #e5e5e5;
  transform: translateY(0);
  transition: all 0.15s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.cooperative-partner .partner-logo li div:hover {
  transform: translateY(-5px);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}
.cooperative-partner .partner-logo li img {
  width: auto;
  transform: scale(1);
  transition: all 0.15s ease-in;
}
.cooperative-partner .partner-logo li:hover img {
  transform: scale(1.1);
}
.cooperative-partner .partner-logo li:nth-child(5n) {
  margin-right: 0;
}

.cooperative-partner .partner-logo li img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 960px) {
  .cooperative-partner {
    display: block;
    padding: 0 0.5rem 2rem 0.5rem;
    margin-top: -1px;
  }
  .cooperative-partner .title-box {
    width: 100%;
    align-items: center;
    margin-top: 0;
  }
  .cooperative-partner .title-box p:nth-child(1) {
    order: 2;
  }
  .cooperative-partner .title-box p:nth-child(2) {
    order: 1;
  }

  .cooperative-partner .partner-logo {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0.54rem;
  }

  .cooperative-partner .partner-logo li {
    width: 50%;
    padding: 0.2rem;
    overflow: hidden;
  }
  .cooperative-partner .partner-logo li > div {
    border: 1px solid #f5f5f5;
    border-radius: 0.2rem;
    overflow: hidden;
    height: 3rem;
  }
}
</style>
