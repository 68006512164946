import {
  getMenu,
  getProjectDetail,
  getNewsDetail,
  getRecruitList,
  getHonorList,
  getProjectTypeList,
  getNewsGroupList,
  getConfigApi,
} from "@/api/public";
import { toTreeeData } from "@/utils/util";

export default {
  state: () => {
    return {
      configData: null,
      menuData: [],
      projectDetail: {},
      newsDetail: null,
      recruitList: [],
      // 企业荣耀
      honorList: [],
      // 首页典型案例
      homeCaseList: null,
      // 首页新闻模块
      businessNews: null,
      industryNews: null,
      swiperNews: null,
    };
  },

  mutations: {
    setConfigData(state, configData) {
      state.configData = configData;
    },
    setMenuData(state, menuData) {
      state.menuData = menuData;
    },
    setProjectDetail(state, body) {
      state.projectDetail = body;
    },
    setNewsDetail(state, body) {
      state.newsDetail = body;
    },
    setRecruitList(state, list) {
      state.recruitList = list;
    },
    setHonorList(state, list) {
      state.honorList = list;
    },
    setHomeCaseList(state, data) {
      state.homeCaseList = data;
    },
    setSwiperNews(state, data) {
      state.swiperNews = data;
    },
    setIndustryNews(state, data) {
      state.industryNews = data;
    },
    setBusinessNews(state, data) {
      state.businessNews = data;
    },
  },

  actions: {
    getConfig({ commit }) {
      return getConfigApi().then((res) => {
        let { data } = res;
        commit("setConfigData", data);
      });
    },
    getMenu({ commit }) {
      return getMenu().then((res) => {
        let { data } = res;
        let treeMenuData = toTreeeData(data, 0);
        commit("setMenuData", treeMenuData);
      });
    },
    getProjectDetail({ commit }, data) {
      return getProjectDetail(data).then((res) => {
        let { data } = res;
        commit("setProjectDetail", data);
      });
    },
    getNewsDetail({ commit }, data) {
      return getNewsDetail(data).then((res) => {
        let { data } = res;
        commit("setNewsDetail", data);
      });
    },
    getRecruitList({ commit }, data) {
      return getRecruitList(data).then((res) => {
        let { data } = res;
        commit("setRecruitList", data);
      });
    },
    getHonorList({ commit }) {
      return getHonorList().then((res) => {
        let { data } = res;
        commit("setHonorList", data);
      });
    },
    getHomeCaseList({ commit }, data) {
      return getProjectTypeList(data).then((res) => {
        let { data } = res;
        commit("setHomeCaseList", data);
      });
    },
    getHomeNewsLists({ commit }, data) {
      return getNewsGroupList(data).then((res) => {
        let { data } = res;
        commit("setSwiperNews", data.swiperNews);
        commit("setIndustryNews", data.industryNews);
        commit("setBusinessNews", data.businessNews);
      });
    },
  },

  getters: {
    getConfigValue: (state) => (key) => {
      let configData = state.configData || {};
      return configData[key];
    },
    configData: (state) => state.configData || {},
    menuData: (state) => state.menuData,
    recruitList: (state) => state.recruitList,
    honorList: (state) => state.honorList,
    projectDetail: (state) => state.projectDetail,
    newsList: (state) => state.newsList,
    newsListTotal: (state) => state.newsListTotal,
    newsDetail: (state) => state.newsDetail,
    homeCaseList: (state) => state.homeCaseList,
    swiperNews: (state) => state.swiperNews,
    industryNews: (state) => state.industryNews,
    businessNews: (state) => state.businessNews,
  },
};
