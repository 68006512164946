import { getNewsList } from "@/api/public";

export default {
  namespaced: true,
  state: () => ({
    newsPageData: {
      newsList: [],
      newsListTotal: 0,
      newsListType: 0,
    },
  }),
  mutations: {
    setNewsPageData(state, { list, total, type }) {
      state.newsPageData.newsList = list;
      state.newsPageData.newsListTotal = total;
      state.newsPageData.newsListType = type;
    },
  },
  actions: {
    getNewsPageData({ commit, rootState }, reqData) {
      rootState.pageLoading = true;
      return getNewsList(reqData)
        .then((res) => {
          let { data } = res;
          commit("setNewsPageData", { ...data, type: reqData.type });
        })
        .finally(() => {
          rootState.pageLoading = false;
        });
    },
  },
  getters: {
    newsList: (state) => state.newsPageData.newsList,
    newsListType: (state) => state.newsPageData.newsListType,
    newsListTotal: (state) => state.newsPageData.newsListTotal,
  },
};
