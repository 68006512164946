<template>
  <div
    class="language_change"
    @mouseenter="handleOpenList"
    @mouseleave="handleCloseList"
  >
    <div class="language_label">
      <svg-icon name="language" class-name="language"></svg-icon>
      <span>English</span>
      <svg-icon name="down" class-name="down"></svg-icon>
    </div>
    <down-transition>
      <div class="language_list" v-if="languageList">
        <ul>
          <li><a href="https://www.fairylands.com.cn">简体中文</a></li>
          <li class="active">
            <a href="https://www.forigin.com.cn">English</a>
          </li>
        </ul>
      </div>
    </down-transition>
  </div>
</template>

<script>
import DownTransition from "@/components/Transition/DownTransition";
import { ref } from "vue";

export default {
  name: "LanguageChange",
  components: { DownTransition },
  setup() {
    const languageList = ref(false);
    const handleOpenList = () => {
      languageList.value = true;
    };
    const handleCloseList = () => {
      languageList.value = false;
    };
    return {
      languageList,
      handleOpenList,
      handleCloseList,
    };
  },
};
</script>

<style scoped>
.language_change {
  position: relative;
  display: inline-block;
  padding: 0.1rem 0.1rem;
  cursor: pointer;
  box-sizing: border-box;
}
.language_change .language_label {
  display: flex;
  align-items: center;
}
.language_change .down {
  margin-left: 0.05rem;
}
.language_change .language {
  margin-right: 0.05rem;
}
.language_change .language_list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 95%;
  background-color: #ffffff;
  box-sizing: border-box;
  /*padding: 10px 5px;*/
  border-radius: 4px;
  box-shadow: 0 1px 1px -2px #00000029, 0 3px 6px #0000001f,
    0 2px 10px 0px #00000017;
  z-index: 9;
  overflow: hidden;
}
.language_change ul,
.language_change li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.language_change li {
  white-space: nowrap;
  text-align: center;
  padding: 0.1rem 0.3rem;
  box-sizing: border-box;
  color: #222222;
  transition: all 0.2s linear;
}
.language_change li.active {
  color: #2178c9;
}

.language_change li:hover {
  background-color: #2178c9;
  color: #ffffff;
}
.language_change li {
  border-bottom: 1px solid #cccccc;
}
.language_change li:last-child {
  border-bottom: none;
}
@media all and (max-width: 960px) {
  .language_change {
    padding: 0.5rem;
  }
  .language_change li {
    padding: 0.3rem 0.5rem;
  }
}
</style>
