<template>
  <div class="home-content">
    <page-component :page-data="pageData.pageContent"></page-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomePage",
  components: {},
  async serverPrefetch() {
    try {
      await this.$store.dispatch("page/getPageContent", {
        path: "/",
      });
    } catch (e) {
      console.log("serverPrefetch:", e);
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("page", ["pageData"]),
  },
  created() {},
  async mounted() {
    if (this.pageData == null || this.pageData.pagePath != "/") {
      await this.$store.dispatch("page/getPageContent", {
        path: "/",
      });
    }
  },

  methods: {},
};
</script>

<style>
.home-content {
  width: 100%;
  overflow-x: hidden;
}
</style>
