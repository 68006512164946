<template>
  <div class="contact-us" id="contact-us">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="contact-content">
      <h1 class="title">{{ setting.contactTitle }}</h1>
      <ul class="contact-info">
        <li
          v-for="(item, index) in setting.contactData"
          :key="index"
          class="wow fadeInUp"
          :data-wow-delay="`${Math.ceil(Math.random() * 0.3)}s`"
        >
          <span>{{ item.title }}</span>
          <span>{{ item.describe }}</span>
        </li>
      </ul>
      <div class="map wow fadeInUp" id="map"></div>
      <ul class="branch-company">
        <template v-for="(item, index) in setting.branchCompanyList">
          <li
            class="wow fadeInUp"
            :data-wow-delay="`${0.25 * (index + 1)}s`"
            :key="index"
          >
            <span>{{ item.subTitle }}</span>
            <h3>{{ item.title }}</h3>
            <p v-html="item.location"></p>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "ContactLocation",
  components: {
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  mounted() {
    this.renderMap();
  },
  methods: {
    renderMap() {
      // eslint-disable-next-line no-undef
      AMapLoader.load({
        key: "2448006cf2ada69127f35adcc30416d6",
        plugins: [],
        AMapUI: {},
        lang: "en",
      })
        .then((AMap) => {
          const map = new AMap.Map("map", {
            zoom: 18,
            center: [113.947632, 22.574864],
            lang: "en",
          });
          const icon = new AMap.Icon({
            size: new AMap.Size(50, 50),
            image:
              "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/company_location_map_icon.png",
            imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(50, 50), // 根据所设置的大小拉伸或压缩图片
          });
          const marker = new AMap.Marker({
            position: new AMap.LngLat(113.947632, 22.574864),
            title: "Forigin Decentralized Water Treatment Co.Ltd.",
            icon,
            anchor: "center",
            offset: new AMap.Pixel(0, 0),
          });

          map.add(marker);
        })
        .catch(() => {
          console.log("图片加载失败");
        });
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.contact-us .contact-content {
  padding: 0 1rem;
}
.contact-us .contact-content .title {
  font-size: var(--h3title-size);
  color: #222222;
  font-weight: normal;
  margin-bottom: 0.6rem;
}
.contact-us .contact-content .contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-us .contact-content .contact-info li {
  width: calc((100% - 1.2rem) / 4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.4rem;
  padding-bottom: 0.2rem;
}
.contact-us .contact-content .contact-info li span {
  font-size: var(--text-size);
  color: #222222;
  font-family: "DINPro";
  display: block;
}
.contact-us .contact-content .contact-info li span:last-child {
  color: #888888;
  margin-top: 0.15rem;
}
.contact-us .contact-content .map {
  height: 6rem;
  margin-top: 0.2rem;
}
.contact-us .contact-content .branch-company {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
}
.contact-us .contact-content .branch-company li {
  width: calc((100% - 1rem) / 3);
  /*height: 4rem;*/
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 0.6rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.contact-us .contact-content .branch-company li:hover {
  box-shadow: 0 0 0.75rem rgba(41, 98, 204, 0.1);
  background-color: #ffffff;
}
.contact-us .contact-content .branch-company li span {
  font-size: var(--text-size);
  color: #888888;
  display: block;
}
.contact-us .contact-content .branch-company li h3 {
  font-size: var(--h4title-size);
  color: #222222;
  font-weight: normal;
  margin-top: calc(100vw / 1920 * 15);
  margin-bottom: calc(100vw / 1920 * 40);
}
.contact-us .contact-content .branch-company li p {
  font-size: var(--text-size);
  color: #888888;
}
@media all and (max-width: 960px) {
  .contact-us .contact-content .contact-info li {
    width: calc((100% - 1.2rem) / 2);
  }
  .contact-us .contact-content .map {
    height: 10rem;
    margin-top: 0.5rem;
  }
  .contact-us .contact-content .branch-company {
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
  }
  .contact-us .contact-content .branch-company li {
    width: 100%;
    height: auto;
    padding: 0.8rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease-in;
    margin-bottom: 0.5rem;
  }
}
</style>
