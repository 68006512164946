<template>
  <div class="system-advantage">
    <img
      class="top-image"
      src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/rAptpZt4.png"
    />
    <div class="advantage-content">
      <item-title
        :title="setting.title"
        :sub-title="setting.subTitle"
        title-color="#ffffff"
        sub-title-color="#ffffff"
      ></item-title>
      <div
        class="advantage-list"
        :style="{
          '--item-size': setting.DescribeCardData.length,
          '--pc-row-size': setting.DescribeCardData.length,
          '--mobile-row-size': Math.floor(setting.DescribeCardData.length / 2),
        }"
      >
        <template v-for="(item, index) in setting.DescribeCardData">
          <div
            class="card-item wow fadeInRight"
            :key="index"
            :data-wow-delay="`${0.1 * (index + 1)}s`"
          >
            <div class="icon" :class="setting.blockTitle ? 'block-title' : ''">
              <img :src="item.imgUrl" />
              <span v-if="item.title">{{ item.title }}</span>
            </div>
            <p>
              {{ item.desc }}
            </p>
            <span>{{ formatIndex(index + 1, 2) }}</span>
          </div>
        </template>
      </div>
    </div>
    <img
      class="bottom-image"
      src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/c6GeequC.png"
      :style="{
        backgroundColor: setting.bottomBackColor
          ? setting.bottomBackColor
          : '#f9f9f9',
      }"
    />
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "SystemAdvantage",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  methods: {
    formatIndex(num, index, str = "0") {
      return Array(index - String(num).length + 1).join(str) + num;
    },
  },
};
</script>

<style scoped>
.system-advantage {
  position: relative;
  font-size: 0;
  --mobile-row-size: 2;
  --pc-row-size: 4;
}
.system-advantage > img {
  width: 100%;
}
.advantage-content {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/ydNZm2we.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  min-height: 5.16rem;
  padding: 0.8rem 1rem 1.5rem 1rem;
  box-sizing: border-box;
  position: relative;
}
.top-image {
  position: relative;
  bottom: -1px;
}
.bottom-image {
  position: relative;
  top: -1px;
}
.advantage-list {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-between;
}
.advantage-list .card-item {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 0.7rem;
  flex: 1;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: calc(100% / var(--pc-row-size) - 0.45rem);
}

.advantage-list .card-item:hover {
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(184, 194, 230, 0.25);
}
.advantage-list .card-item .icon {
  display: flex;
  align-items: center;
  width: 100%;
}
.advantage-list .card-item .icon.block-title {
  flex-direction: column;
  align-items: start;
}
.advantage-list .card-item .icon.block-title > span {
  margin-left: 0;
  margin-top: 0.2rem;
}
.advantage-list .card-item .icon img {
  height: 0.68rem;
}
.advantage-list .card-item .icon > span {
  font-size: var(--text-size);
  margin-left: 0.2rem;
  color: #222222;
  transition: all 0.2s linear;
}
.advantage-list .card-item p {
  font-size: var(--text-size);
  font-weight: 400;
  color: #333333;
  margin-top: 0.3rem;
  flex: 1;
}
.advantage-list .card-item > span {
  font-family: DINPro;
  font-size: 0.6rem;
  font-weight: 400;
  color: #333333;
  margin-top: 0.2rem;
}

.advantage-list .card-item:hover .icon > span {
  color: #2178c9;
}
@media all and (max-width: 960px) {
  .advantage-list {
    flex-wrap: wrap;
  }
  .advantage-list .card-item {
    height: auto;
    /*width: 100%;*/
    max-width: calc(100% / var(--mobile-row-size) - 0.2rem);
    flex: 1 1 calc(100% / var(--mobile-row-size) - 0.2rem);
    margin-top: 0.5rem;
  }
  .advantage-list .card-item .icon img {
    height: 1.2rem;
  }

  .advantage-list .card-item .icon > span {
    margin-left: 0.5rem;
  }
  .advantage-list .card-item > span {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
}
</style>
