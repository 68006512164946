<template>
  <div
    class="content-item-programme"
    :class="setting.contentText ? 'has-content' : 'flex-center'"
  >
    <div class="item-title">
      <h2>{{ setting.title }}</h2>
      <p>
        {{ setting.subTitle }}
      </p>
    </div>
    <div class="item-content">
      <div class="item-content-left wow fadeInLeft" v-if="setting.contentText">
        <div class="left-text" v-html="setting.contentText"></div>
      </div>
      <div class="item-content-right wow fadeInRight" id="programmeSwiper">
        <swiper ref="mySwiper" :options="caseSwiperOpting">
          <template v-for="(item, index) in setting.images">
            <swiper-slide :key="item.url">
              <div class="programme-image-item">
                <img :src="item.url" :index="index" />
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "ProgrammeItem",
  components: { Swiper, SwiperSlide },
  props: {
    setting: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      programmeSwiper: null,
      caseSwiperOpting: {},
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.content-item-programme .item-title h2 {
  font-size: var(--h1title-size);
  color: rgb(255, 255, 255);
}
.content-item-programme .item-title p {
  font-size: var(--text-size);
  color: rgb(255, 255, 255);
  margin-top: 0.25rem;
}
.content-item-programme {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/Pq0n7CVC.jpg");
  background-size: 19.2rem 7.5rem;
  background-color: #1360a9;
  background-repeat: no-repeat;
  background-position: bottom center;
  min-height: 7.5rem;
  width: 100%;
  padding: 0.8rem 1rem;
  box-sizing: border-box;
}
.content-item-programme.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;
}
.item-content-left {
  width: 9.5rem;
  padding: 0.7rem 0;
  /*margin-top: 0.6rem;*/
  position: relative;
}
.item-content-left .left-text {
  margin: 0 1rem;
}
.item-content-left:before {
  content: "“";
  display: block;
  font-family: "Constantia";
  text-align: left;
  font-size: 2rem;
  line-height: 0.15;
  color: rgba(255, 255, 255, 0.102);
  position: absolute;
  left: 0;
  top: 0.8rem;
}
.item-content-left:after {
  content: "”";
  display: block;
  font-family: "Constantia";
  text-align: right;
  font-size: 2rem;
  line-height: 0.15;
  color: rgba(255, 255, 255, 0.102);
  position: absolute;
  right: 0.3rem;
  bottom: -0.3rem;
}

.item-content-left .title {
  font-size: var(--subtitle-size);
  color: rgb(255, 255, 255);
}
.item-content-left .desc {
  font-size: var(--text-size);
  color: rgb(255, 255, 255);
  margin-top: 0.3rem;
  line-height: 1.5;
}
.item-content-left .desc .desc-title {
  font-weight: bold;
}
.item-content-left .desc .desc-explain {
  padding-left: 0.15rem;
  box-sizing: border-box;
  position: relative;
  margin-top: 0.05rem;
}
.item-content-left .desc .desc-explain:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 0.04rem solid transparent;
  border-left: 0.06rem solid #ffffff;
  border-bottom: 0.04rem solid transparent;
  top: 0.1rem;
  position: absolute;
  left: 0;
}

.item-content-right {
  width: 6.5rem;
  height: 4.2rem;
  border-radius: 0.2rem;
  overflow: hidden;
  background: #ffffff;
}
.programme-image-item {
  font-size: 0;
}
.item-content-right img {
  width: 100%;
}

@media all and (max-width: 960px) {
  .content-item-programme {
    padding: 1rem;
  }
  .content-item-programme.flex-center {
    flex-direction: column;
  }
  .content-item-programme .item-title {
    text-align: center;
  }
  .content-item-programme .item-title p {
    margin-top: 0.5rem;
  }
  .item-content-left .desc .desc-explain {
    padding-left: 0.5rem;
    margin-top: 0.5rem;
  }
  .item-content-left .desc .desc-explain:before {
    border-top: 0.2rem solid transparent;
    border-left: 0.3rem solid #ffffff;
    border-bottom: 0.2rem solid transparent;
    top: 0.22rem;
  }
  .item-content-left:before {
    display: none;
  }
  .item-content-left:after {
    display: none;
  }
  .item-content {
    margin-top: 0.2rem;
    flex-direction: column;
    align-items: center;
  }
  .item-content-left {
    width: 100%;
  }
  .item-content-left .left-text {
    margin: 0;
  }
  .item-content-right {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
  }
}
</style>
