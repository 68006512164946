<template>
  <div class="nav-menu" :class="{ fixed: topFixed, top: !topFixed }">
    <div class="nav-menu-container">
      <ul class="nav-menu-root">
        <template v-for="(item, index) in menuData">
          <sub-menu :item-data="item" :index="index" :key="item.id"></sub-menu>
        </template>
      </ul>
    </div>
    <div class="mobile_language_change">
      <language-change></language-change>
    </div>
    <global-mask v-if="showMask" @click="handleClickMask"></global-mask>
  </div>
</template>

<script>
import SubMenu from "@/components/NavMenu/SubMenu";
import GlobalMask from "@/components/GlobalMask/GlobalMask";
import { mapGetters } from "vuex";
import LanguageChange from "@/components/LanguageChange/LanguageChange.vue";
// import TopNav from "@/components/TopNav/TopNav.vue";
export default {
  name: "NavMenu",
  props: {
    menuData: {
      type: Array,
      default: null,
    },
    topFixed: {
      type: Boolean,
      default: false,
    },
  },
  components: { LanguageChange, SubMenu, GlobalMask },
  data() {
    return {
      openMenu: false,
      ismobile: false,
      showMask: false,
    };
  },
  computed: {
    ...mapGetters(["logoImageUrls"]),
  },
  mounted() {
    // console.log(this.menuData);
  },
  methods: {
    handleControlMenu(is) {
      this.openMenu = is;
      this.showMask = is;
      this.$emit("mobileMenuChange", is);
    },
    handleClickMask() {
      this.handleControlMenu(false);
    },
  },
};
</script>

<style>
.nav-menu {
  --color-primary: #2178c9;
  --lable-color: #333333;
  --hover-color: #ffffff;
  --fixed-back: #ffffff;
}
.nav-menu-root {
  position: relative;
  font-size: 0.18rem;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
}
.nav-menu-root li a {
  transition: all 0.2s ease-out 0s;
  cursor: pointer;
}
.nav-menu-root li a:hover {
  color: var(--color-primary);
}
.nav-menu-root > li {
  display: inline-block;
  margin: 0 0.15rem;
  position: relative;
}
.nav-menu-root li > a {
  padding: 0.2rem 0;
  color: var(--lable-color);
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  display: block;
}
.nav-menu.fixed .nav-menu-root > li > a {
  color: var(--lable-color);
}
.nav-menu.top .nav-menu-root > li > a {
  color: #ffffff;
}
li.menu-item > a > svg {
  display: none;
}
li.has-children > a > svg {
  transform: rotate(90deg);
  /*display: inline-block;*/
}
.nav-menu-root ul.sub-menu {
  display: block;
  position: absolute;
  min-width: 100px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  top: 130%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out 0s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  border-radius: 3px;
  white-space: nowrap;
  text-align: center;
  font-size: 0.16rem;
}
ul.nav-menu-root > .menu-item > ul.sub-menu:after {
  content: "";
  position: absolute;
  left: calc((100% - 20px) / 2);
  top: -0.1rem;
  width: 0;
  height: 0;
  border-left: 0.1rem solid transparent;
  border-right: 0.1rem solid transparent;
  border-bottom: 0.1rem solid #ffffff;
}

.nav-menu-root .menu-item:hover > ul.sub-menu {
  visibility: visible;
  opacity: 1;
  top: 90%;
}

.nav-menu-root ul.sub-menu > li {
  position: relative;
  display: block;
}
.nav-menu-root ul.sub-menu > li > a {
  line-height: 2;
  padding: 0.15rem 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-menu-root ul.sub-menu > li > a:hover {
  background: var(--color-primary);
  color: var(--hover-color);
}

.nav-menu-root ul.sub-menu > li > a > .label {
  flex: 1;
}

.nav-menu-root ul.sub-menu > li > a > svg {
  padding-right: 8px;
  padding-left: 5px;
}

.nav-menu-root ul.sub-menu > li > ul.sub-menu {
  padding-left: 15px;
  left: 100%;
  visibility: hidden;
  opacity: 0;
  top: 130%;
  margin-left: 5px;
}

.nav-menu-root ul.sub-menu > li:hover > ul.sub-menu {
  visibility: visible;
  opacity: 1;
  top: 0;
}
.mobile_language_change {
  display: none;
  text-align: center;
  font-size: var(--text-size);
}
@media all and (max-width: 960px) {
  .nav-menu {
    padding-bottom: 300px;
  }
  .mobile-menu-header a {
    font-size: 0;
  }
  .mobile-menu-header .menu-logo {
    max-width: 150px;
  }
  .nav-menu-root {
    display: block;
    max-width: 664px;
    margin: 0 auto;
    padding: 15px 20px 50px 20px;
    height: 100%;
    box-sizing: border-box;
    font-size: var(--text-size);
    flex-grow: 1;
    overflow: auto;
  }
  .nav-menu-root > li {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid rgba(136, 136, 136, 0.3);
    background: #ffffff;
  }
  .nav-menu-root li > a {
    padding: 0.5rem 0;
  }
  li.has-children > a > svg {
    transform: rotate(0deg);
    display: inline-block;
  }
  .nav-menu-root > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-menu .nav-menu-root > li > a {
    color: var(--lable-color) !important;
  }

  .nav-menu-root ul.sub-menu {
    display: block;
    position: static;
    min-width: auto;
    background: inherit;
    left: auto;
    transform: translateX(0);
    top: auto;
    visibility: visible;
    opacity: 1;
    transition: none;
    box-shadow: none;
    z-index: 1;
    /* border: 1px solid #fafafa; */
    border-radius: 0;
    white-space: nowrap;
    text-align: left;
    /*font-size: 15px;*/
    padding-bottom: 10px;
    font-size: var(--text-size);
  }
  .nav-menu-root ul.sub-menu > li > a {
    line-height: 1.5;
    padding: 0.5rem 0.1rem 0.5rem 10px;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .nav-menu-root ul.sub-menu > li > a span {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .nav-menu-root li a:hover {
    color: var(--color-primary);
  }
  .mobile_language_change {
    display: block;
  }
}
@media all and (max-width: 750px) {
  .nav-menu .mobile-control-btn {
    font-size: 30px;
  }
  .nav-menu-list {
    max-width: 100%;
  }
}
</style>
