<template>
  <div class="online-message">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <ul class="message-input-box">
      <li
        class="wow fadeInUp"
        :data-wow-delay="`${Math.ceil(Math.random() * 0.3)}s`"
      >
        <label>
          <span>Name:</span>
        </label>
        <input
          type="text"
          placeholder="enter your name"
          v-model="messageForm.leaveName"
        />
      </li>
      <li
        class="wow fadeInUp"
        :data-wow-delay="`${Math.ceil(Math.random() * 0.3)}s`"
      >
        <label>
          <span>Email:</span>
        </label>
        <input
          type="text"
          placeholder="enter your email"
          v-model="messageForm.mobile"
        />
      </li>
      <li
        class="wow fadeInUp"
        :data-wow-delay="`${Math.ceil(Math.random() * 0.3)}s`"
      >
        <label><span>Wechat:</span></label>
        <input
          type="text"
          placeholder="enter wechat account"
          v-model="messageForm.wechat"
        />
      </li>
      <li
        class="wow fadeInUp"
        :data-wow-delay="`${Math.ceil(Math.random() * 0.3)}s`"
      >
        <label>
          <span>Company name:</span>
        </label>
        <input
          type="text"
          placeholder="enter company name"
          v-model="messageForm.company"
        />
      </li>
    </ul>
    <div class="message-content wow fadeInUp">
      <label><span>Message:</span></label>
      <textarea
        placeholder="leave a message here"
        v-model="messageForm.content"
      ></textarea>
    </div>
    <div class="submit wow fadeInUp">
      <a-spin :spinning="sendLoad">
        <button @click="submitMessage"><span>Submit</span></button>
      </a-spin>
    </div>
    <div class="privacy-policy-text">
      <a href="/about/privacy-policy">
        click here for our privacy policy >
      </a>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
import { validMessage } from "@/utils/validate";
import notification from "ant-design-vue/lib/notification";
import { submitMessage } from "@/api/public";
export default {
  name: "OnlineMessage",
  components: {
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      messageForm: {
        leaveName: "",
        mobile: "",
        content: "",
        company: "",
        wechat: "",
      },
      sendLoad: false,
    };
  },
  methods: {
    submitMessage() {
      const validMessageStatus = validMessage(this.messageForm);
      if (validMessageStatus.status == 0) {
        notification.error({
          message: "Kind reminder",
          description: validMessageStatus.msg,
        });
        return;
      }
      this.sendLoad = true;
      console.log(this.messageForm);
      submitMessage(this.messageForm)
        .then(() => {
          this.messageForm.leaveName = "";
          this.messageForm.mobile = "";
          this.messageForm.content = "";
          this.messageForm.company = "";
          this.messageForm.wechat = "";
          let expdate = new Date();
          expdate.setTime(expdate.getTime() + 1 * 60 * 1000);
          document.cookie = `sendMsgTime=${expdate.getTime()};expires=${expdate.toGMTString()};path=/`;
          notification.success({
            message: "Kind reminder",
            description: "Message successfully",
          });
        })
        .catch(() => {
          notification.error({
            message: "Kind reminder",
            description: "Message Failed.",
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.online-message {
  background-color: #f8f8f8;
  padding-bottom: 1.5rem;
}
.message-input-box {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.message-input-box li {
  background-color: #ffffff;
  /*height: 0.8rem;*/
  width: calc((100% - 0.3rem) / 2);
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem;
  margin-top: 0.3rem;
}
.message-input-box li span,
.message-content span {
  position: relative;
  font-size: var(--text-size);
  color: #888888;
  display: block;
  white-space: nowrap;
}
.message-input-box li span::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: -0.3rem;
  top: 0;
}
.message-input-box li:nth-child(1) span::after,
.message-input-box li:nth-child(2) span::after,
.message-content span::after {
  color: red;
  content: "*";
  position: absolute;
  right: -0.2rem;
  top: -0.1rem;
}
.message-input-box li label {
}
.message-input-box li input {
  border: none;
  width: 100%;
  margin-left: calc(100vw / 1920 * 30);
  outline: none;
  box-sizing: border-box;
  padding: 0 0 0 calc(100vw / 1920 * 30);
  font-size: var(--text-size);
}
.online-message .message-content {
  margin: 0.3rem auto 0;
  background-color: #ffffff;
  width: calc(100% - 2rem);
  border-radius: 0.05rem;
  box-sizing: border-box;
  padding: 0.3rem;
  display: flex;
  height: 3rem;
}
.online-message .message-content textarea {
  width: 100%;
  margin-left: calc(100vw / 1920 * 30);
  font-size: var(--text-size);
  border: none;
  outline: none;
  resize: none;
  position: relative;
  padding-left: calc(100vw / 1920 * 30);
}

.online-message .message-content span::before {
  content: "";
  width: 1px;
  height: calc(var(--text-size) * 1.5);
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: calc(100vw / 1920 * -30);
  /*height: 100%;*/
  top: 0;
}
.online-message .submit {
  /*width: 3rem;*/
  text-align: center;
  margin-top: 0.6rem;
}
.online-message .submit button {
  outline: #2178c9;
  border: none;
  padding: 0.2rem 1.2rem;
  background-color: #2178c9;
  color: #ffffff;
  font-size: var(--text-size);
  border-radius: 0.4rem;
  cursor: pointer;
}
.online-message .submit button:hover {
  opacity: 0.9;
}
.online-message .submit button:active {
  opacity: 1;
}
@media all and (max-width: 960px) {
  .message-input-box {
    flex-direction: column;
  }
  .message-input-box li {
    width: 100%;
    margin-bottom: 0.5rem;
    flex-direction: column;
    align-items: start;
    background-color: inherit;
    padding: 0;
  }
  .online-message .message-content label,
  .message-input-box li label {
    margin-bottom: 0.2rem;
  }
  .online-message .message-content span::before,
  .message-input-box li span::before {
    display: none;
  }
  .message-input-box li input {
    padding: 0.5rem 0.3rem;
    width: 100%;
    margin: 0;
    line-height: 2;
  }

  .online-message .message-content {
    margin-top: 0;
    flex-direction: column;
    align-items: start;
    background-color: inherit;
    padding: 0 1rem;
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  .online-message .message-content textarea {
    padding: 0.5rem 0.3rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    line-height: 2;
    height: 8rem;
  }
  .online-message .submit button {
    outline: #2178c9;
    border: none;
    padding: 0.3rem 2rem;
    background-color: #2178c9;
    color: #ffffff;
    font-size: var(--text-size);
    border-radius: 0.7rem;
    cursor: pointer;
  }
}
.privacy-policy-text {
  margin-top: .5rem;
  text-align: center;
  font-size: var(--text-size);
  color: #666666;
  width: 100%;
  text-decoration: underline;
  transition: color 0.3s linear;
}
.privacy-policy-text:hover {
  color: #222222;
}
</style>
