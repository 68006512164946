<template>
  <div class="typical-page-case">
    <ul class="list">
      <template v-for="(item, index) in projectList">
        <li
          class="list-item wow fadeInUp"
          data-wow-delay="0.3s"
          :key="item.id"
          :index="index"
        >
          <div class="list-item-content">
            <a :href="`/case/detail?id=${item.id}&type=${type}`">
              <div class="pic">
                <img :src="item.imageUrl" alt="" />
              </div>
              <h3>{{ item.title }}</h3>
            </a>
          </div>
        </li>
      </template>
    </ul>
    <a-empty
      v-if="!projectList.length"
      description="No data"
      class="wow fadeInUp"
    />
  </div>
</template>

<script>
export default {
  name: "CaseList",
  props: {
    projectList: {
      type: Array,
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
  },
  methods: {},
};
</script>

<style scoped>
.list {
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
}
.list li {
  width: calc(100% / 3 - 0.2rem);
  padding-bottom: 0.6rem;
  /*margin-right: 0.2rem;*/
}
.list li:nth-child(3n) {
  /*margin-right: 0.2rem;*/
}
.list:after {
  content: "";
  width: calc(100% / 3 - 0.2rem);
  display: block;
}

.list li .pic {
  height: 3.9rem;
  background-color: #e5e5e5;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.list li .pic img {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.list li h3 {
  font-size: var(--text-size);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: calc(100vw / 1920 * 30);
  font-weight: normal;
  cursor: pointer;
}
.list li h3:hover {
  color: #2178c9;
}
.list li .pic:hover img {
  transform: scale(1.1);
}
@media all and (max-width: 960px) {
  .list li {
    width: calc(100% / 2 - 0.2rem);
    padding-bottom: 0.6rem;
  }
  .list li .pic {
    height: 5rem;
  }
}
</style>
