<template>
  <a
    @click.stop="handleClickLink"
    :href="itemData.menuRoute"
    :class="down ? 'down' : 'right'"
  >
    <span className="label" @click.stop="handleClickLabel">
      {{ itemData.menuName }}
    </span>
    <svg-icon name="right" @click.stop="handleClickIcon"></svg-icon>
  </a>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    itemData: {
      type: Object,
      require: true,
    },
    down: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickIcon(e) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("iconClick", this.itemData);
      // this.$emit("click", this.itemData);
    },
    handleClickLink(e) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("clickLink", this.itemData);
      // this.$emit("click", this.itemData);
      return false;
    },
    handleClickLabel(e) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("clickLabel", this.itemData);
      // this.$emit("click", this.itemData);
    },
  },
};
</script>

<style scoped>
.a svg {
  transition: transform 0.3s linear;
}
.down svg {
  transform: rotate(90deg);
}
</style>
