import Vuex from "vuex";
import request from "./modules/request";
import page from "./modules/page";
import project from "./modules/project";
import news from "./modules/news";
import { getScrollTopNum } from "@/utils/util";

export function createStore() {
  return new Vuex.Store({
    state: {
      isPosition: false,
      pageLoading: false,
      showMask: false,
      scrollTop: 0,
      logoImageUrls: {
        colorLogo:
          "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/eJNVFCWB.png",
        whiteLogo:
          "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/eLmNo3xs.png",
      },
      windowsWidth: 0,
    },
    mutations: {
      setPosition(state, { value }) {
        state.isPosition = value;
      },
      setPageLoading(state, val) {
        state.pageLoading = val;
      },
      setScrollTopNum(state, val) {
        state.scrollTop = val;
      },
      changeMenuStatus(state, val) {
        state.openMenu = val;
      },
      setWindowsWidth(state, val) {
        state.windowsWidth = val;
      },
      setShowMask(state, val) {
        state.showMask = val;
      },
    },

    actions: {
      changePageLoading({ commit }, val) {
        commit("setPageLoading", val);
      },
      changeScrollTop({ commit }) {
        let scrollTopNum = getScrollTopNum();
        commit("setScrollTopNum", scrollTopNum);
      },
      changeWindowWidth({ commit }, val) {
        commit("setWindowsWidth", val);
      },
      changeShowMask({ commit }, val) {
        commit("setShowMask", val);
      },
    },
    modules: {
      request: request,
      page: page,
      project: project,
      news: news,
    },
    getters: {
      isPosition: (state) => state.isPosition,
      pageLoading: (state) => state.pageLoading,
      scrollTop: (state) => state.scrollTop,
      logoImageUrls: (state) => state.logoImageUrls,
      windowsWidth: (state) => state.windowsWidth,
      showMask: (state) => state.showMask,
    },
  });
}
