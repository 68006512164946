var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"strategy-page"},[_c('page-banner',{attrs:{"image-url":"https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/YGZPSlzp.png","small-image-url":"https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/Zt9QWjGw.png"}}),_vm._v(" "),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"crumbs",attrs:{"padhide":""}},[_c('ul',{staticClass:"wow fadeInLeft"},[_c('li',{staticClass:"icon"},[_c('svg-icon',{attrs:{"name":"home"}})],1),_vm._v(" "),_c('li',{staticClass:"label-hover",on:{"click":function($event){return _vm.$router.replace({ path: `/` })}}},[_vm._v("\n          HOMEPAGE\n        ")]),_vm._v(" "),_c('li',{staticClass:"icon"},[_c('svg-icon',{attrs:{"name":"right"}})],1),_vm._v(" "),_c('li',{staticClass:"label-hover"},[_vm._v("STRATEGIC LAYOUT")]),_vm._v(" "),_c('li',{staticClass:"icon"},[_c('svg-icon',{attrs:{"name":"right"}})],1),_vm._v(" "),_c('li',[_vm._v(_vm._s(_vm.title))])])]),_vm._v(" "),_c('div',{staticClass:"tab"},[_c('ul',[_c('li',{class:[
            _vm.$route.path == '/strategy/market' ? 'active' : '',
            'wow',
            'fadeInRight',
          ],attrs:{"data-wow-delay":"0.1s"},on:{"click":function($event){return _vm.$router.push('/strategy/market')}}},[_vm._v("\n          Market Layout\n        ")]),_vm._v(" "),_c('li',{class:[
            _vm.$route.path == '/strategy/development' ? 'active' : '',
            'wow',
            'fadeInRight',
          ],attrs:{"data-wow-delay":"0.3s"},on:{"click":function($event){return _vm.$router.push('/strategy/development')}}},[_vm._v("\n          R&D Layout\n        ")])])])]),_vm._v(" "),_c('div',{staticClass:"page-content"},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }