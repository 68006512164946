<template>
  <div class="news-page">
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/xMN6PT9d.jpg"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/PfI5yPVn.jpg"
    ></page-banner>
    <div class="nav">
      <div class="crumbs" padhide>
        <ul class="wow fadeInLeft">
          <li class="icon">
            <svg-icon name="home"></svg-icon>
          </li>
          <li class="label-hover">
            <a href="/" title="HOMEPAGE"> HOMEPAGE </a>
          </li>
          <li class="icon">
            <svg-icon name="right"></svg-icon>
          </li>
          <li class="label-hover">
            <a href="/news/enterprise" title="NEWS CENTER">NEWS CENTER</a>
          </li>
          <li class="icon">
            <svg-icon name="right"></svg-icon>
          </li>
          <li>{{ title }}</li>
        </ul>
      </div>
      <div class="tab">
        <ul>
          <li
            :class="[
              $route.path == '/news/enterprise' ? 'active' : '',
              'wow',
              'fadeInRight',
            ]"
            data-wow-delay="0.1s"
          >
            <router-link to="/news/enterprise" title="Corporate Information"
              >Company Information</router-link
            >
          </li>
          <li
            :class="[
              $route.path == '/news/industry' ? 'active' : '',
              'wow',
              'fadeInRight',
            ]"
            data-wow-delay="0.3s"
          >
            <router-link to="/news/industry"> Industry information </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="page-content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner/PageBanner";
export default {
  name: "NewsPage",
  components: {
    PageBanner,
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style scoped>
.nav {
  height: 0.8rem;
  border-bottom: 1px solid #f5f5f5;
  padding: 0.1rem 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.crumbs ul {
  display: flex;
  align-content: center;
  align-items: center;
}
.crumbs li {
  font-size: var(--text-size);
  margin: 0 calc(100vw / 1920 * 7);
  color: #888888;
  white-space: nowrap;
}
.tab {
  width: 100%;
  overflow: hidden;
  margin-left: 1rem;
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab::-webkit-scrollbar {
  display: none;
}
.tab ul {
  /*display: flex;*/
  /*align-content: center;*/
  /*align-items: center;*/
  /*justify-content: end;*/
  white-space: nowrap;
  text-align: right;
  font-size: 0;
}
.tab li {
  font-size: var(--text-size);
  color: #888888;
  /*margin-right: 0.48rem;*/
  position: relative;
  height: inherit;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 0.35rem;
  display: inline-block;
}
.tab li:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  width: 1px;
  background-color: #efefef;
}
.tab li:last-child::after {
  /*margin-right: 0rem;*/
  display: none;
}
.tab li.active {
  color: #2178c9;
}
.tab li.active::after {
  position: absolute;
  left: 0;
  bottom: -1px;
  content: "";
  height: 2px;
  background-color: #2178c9;
  animation: borderWidth 0.3s forwards;
}
@media all and (max-width: 960px) {
  .nav {
    padding: 0.5rem 1rem;
  }
  .tab {
    margin-left: 0;
  }
  .tab ul {
    text-align: left;
  }
  .tab li:last-child {
    margin-right: 0.48rem;
  }
}
</style>
