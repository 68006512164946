<template>
  <div class="swiper-development-path" id="development-history">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="history-swiper-content">
      <div class="year-lists">
        <swiper
          ref="yearSwiper"
          :options="yearSwiperOption"
          @slideChange="handleYearSwiperChange"
          @click-slide="handleYearClickSlide"
        >
          <template v-for="(item, index) in setting.experienceData">
            <swiper-slide :key="index">
              <div class="year-item">
                <span>{{ item.year }}</span>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <div class="swiper-control-panel">
        <div class="arrow-box">
          <svg-icon name="up" class="last-year"></svg-icon>
          <svg-icon name="down" class="next-year"></svg-icon>
        </div>
        <div class="point-box">
          <span class="circular"
            ><i
              :style="{ transform: `rotate(${swiperActiveIndex * 180}deg)` }"
            ></i
          ></span>
          <div
            data-wow-delay="0.1s"
            class="dotted-line-box wow fadeInRight"
          >
            <img src="../../../../assets/images/dotted-line-blue.png" alt="" />
          </div>
        </div>
      </div>
      <div class="path-content-swiper">
        <swiper
          ref="contentSwiper"
          :options="contentSwiperOption"
          @slideChange="handlePathContentSwiperChange"
        >
          <template v-for="(contentItem, index) in setting.experienceData">
            <swiper-slide :key="index">
              <div class="path-content-item">
                <div class="pic-box">
                  <img :src="contentItem.pic" alt="" />
                </div>
                <div class="content-box">
                  <h1>{{ contentItem.year }}</h1>
                  <ul class="record">
                    <li
                      v-for="(describeItem, index) in contentItem.describe"
                      :key="index"
                    >
                      {{ describeItem }}
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
export default {
  name: "SwiperDevelopmentPath",
  components: {
    ItemTitle,
    Swiper,
    SwiperSlide,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      swiperActiveIndex: this.setting.experienceData.length || 6,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
    yearSwiper() {
      return this.$refs.yearSwiper.$swiper;
    },
    contentSwiper() {
      return this.$refs.contentSwiper.$swiper;
    },
    yearSwiperOption() {
      return {
        direction: "horizontal", // 垂直切换选项
        slidesPerView: 5, //展示数量
        centeredSlides: true,
        initialSlide: this.swiperActiveIndex,
        navigation: {
          prevEl: ".last-year",
          nextEl: ".next-year",
        },
        breakpoints: {
          961: {
            direction: "vertical",
          },
        },
      };
    },
    contentSwiperOption() {
      return {
        direction: "horizontal", // 垂直切换选项
        slidesPerView: 1, //展示数量
        initialSlide: this.swiperActiveIndex,
        spaceBetween: 10,
        breakpoints: {
          961: {
            direction: "vertical",
          },
        },
      };
    },
  },
  methods: {
    handleYearSwiperChange() {
      // console.log(this.yearSwiper.activeIndex);
      this.contentSwiper.slideTo(this.yearSwiper.activeIndex);
      this.swiperActiveIndex = this.yearSwiper.activeIndex;
    },
    handlePathContentSwiperChange() {
      this.yearSwiper.slideTo(this.contentSwiper.activeIndex);
      this.swiperActiveIndex = this.contentSwiper.activeIndex;
    },
    // handleLastYear() {
    //   this.yearSwiper.slidePrev();
    // },
    // handleNextYear() {
    //   this.yearSwiper.slideNext();
    // },
    handleYearClickSlide(index) {
      this.yearSwiper.slideTo(index);
      this.swiperActiveIndex = this.yearSwiper.activeIndex;
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.history-swiper-content {
  padding: 0 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history-swiper-content .path-content-swiper,
.history-swiper-content .year-lists {
  height: 5rem;
}
.year-lists .year-item {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: var(--h3title-size);
  color: #888888;
  font-weight: bold;
  font-family: "DINPro";
  cursor: pointer;
}
.swiper-slide-active .year-item {
  color: #2178c9;
}

.path-content-item .content-box {
  margin-left: 0.8rem;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 6.8rem);
}
.path-content-item .content-box h1 {
  font-family: "DINPro";
  color: #2178c9;
  font-size: var(--h1title-size);
  padding-bottom: calc(100vw / 1920 * 56);
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}
.path-content-item .content-box .record li {
  font-size: var(--text-size);
  color: #222222;
  margin-top: 0.4rem;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  padding-left: 0.2rem;
  box-sizing: border-box;
}
.path-content-item .content-box .record li::after {
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  background-color: #222222;
  content: "";
  position: absolute;
  left: 0.1rem;
  top: calc((var(--text-size) * 1.5 / 2) - 0.04rem);
}
.path-content-item {
  /*margin-left: 1rem;*/
  display: flex;
  align-content: center;
  align-items: center;
}
.path-content-item .pic-box {
  width: 6rem;
  height: 4rem;
  border-radius: 0.2rem;
  overflow: hidden;
  font-size: 0;
}
.path-content-item .pic-box img {
  width: 100%;
}

.swiper-control-panel {
  display: flex;
  height: 5rem;
  align-items: center;
  position: relative;
}
.point-box {
  position: relative;
  height: 100%;
}
.point-box .dotted-line-box {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0.5rem;
}
.point-box .dotted-line-box img {
  height: 100%;
}
.point-box .circular {
  width: 0.95rem;
  height: 0.95rem;
  border-radius: 0.475rem;
  border: 1px solid #2178c9;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin-left: 1.3rem;
  position: relative;
  background-color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
}
.point-box .circular::after {
  content: "";
  width: 1rem;
  height: 1px;
  position: absolute;
  background-color: #2178c9;
  left: -1rem;
}
.point-box .circular::before {
  content: "";
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 0.035rem;
  position: absolute;
  left: -1.07rem;
  background-color: #2178c9;
}
.point-box .circular i {
  width: 0.55rem;
  height: 0.55rem;
  border-radius: 0.275rem;
  display: block;
  background: url("../../../../assets/images/hourglass_icon.png") no-repeat
    center center #2178c9;
  background-size: 0.15rem auto;
  transition: all 0.3s ease-in;
  transform: rotate(0deg);
}

.arrow-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.3rem;
  color: #007aff;
  position: absolute;
  left: 0.8rem;
  height: 100%;
  top: 0;
  z-index: 3;
}
.arrow-box .last-year.swiper-button-disabled,
.arrow-box .next-year.swiper-button-disabled {
  cursor: no-drop;
  color: #cccccc;
}

.arrow-box .last-year,
.arrow-box .next-year {
  cursor: pointer;
}
@media all and (max-width: 960px) {
  .history-swiper-content {
    justify-content: center;
    flex-direction: column;
  }

  .history-swiper-content .path-content-swiper,
  .history-swiper-content .year-lists {
    height: auto;
    width: 100%;
  }

  .arrow-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
    color: #007aff;
    position: absolute;
    left: auto;
    height: auto;
    top: 0.8rem;
    width: 100%;
  }
  .swiper-control-panel {
    height: auto;
    width: 100%;
    justify-content: center;
  }
  .swiper-control-panel .last-year {
    transform: rotate(-90deg);
  }
  .swiper-control-panel .next-year {
    transform: rotate(-90deg);
  }
  .point-box .circular {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid #2178c9;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-top: 1.3rem;
    position: relative;
    background-color: #ffffff;
    left: 50%;
    top: auto;
    transform: translate(-50%, 0);
    margin-left: 0;
  }
  .point-box .circular::after {
    content: "";
    width: 1px;
    height: 1rem;
    position: absolute;
    background-color: #2178c9;
    left: 50%;
    transform: translate(-50%, 0);
    top: -1rem;
  }
  .point-box .circular::before {
    content: "";
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 0.035rem;
    position: absolute;
    top: -1.07rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #2178c9;
  }
  .path-content-item .content-box {
    margin-left: 0.8rem;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .path-content-swiper {
    width: 100%;
    margin-top: 0.5rem;
  }
  .path-content-item {
    margin: 0;
  }
  .path-content-item .pic-box {
    width: 50%;
    height: auto;
    border-radius: 0.2rem;
    overflow: hidden;
    flex: 1 0 50%;
  }
  .path-content-item .content-box {
    /*margin-left: 0.8rem;*/
    /*display: flex;*/
    /*align-content: center;*/
    /*align-items: center;*/
    /*flex-wrap: wrap;*/
    /*width: 100%;*/
  }
  .year-item {
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  .path-content-item .content-box .record li {
    padding-left: 0.4rem;
  }
  .path-content-item .content-box .record li::after {
    width: 0.15rem;
    height: 0.15rem;
  }
  .point-box .circular i {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
  }
  .point-box .dotted-line-box {
    display: none;
  }
}
@media all and (max-width: 768px) {
  .path-content-item {
    flex-direction: column;
    width: 100%;
  }
  .path-content-item .content-box {
    margin-left: 0;
    margin-top: 0.5rem;
  }
  .path-content-item .pic-box {
    width: 100%;
    flex: 1 1 100%;
  }
}
</style>
<style>
.history-swiper-content .swiper-container-vertical {
  height: 100%;
}
.path-content-swiper .swiper-slide {
  display: flex;
  align-items: center;
}
.path-content-item {
  margin: auto 0 auto 1rem;
}
</style>
