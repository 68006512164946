<template>
  <div class="difficulty-content">
    <div class="difficulty-title wow fadeInLeft" v-if="setting.title">
      <h2>{{ setting.title }}</h2>
    </div>
    <div class="difficulty-cards">
      <swiper ref="mySwiper" :options="difficultySwiperOpting">
        <template v-for="(item, index) in setting.difficultyList">
          <swiper-slide :key="index">
            <div
              class="difficulty-card-item wow fadeInRight"
              :data-wow-delay="`${0.2 * index}s`"
            >
              <div class="card-content">
                <div class="index-num">{{ formatIndex(index + 1, 2) }}</div>
                <p class="desc-text">
                  {{ item.desc }}
                </p>
                <div class="card-image">
                  <img :src="item.img" />
                </div>
              </div>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "DifficultyItem",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    setting: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      difficultySwiperOpting: {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        breakpoints: {
          960: {
            slidesPerView: this.setting.showNum,
          },
        },
      },
      // difficultyData: [
      //   {
      //     desc: "污水排放量不稳定，水质变化大。",
      //     img: "http://fairylands.oss-cn-beijing.aliyuncs.com/images/d815c8c607440c27f2d259fbf827c872.jpg",
      //   },
      //   {
      //     desc: "大多远离城市基础设施，管网建设难度大，无法纳入市政管网统一处理。",
      //     img: "http://fairylands.oss-cn-beijing.aliyuncs.com/images/611e4c4a867afa90f4dca5553422eba1.jpg",
      //   },
      //   {
      //     desc: "工况要求高，低温或高温微生物系统挑战大。",
      //     img: "http://fairylands.oss-cn-beijing.aliyuncs.com/images/6f94e664656e1b027b16fca55a46e454.jpg",
      //   },
      //   {
      //     desc: "空间狭小，移动可放，季节性排水，气味敏感，水质要求高。",
      //     img: "http://fairylands.oss-cn-beijing.aliyuncs.com/images/c7d2892ad6fbf3d9c2c0dad66f00daa8.jpg",
      //   },
      // ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.initSwiper();
  },
  methods: {
    formatIndex(num, index, str = "0") {
      return Array(index - String(num).length + 1).join(str) + num;
    },
  },
};
</script>

<style scoped>
.difficulty-content {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
.difficulty-title h2 {
  font-size: var(--h1title-size);
  font-weight: bold;
  color: #222222;
}
.difficulty-cards {
  margin-top: 0.8rem;
  overflow: hidden;
}
.difficulty-card-item {
  padding: 0.15rem;
  box-sizing: border-box;
  height: 100%;
}
.difficulty-card-item .card-content {
  padding: 0.2rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(16, 32, 115, 0.1);
  border-radius: 0.2rem;
  /*height: 4.3rem;*/
  height: 100%;
  display: flex;
  flex-direction: column;
}
.difficulty-card-item .index-num {
  font-size: 0.4rem;
  font-family: DINPro;
  font-weight: 400;
  color: #2178c9;
}
.difficulty-card-item .desc-text {
  margin-top: 0.25rem;
  font-size: var(--text-size);
  /*height: calc(var(--text-size) * 4 * 1.5);*/
  line-height: 1.5;
  font-weight: 400;
  color: #333333;
  margin-bottom: 0.25rem;
}
.difficulty-card-item .card-image {
  /*display: block;*/
  width: 100%;
  border-radius: 0.1rem;
  overflow: hidden;
  font-size: 0;
  margin-top: auto;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.difficulty-card-item .card-image:after {
  display: inline-block;
  width: 0;
  content: "";
  height: 100%;
  vertical-align: middle;
}
.difficulty-card-item .card-image img {
  width: 100%;
  vertical-align: middle;
}

@media all and (max-width: 960px) {
  .difficulty-card-item .card-content {
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
  .difficulty-card-item .index-num {
    font-size: 0.8rem;
    font-family: DINPro;
    font-weight: 400;
    color: #2178c9;
  }
  .difficulty-card-item .desc-text {
    margin: 0.5rem 0;
  }
  .difficulty-card-item .card-image {
    height: 5rem;
    line-height: 5rem;
    display: flex;
    align-items: center;
  }
  .difficulty-card-item .card-image img {
    height: 100%;
    object-fit: cover;
  }
}
</style>
<style>
.difficulty-content .swiper-slide {
  overflow: hidden;
  height: auto;
  background: inherit;
  width: 100%;
}
@media all and (max-width: 960px) {
  .difficulty-content .swiper-slide {
    max-width: 35vw;
  }
}
@media all and (max-width: 768px) {
  .difficulty-content .swiper-slide {
    max-width: 55vw;
  }
}
</style>
