<template>
  <div class="page-loading" :class="{ fullscreen: fullscreen }">
    <div class="circle-box">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Loading",
  props: {
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.page-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9;
}
.page-loading .fullscreen {
  position: fixed;
}
.circle-box,
.circle-box * {
  box-sizing: border-box;
}

.circle-box {
  height: 66px;
  width: 66px;
  padding: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
}
.circle-box .circle {
  position: absolute;
  display: block;
  border-radius: 50%;
  border: 3px solid #2178c9;
  opacity: 1;
}
.circle-box .circle:nth-child(1) {
  height: 60px;
  width: 60px;
  animation: circle-box-circle1-animation 1.5s infinite linear;
  border-width: 3px;
}
.circle-box .circle:nth-child(2) {
  height: calc(60px * 0.65);
  width: calc(60px * 0.65);
  animation: circle-box-circle2-animation 1.5s infinite linear;
  border-width: 2px;
}
.circle-box .circle:nth-child(3) {
  height: calc(60px * 0.1);
  width: calc(60px * 0.1);
  animation: circle-box-circle3-animation 1.5s infinite linear;
  border-width: 1px;
}

@keyframes circle-box-circle1-animation {
  0% {
    transform: rotateZ(20deg) rotateY(0deg);
  }
  100% {
    transform: rotateZ(100deg) rotateY(360deg);
  }
}
@keyframes circle-box-circle2-animation {
  0% {
    transform: rotateZ(100deg) rotateX(0deg);
  }
  100% {
    transform: rotateZ(0deg) rotateX(360deg);
  }
}
@keyframes circle-box-circle3-animation {
  0% {
    transform: rotateZ(100deg) rotateX(-360deg);
  }
  100% {
    transform: rotateZ(-360deg) rotateX(360deg);
  }
}
</style>
