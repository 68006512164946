<template>
  <div class="top-nav">
    <div class="top-nav-container">
      <div class="top-nav-content">
        <div class="right-nav">
          <language-change></language-change>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import LanguageChange from "@/components/LanguageChange/LanguageChange.vue";

export default {
  name: "TopNav",
  components: { LanguageChange },
  setup() {
    const languageList = ref(false);
    const handleOpenList = () => {
      languageList.value = true;
    };
    const handleCloseList = () => {
      languageList.value = false;
    };

    return {
      languageList,
      handleOpenList,
      handleCloseList,
    };
  },
};
</script>

<style scoped>
.top-nav {
  z-index: 110;
  position: relative;
  font-size: var(--text-size);
}
.top-nav .top-nav-container {
  position: static;
  text-align: right;
  color: #cccccc;
  background-color: #111111;
}
.top-nav .top-nav-container .top-nav-content {
  padding: 0 1rem;
}
.top-nav .right-nav {
  /*font-size: 0;*/
}
</style>
