<template>
  <div class="page-banner">
    <img :src="imageUrl" mobilehide />
    <img :src="smallImageUrl ? smallImageUrl : imageUrl" pchide />
    <div class="banner-text wow fadeInUp" v-if="bannerText">
      <p>{{ bannerText }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PageBanner",
  props: {
    imageUrl: {
      type: String,
      require: true,
    },
    smallImageUrl: {
      type: String,
      default: null,
    },
    bannerText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showImgUrl: this.imageUrl,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
    // imageUrlComputed() {
    //   if (this.$store.getters.windowsWidth > 768) {
    //     return this.imageUrl;
    //   } else {
    //     return this.smallImageUrl || this.imageUrl;
    //   }
    // },
  },
  methods: {},
};
</script>

<style scoped>
.page-banner {
  display: block;
  font-size: 0;
  position: relative;
  overflow: hidden;
}
.page-banner img {
  width: 100%;
  animation: bannerAnimate 0.8s linear;
  /*animation-direction: alternate;*/
  transform: scale(1.06);
}
.page-banner .banner-text {
  font-family: DINPro;
  font-size: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  color: #ffffff;
  transform: translate(-50%, -50%);
}
.crumbs ul {
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  left: 4.1rem;
  bottom: 2.8rem;
}
.crumbs li {
  color: #ffffff;
  font-size: 0.12rem;
  margin: 0 calc(100vw / 1920 * 7);
}
@keyframes bannerAnimate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.06);
  }
}
</style>
