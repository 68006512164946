import { render, staticRenderFns } from "./CompanyProfile.vue?vue&type=template&id=e2a813c8&scoped=true"
import script from "./CompanyProfile.vue?vue&type=script&lang=js"
export * from "./CompanyProfile.vue?vue&type=script&lang=js"
import style0 from "./CompanyProfile.vue?vue&type=style&index=0&id=e2a813c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2a813c8",
  null
  
)

export default component.exports