<template>
  <div class="application-scenario">
    <HomeItemTitle
      class="application-title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></HomeItemTitle>
    <ul class="application-box">
      <li
        class="wow fadeInUp"
        :data-wow-delay="`${0.15 * (index + 1)}s`"
        v-for="(item, index) in setting.sceneList"
        :key="index"
      >
        <div
          class="pic-box"
          :style="{ backgroundImage: `url(${item.imageUrl})` }"
        ></div>
        <p class="text-box">
          <span>{{ item.titleCn }}</span>
          <span>{{ item.titleEn }}</span>
        </p>
        <div class="layer-box"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import HomeItemTitle from "@/components/HomeItemTitle/HomeItemTitle.vue";
export default {
  name: "ApplicationScenario",
  components: {
    HomeItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  computed: {},
};
</script>

<style scoped>
.application-scenario .application-title-box {
  padding: 1rem 1rem 0.7rem;
  justify-content: space-between;
  align-items: center;
}

.application-scenario {
  background-color: #f8f8f8;
}

.application-box li {
  width: calc(100% / 5);
  height: calc(100vw / 5);
}
.application-box li .text-box {
  color: #ffffff;
  position: absolute;
  /*left: 15.62%;*/
  bottom: 7.5%;
  z-index: 8;
  font-size: var(--text-size);
  width: 100%;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.application-box li .text-box span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.application-box li .text-box span:nth-child(2) {
  font-size: var(--subtext-size);
  margin-top: 0.02rem;
  opacity: 0.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.application-box li .text-box span:nth-child(2) {
  font-family: "DINPro";
}
.application-box {
  display: flex;
  flex-wrap: wrap;
}
.application-box li {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.application-box li .pic-box,
.application-box li .layer-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  z-index: 1;
  transition: all 0.15s ease-in;
  transform: scale(1);
}
.application-box li:hover .pic-box {
  transform: scale(1.1);
}
.application-box li .layer-box {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.15s ease-in;
}
.application-box li .layer-box:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 0;
  width: 100%;
  box-shadow: 1px 1px 60px 60px #000000;
}
.application-box li:hover .layer-box {
  background-color: rgba(0, 0, 0, 0);
}

.application-box li {
  width: calc(100% / 5);
  height: calc(100vw / 5);
}
@media screen and (max-width: 960px) {
  .application-scenario .application-title-box {
    padding: 0.7rem 1rem 0.7rem;
    justify-content: space-between;
    align-items: center;
  }
  .application-scenario {
    margin-top: -1px;
  }

  .application-box li {
    width: calc(100% / 2);
    height: calc(100vw / 2);
  }
  .application-box li .text-box {
    /*font-size: 0.5rem;*/
    /*left: 10%;*/
    padding: 0 0.5rem;
  }
  .application-box li .text-box spen {
    white-space: nowrap;
  }
  .application-box li .text-box span:nth-child(2) {
    margin-top: 0.1rem;
    opacity: 0.5;
  }
}
</style>
