<template>
  <div class="select-btn-group">
    <ul :class="{ type: true, open: openTabList }">
      <template v-for="(item, index) in projectTypeList">
        <li
          :key="item.id"
          :class="{
            active: $route.path == item.path,
            wow: true,
            fadeInRight: true,
            fadeInUp: false,
          }"
          @click="handelChangeTab(item, index)"
          :data-wow-delay="`0.${index}s`"
        >
          {{ item.projectTypeName }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SelectTab",
  props: {
    projectTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      openTabList: false,
    };
  },
  methods: {
    handelChangeTab(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style scoped>
.select-btn-group {
  font-size: var(--text-size);
}

.select-btn-group .type {
  display: inline-flex;
}
.select-btn-group .type li {
  /*width: 2.5rem;*/
  padding: 0 0.5rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #e5e5e5;
  margin-right: 0.2rem;
  font-size: var(--text-size);
  color: #222222;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.select-btn-group .type li:hover,
.select-btn-group .type li.active {
  color: #ffffff;
  border-color: #2178c9;
  background-color: #2178c9;
}
.select-btn-group .type li:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1400px) {
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 960px) {
  .select-btn-group {
    overflow-x: auto;
    width: 100%;
    padding-bottom: 5px;
  }
  .select-btn-group .type {
    padding-bottom: 0.15rem;
  }
  .select-btn-group .type li {
    /*width: 5.5rem;*/
    padding: 0 1rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0.76rem;
    margin-right: 0.25rem;
  }
  .select-btn-group {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .select-btn-group .type li {
    font-size: 0.4rem;
    /*width: 5.5rem;*/
    height: 1.5rem;
    line-height: 1.5rem;
    display: inline-block;
    /*padding: 0.2rem 0.3rem;*/
    white-space: nowrap;
    border-radius: 0.76rem;
    margin-right: 0.25rem;
  }
}
</style>
