<template>
  <div>
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/7d4qVdow.png"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/7d4qVdow.png"
    ></page-banner>
    <div class="content">
      <div class="top">
        <div class="title-box wow fadeInUp">
          <h1>{{ setting.title }}</h1>
        </div>
        <div class="content-html wow dcss fadeInUp">
          <div v-html="setting.contentText"></div>
        </div>
      </div>
      <ul class="bottom">
        <li class="wow fadeInUp">
          <span @click="onBackPage">Back</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner/PageBanner.vue";
export default {
  name: "PrivacyPolicy",
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  asyncData({ store, route }) {
    return store.dispatch("getNewsDetail", { id: route.query.id });
  },
  components: {
    PageBanner,
  },
  data() {
    return {};
  },
  methods: {
    onBackPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.content {
  min-height: 6rem;
  background-color: #f5f5f5;
  padding: 0 2.6rem;
}
.content .top {
  width: 100%;
  min-height: 10rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
  margin-top: -2rem;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  padding: 0 1.5rem;
}

.content .top .title-box {
  padding: 0.6rem 0;
  border-bottom: 1px solid #e5e5e5;
}
.content .top .title-box h1 {
  font-size: var(--subtitle-size);
  font-weight: normal;
}
.content .top .title-box span {
  font-family: "DINPro";
  font-size: var(--text-size);
  color: #888888;
  display: block;
  margin-top: 0.3rem;
}
.content .top .content-html {
  /*font-size: 0.16rem;*/
  font-size: var(--text-size);
  padding: 0.6rem 0 1.5rem;
}
.content .bottom {
  width: 100%;
  height: 1.75rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}
.content .bottom li {
  font-size: initial;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
  color: #888888;
}
.content .bottom li:hover {
  color: #2178c9;
}
.content .bottom li span {
  font-size: var(--text-size);
  color: inherit;
}
.content .bottom li img {
  width: calc(100vw / 1920 * 8);
}
.content .bottom li:first-child img {
  margin-right: calc(100vw / 1920 * 20);
}
.content .bottom li:last-child img {
  margin-left: calc(100vw / 1920 * 20);
}

@media all and (max-width: 960px) {
  .content {
    padding: 0;
  }
  .content .top {
    margin-top: 0;
    padding: 0 1rem;
  }
  .content .top .title-box span {
    margin-top: 0.5rem;
  }
  .content .bottom {
    height: 2.75rem;
  }
}
</style>
