import notification from "ant-design-vue/lib/notification";
import { getCookie } from "@/utils/util";

/**
 * @param {string} telNumber
 * @returns {Boolean}
 */
export function validTelNumber(number) {
  const reg =
    /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d)|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d))$)/;
  return reg.test(number);
}

export function validMile(mile) {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;
  return reg.test(mile);
}

export function strNotNull(val) {
  return val != null && val != undefined && val != "";
}

export function validMessage(messageData) {
  let { leaveName, mobile, content } = messageData;
  if (!strNotNull(leaveName)) {
    return {
      status: 0,
      msg: "please enter name",
    };
  }
  if (!strNotNull(mobile)) {
    return {
      status: 0,
      msg: "Please enter the email",
    };
  }
  if (!validMile(mobile)) {
    return {
      status: 0,
      msg: "email format is incorrect",
    };
  }
  if (!strNotNull(content)) {
    return {
      status: 0,
      msg: "Please enter message information",
    };
  }
  let sendMsgTime = getCookie("sendMsgTime");
  if (sendMsgTime) {
    let nowTime = parseInt(new Date().getTime() / 1000);
    sendMsgTime = parseInt(new Date(sendMsgTime - 0).getTime() / 1000);
    if (sendMsgTime - nowTime > 0) {
      notification.warning({
        message: "message",
        description:
          "The message interval is too short, please try again later!",
      });
      return {
        status: 0,
        msg: "The message interval is too short, please try again later!",
      };
    }
  }
  return {
    status: 1,
    msg: "Passed the verification",
  };
}
