<template>
  <div>
    <detail-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/7d4qVdow.png"
      @backPage="backToList"
    ></detail-banner>
    <div class="content">
      <div class="top">
        <h1 class="wow fadeInUp">{{ projectDetail.title }}</h1>
        <div
          class="content-html dcss wow fadeInUp"
          v-html="projectDetail.content || ''"
        ></div>
      </div>
      <ul class="bottom">
        <li class="wow fadeInUp">
          <span @click="backToList">Back to list</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import leftArrow01 from "../../../assets/images/case-left-arrow-01.png";
import leftArrow02 from "../../../assets/images/case-left-arrow-02.png";
import rightArrow01 from "../../../assets/images/case-right-arrow-01.png";
import rightArrow02 from "../../../assets/images/case-right-arrow-02.png";
import detailBanner from "./component/detailBanner";
import { mapGetters } from "vuex";
export default {
  asyncData({ store, route }) {
    return store.dispatch("getProjectDetail", {
      id: route.query.id,
    });
  },
  name: "CaseDetail",
  components: {
    detailBanner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["projectDetail"]),
  },
  mounted() {
    if (this.projectDetail) {
      this.$store.dispatch("getProjectDetail", {
        id: this.$route.query.id,
        type: this.$route.query.type,
      });
    }
  },
  methods: {
    mouseoverLi(e) {
      let type = e.currentTarget.getAttribute("type");
      if (type == "step") {
        e.currentTarget.children[0].src = leftArrow02;
      } else if (type == "next") {
        e.currentTarget.children[1].src = rightArrow02;
      }
    },
    mouseoutLi(e) {
      let type = e.currentTarget.getAttribute("type");
      if (type == "step") {
        e.currentTarget.children[0].src = leftArrow01;
      } else if (type == "next") {
        e.currentTarget.children[1].src = rightArrow01;
      }
    },
    turnOver(id) {
      if (id) {
        this.$router.replace({ path: "/case/detail", query: { id } });
      }
    },
    backToList() {
      // let { type } = this.$route.query;
      this.$router.replace(`/case/list`);
    },
    getStrType(typeId) {
      let typeVal = null;
      switch (typeId) {
        case 40:
          typeVal = "city";
          break;
        case 41:
          typeVal = "rural";
          break;
        case 42:
          typeVal = "new";
          break;
        default:
          typeVal = null;
          break;
      }
      return typeVal;
    },
  },
};
</script>

<style scoped>
.content {
  min-height: 6rem;
  background-color: #f5f5f5;
  padding: 0 2.6rem;
}
.content .top {
  width: 100%;
  min-height: 10rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
  margin-top: -2rem;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  padding: 0 1.5rem;
}
.content .top h1 {
  font-size: var(--subtitle-size);
  font-weight: normal;
  padding: 0.6rem 0;
  border-bottom: 1px solid #e5e5e5;
}
.content .top .content-html {
  font-size: var(--text-size);
  padding: 0.6rem 0 1.5rem;
}
.content .bottom {
  width: 100%;
  height: 1.75rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}
.content .bottom li {
  font-size: initial;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
  color: #888888;
}
.content .bottom li:hover {
  color: #2178c9;
}
.content .bottom li span {
  font-size: var(--text-size);
  color: inherit;
}
.content .bottom li img {
  width: calc(100vw / 1920 * 8);
}
.content .bottom li:first-child img {
  margin-right: calc(100vw / 1920 * 20);
}
.content .bottom li:last-child img {
  margin-left: calc(100vw / 1920 * 20);
}
@media all and (max-width: 1200px) {
}
@media all and (max-width: 960px) {
  .content {
    padding: 0;
  }
  .content .top {
    margin-top: 0;
    padding: 0 1rem;
  }
  .content .top h1 {
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .content .bottom {
    height: 2.75rem;
  }
}
</style>
