import Vue from "vue";
import App from "./App";
import { createRouter } from "./router";
import { createStore } from "./store";
import "./icons/index";

import global from "./utils/global";

import "./assets/style/animate.min.css";

import "swiper/css/swiper.css";

import "ant-design-vue/lib/notification/style/index.css";
import "ant-design-vue/lib/pagination/style/index.css";
import "ant-design-vue/lib/input/style/index.css";
import "ant-design-vue/lib/empty/style/index.css";
import "ant-design-vue/lib/icon/style/index.css";
import "ant-design-vue/lib/spin/style/index.css";

import "./assets/style/reset.css";
import "./assets/style/index.css";
import { generatorDynamicRouter } from "@/router/asyncRouter";

Vue.use(global);
Vue.config.productionTip = false;

// var controller = new ScrollMagic.Controller();

export default async () => {
  const res = await generatorDynamicRouter();
  const router = createRouter();
  if (res) {
    router.addRoute(res);
  }
  const store = createStore();
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });
  return Promise.resolve({ app, router, store });
};
