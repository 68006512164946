const elTransition =
  "0.3s height ease-in-out, 0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out";
const pcElTransition = ".2s all linear";
const mobileTransition = {
  "before-enter"(el) {
    el.style.transition = elTransition;
    if (!el.dataset) el.dataset = {};

    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;

    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
  },

  enter(el) {
    el.dataset.oldOverflow = el.style.overflow;
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + "px";
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    } else {
      el.style.height = "";
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    }

    el.style.overflow = "hidden";
  },

  "after-enter"(el) {
    el.style.transition = "";
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
  },

  "before-leave"(el) {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;

    el.style.height = el.scrollHeight + "px";
    el.style.overflow = "hidden";
  },

  leave(el) {
    if (el.scrollHeight !== 0) {
      el.style.transition = elTransition;
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
    }
  },

  "after-leave"(el) {
    el.style.transition = "";
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  },
};
const pcTransition = {
  "before-enter"(el) {
    el.style.transition = pcElTransition;
    el.style.top = "110%";
    el.style.opacity = "0";
  },

  enter(el) {
    el.style.top = "110%";
    el.style.opacity = "0";
  },

  "after-enter"(el) {
    // el.style.transition = "";
    el.style.top = "90%";
    el.style.opacity = "1";
  },

  "before-leave"(el) {
    el.style.transition = pcElTransition;
    el.style.top = "110%";
    el.style.opacity = "1";
  },

  leave(el) {
    el.style.top = "110%";
    el.style.opacity = "0";
  },

  "after-leave"(el) {
    el.style.top = "";
    el.style.opacity = "";
    el.style.transition = "";
  },
};
export default {
  name: "MenuTransition",
  functional: true,
  render(h, { props, children }) {
    const data = {
      on: props.isMobile ? mobileTransition : {},
      props: {
        isMobile: {
          type: Boolean,
          default: false,
        },
      },
      duration: "1000",
    };
    return h("transition", data, children);
  },
};
