// import Layout from "../layout/Layout.vue";
// import Home from "../views/Home/index.vue";
// import TypicalCase from "../views/Case/TypicalCase/TypicalCase.vue";
// import CaseDetail from "../views/Case/CaseDetail/detail.vue";
// import StrategyPage from "../views/Strategy/index.vue";
// import NewsDetail from "../views/News/detail.vue";
// import NewsPage from "../views/News/index.vue";
// import About from "../views/About/index.vue";
import Error404 from "../views/Error/Error404.vue";
import Error500 from "../views/Error/Error500.vue";

export default [
  // {
  //   path: "",
  //   name: "Layout",
  //   component: Layout,
  //   redirect: "/",
  //   children: [
  //     {
  //       path: "/",
  //       name: "Home",
  //       component: Common,
  //       meta: {
  //         title: "首页",
  //       },
  //     },
  //     {
  //       path: "/solution",
  //       name: "Solution",
  //       component: Solution,
  //       redirect: "/solution/resilient-city",
  //       meta: {
  //         title: "解决方案",
  //       },
  //       children: [
  //         {
  //           path: "resilient-city",
  //           name: "ResilientCity",
  //           component: Common,
  //           meta: {
  //             title: "韧性城市",
  //           },
  //         },
  //         {
  //           path: "revitalize-rural",
  //           name: "RevitalizeRural",
  //           component: Common,
  //           meta: {
  //             title: "乡村振兴",
  //           },
  //         },
  //         {
  //           path: "new-scene",
  //           name: "NewScene",
  //           component: Common,
  //           meta: {
  //             title: "新型场景",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/product",
  //       name: "Product",
  //       component: Products,
  //       redirect: "/product/panda-box",
  //       meta: {
  //         title: "产品中心",
  //       },
  //       children: [
  //         {
  //           path: "panda-box",
  //           name: "PandaBox",
  //           component: Common,
  //           meta: {
  //             title: "Panda-Box",
  //           },
  //         },
  //         {
  //           path: "bee-box",
  //           name: "BeeBox",
  //           component: Common,
  //           meta: {
  //             title: "Bee-Box",
  //           },
  //         },
  //         {
  //           path: "wind-light",
  //           name: "WindLightEnergy",
  //           component: Common,
  //           meta: {
  //             title: "全离网风光互补供能系统",
  //           },
  //         },
  //         {
  //           path: "rtu-desc",
  //           name: "RtuDesc",
  //           component: Common,
  //           meta: {
  //             title: "远程终端控制单元（RTU）",
  //           },
  //         },
  //         {
  //           path: "phoenix-nest",
  //           name: "PhoenixNest",
  //           component: Common,
  //           meta: {
  //             title: "凤巢运维系统",
  //           },
  //         },
  //         {
  //           path: "red-bee",
  //           name: "RedBee",
  //           component: Common,
  //           meta: {
  //             title: "红蜂系统",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/case/detail",
  //       name: "CaseDetail",
  //       component: CaseDetail,
  //       meta: {
  //         title: "典型案例-详情",
  //       },
  //     },
  //     {
  //       path: "/case",
  //       name: "TypicalCase",
  //       component: TypicalCase,
  //       meta: {
  //         title: "典型案例",
  //       },
  //       redirect: "/case/list",
  //       children: [
  //         {
  //           path: "/case/list",
  //           name: "CaseList",
  //           component: Common,
  //           meta: {
  //             title: "典型案例-全部",
  //           },
  //         },
  //         {
  //           path: "/case/list-city",
  //           name: "CityCaseList",
  //           component: Common,
  //           meta: {
  //             title: "典型案例-城市",
  //           },
  //         },
  //         {
  //           path: "/case/list-rural",
  //           name: "RuralCaseList",
  //           component: Common,
  //           meta: {
  //             title: "典型案例-乡村",
  //           },
  //         },
  //         {
  //           path: "/case/list-new",
  //           name: "NewCaseList",
  //           component: Common,
  //           meta: {
  //             title: "典型案例-新兴",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/strategy",
  //       name: "StrategyPage",
  //       component: StrategyPage,
  //       redirect: "/strategy/market",
  //       meta: {
  //         title: "战略布局",
  //       },
  //       children: [
  //         {
  //           path: "market",
  //           name: "Market",
  //           component: Common,
  //           meta: {
  //             title: "市场布局",
  //           },
  //         },
  //         {
  //           path: "development",
  //           name: "Development",
  //           component: Common,
  //           meta: {
  //             title: "研发布局",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/news",
  //       name: "NewsPage",
  //       component: NewsPage,
  //       children: [
  //         {
  //           path: "enterprise",
  //           name: "NewsEnterprise",
  //           component: Common,
  //           meta: {
  //             title: "企业新闻",
  //           },
  //         },
  //         {
  //           path: "industry",
  //           name: "NewsIndustry",
  //           component: Common,
  //           meta: {
  //             title: "行业新闻",
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/news/detail",
  //       name: "NewsDetail",
  //       component: NewsDetail,
  //       meta: {
  //         title: "新闻中心-详情",
  //       },
  //     },
  //     {
  //       path: "/recruit",
  //       name: "Recruit",
  //       component: Common,
  //       meta: {
  //         title: "人才招聘",
  //       },
  //     },
  //     {
  //       path: "/about*",
  //       name: "About",
  //       component: About,
  //       meta: {
  //         title: "关于我们",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/404",
    name: "Error404",
    component: Error404,
    meta: {
      title: "404",
    },
  },
  {
    path: "/500",
    name: "Error500",
    component: Error500,
    meta: {
      title: "500",
    },
  },
];
