<template>
  <div class="case-list-swiper-component">
    <div class="case-image-list case-box">
      <swiper
        ref="mySwiper"
        :options="caseSwiperOpting"
        @slideChange="handleSwiperChange"
      >
        <template v-for="(item, index) in caseListData">
          <swiper-slide :key="item.id">
            <div
              :data-wow-delay="`${0.1 * (index + 1)}s`"
              class="case-item wow fadeInRight"
            >
              <a :href="`/case/detail?id=${item.id}`">
                <div class="pic-box">
                  <div
                    :style="{
                      backgroundImage: `url(${item.imageUrl})`,
                    }"
                    class="content-img"
                  ></div>
                  <p>{{ item.title }}</p>
                </div>
                <div class="text-box">
                  <p>Project scale:{{ item.scale }}</p>
                  <p>Coverage:{{ item.scope }}</p>
                </div>
              </a>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <div class="pagination-com">
      <swiper-pagination
        :pageNum="pageNum"
        v-model="swiperPage"
        @change="handelChangePage"
      ></swiper-pagination>
    </div>
  </div>
</template>

<script>
import SwiperPagination from "@/components/SwiperPagination/SwiperPagination";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "CaseListSwiper",
  components: { SwiperPagination, Swiper, SwiperSlide },
  props: {
    caseListData: {
      type: Array,
      default: () => [],
    },
    swiperPageGroup: {
      type: Number,
      default: 1,
    },
    swiperId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      caseImageSwiper: null,
      swiperPage: 0,
      caseSwiperOpting: {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 1,
        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          751: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
        on: {
          init: () => {
            this.setPageNum();
          },
          breakpoint: () => {
            this.setPageNum();
          },
        },
      },
      pageNum: 0,
    };
  },
  watch: {
    caseListData() {
      this.setPageNum();
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    pageSize() {
      if (this.$store.getters.windowsWidth > 1200) {
        return 4;
      } else if (
        this.$store.getters.windowsWidth > 751 &&
        this.$store.getters.windowsWidth <= 1200
      ) {
        return 3;
      } else {
        return 2;
      }
    },
    // pageNum() {
    //   if (this.caseListData) {
    //     return Math.ceil(
    //       (this.caseListData.length - this.pageSize) / this.swiperPageGroup + 1
    //     );
    //   } else {
    //     return 0;
    //   }
    // },
  },
  mounted() {},
  methods: {
    setPageNum() {
      if (this.caseListData) {
        this.pageNum = Math.ceil(
          (this.caseListData.length - this.pageSize) / this.swiperPageGroup + 1
        );
      } else {
        this.pageNum = 2;
      }
    },
    handleSwiperChange() {
      this.swiperPage = Math.floor(
        this.swiper.realIndex / this.swiperPageGroup
      );
    },
    handelChangePage(index) {
      if (this.swiper) {
        this.swiper.slideTo(index * this.swiperPageGroup);
      }
    },
  },
};
</script>

<style scoped>
.case-list-swiper-component {
  box-sizing: border-box;
  width: 100%;
}

.case-box .case-item {
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.case-box .case-item a {
  text-decoration: none;
}
.case-box .case-item:nth-child(4n) {
  margin: 0;
}
.case-box .case-item .pic-box {
  position: relative;
  overflow: hidden;
  height: calc(100vw * 0.15);
  border-radius: 0.1rem;
}
.case-box .case-item .pic-box::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  background: -webkit-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9)
  );
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}
.case-box .case-item .pic-box .content-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center #f5f5f5;
  background-size: cover;
  transform: scale(1);
  transition: all 0.15s ease-in;
}
.case-box .case-item:hover .pic-box .content-img {
  transform: scale(1.1);
}
.case-box .case-item .pic-box p {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0.2rem;
  font-size: var(--subtext-size);
}
.case-box .case-item .text-box {
  position: relative;
  font-size: var(--subtext-size);
  padding: calc(100vw * 0.02) calc(100vw * 0.005);
  border-bottom: 1px solid #dfdfdf;
}
.case-box .case-item .text-box p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333;
}
.case-box .case-item .text-box p:nth-child(2) {
  margin-top: calc(100vw * 0.005);
}
.case-box .case-item .text-box::after {
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  transition: all 0.15s ease-in;
  width: 0;
  background-color: #2178c9;
  bottom: -1px;
}
.case-box .case-item:hover .text-box::after {
  width: 100%;
}
.pagination-com {
  margin-top: 0.7rem;
}
@media screen and (max-width: 1200px) {
  .case-box .case-item .pic-box {
    height: calc(100vw * 0.23);
  }
}
@media screen and (max-width: 750px) {
  .case-box .case-item .pic-box {
    height: calc(100vw * 0.3);
  }
}
</style>
