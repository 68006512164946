<template>
  <div class="image-frame" :style="{ paddingBottom: scale }">
    <div class="image-panel">
      <img :src="imgUrl" :alt="imgAlt" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ScaleImage",
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
    imgAlt: {
      type: String,
      required: false,
    },
    scale: {
      type: [Number, String],
      required: true,
    },
  },
};
</script>

<style scoped>
.image-frame {
  position: relative;
  font-size: 0;
}
.image-panel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-panel img {
  max-width: 100%;
  max-height: 100%;
}
</style>
