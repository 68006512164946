<template>
  <div class="enterprise-page" id="news-title">
    <item-title
      class="page-title"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <a-spin :spinning="loadNews">
      <new-list :news-list="newsList" />
      <a-pagination
        v-model="currentPage"
        :hideOnSinglePage="true"
        :default-page-size="pageSize"
        :total="newsListTotal"
        @change="newsListSizeChange"
        class="a-pagination wow fadeInUp"
        :simple="paginationSimple"
      />
    </a-spin>
  </div>
</template>

<script>
import NewList from "@/components/NewList/NewList.vue";
import { mapGetters } from "vuex";
import ItemTitle from "@/components/ItemTitle/ItemTitle";

export default {
  name: "NewsPageList",
  components: {
    NewList,
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 12,
      paginationSimple: false,
      loadNews: false,
    };
  },
  async serverPrefetch() {
    if (this.setting.pageNum) {
      this.pageNum = this.setting.pageNum;
    }
    try {
      await this.handleGetList(
        this.setting.type,
        this.currentPage,
        this.pageNum
      );
    } catch (e) {
      console.log("serverPrefetch:", e);
    }
  },

  computed: {
    ...mapGetters(["windowsWidth"]),
    ...mapGetters("news", ["newsList", "newsListTotal", "newsListType"]),
  },
  watch: {
    windowsWidth(newData) {
      if (newData > 960) {
        this.paginationSimple = false;
      } else {
        this.paginationSimple = true;
      }
    },
  },
  mounted() {
    if (this.setting.pageNum) {
      this.pageNum = this.setting.pageNum;
    }
    if (
      this.newsListType != this.setting.type ||
      this.newsList.length == 0 ||
      this.newsListTotal == 0
    ) {
      let type = this.setting.type;
      this.handleGetList(type, this.currentPage, this.pageNum);
    }
    if (this.windowsWidth > 960) {
      this.paginationSimple = false;
    } else {
      this.paginationSimple = true;
    }
  },
  methods: {
    async handleGetList(typeId, page, limit) {
      this.loadNews = true;
      await this.$store.dispatch("news/getNewsPageData", {
        page: page,
        limit: limit,
        type: typeId,
      });
      this.loadNews = false;
    },
    newsListSizeChange(page) {
      if (document && document.getElementById) {
        document
          .getElementById("news-title")
          .scrollIntoView({ behavior: "smooth" });
      }
      this.handleGetList(this.setting.type, page, this.pageNum);
    },
  },
};
</script>

<style scoped>
.enterprise-page {
  padding-bottom: 1.5rem;
}
.page-title {
  padding: 1rem 1rem 0.7rem;
}
.a-pagination {
  margin-top: 0.2rem;
}
@keyframes borderWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes borderWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
