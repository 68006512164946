<template>
  <div class="title">
    <h2 class="wow fadeInLeft" :style="{ color: titleColor }">{{ title }}</h2>
    <span
      v-if="subTitle"
      :style="{ color: subTitleColor, '--sty-color': subTitleColor }"
      class="wow fadeInRight"
      >{{ subTitle }}</span
    >
  </div>
</template>

<script>
export default {
  name: "HomeItemTitle",
  props: {
    title: {
      type: String,
      request: true,
    },
    subTitle: {
      type: String,
      default: null,
    },
    titleColor: {
      type: String,
      default: "#222222",
    },
    subTitleColor: {
      type: String,
      default: "#888888",
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  --sty-color: #ffffff;
}
.title h2 {
  font-size: var(--h1title-size);
  color: #222222;
  font-weight: bold;
}
.title span {
  font-family: DINPro;
  font-size: var(--entitle-size);
  font-weight: 400;
  color: #888888;
  position: relative;
}
.title span:after {
  content: "";
  position: absolute;
  left: -1.2rem;
  width: 1rem;
  height: 1px;
  background: var(--sty-color);
  opacity: 0.2;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 960px) {
  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .title span:after {
    display: none;
  }
}
</style>
