<template>
  <div class="footer">
    <div class="left-side">
      <accordion-menu :menu-data="menuDataList"></accordion-menu>
      <p data-wow-delay="0.2s" class="copyright-box wow fadeInUp">
        {{ websiteCopyright }}<br />
        Guangdong Province ICP Filing:<a
          href="https://beian.miit.gov.cn"
          target="_blank"
          >{{ websiteRecord }}</a
        >
      </p>
    </div>
    <div class="right-side">
      <ul class="qr-box wow fadeInUp">
        <li>
          <div class="qr-background">
            <a
              href="https://www.linkedin.com/company/forigin-environmental-technology-company/?viewAsMember=true"
              title="LinkedIn"
              target="_blank"
            >
              <svg-icon name="linkedin"></svg-icon>
            </a>
            <!--            <img-->
            <!--              src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/TWHqraah.jpg"-->
            <!--              alt="Forigin LinkedIn"-->
            <!--              title="Forigin LinkedIn"-->
            <!--            />-->
          </div>
          <!--          <p>Scan to follow Forigin LinkedIn account</p>-->
        </li>
        <li>
          <div class="qr-background">
            <a
              href="https://www.youtube.com/channel/UCVFusqEjTL6jBwz7eZzPVFQ"
              title="YouTube"
              target="_blank"
              ><svg-icon name="youtube"></svg-icon
            ></a>
            <!--            <img-->
            <!--              src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/HDjZOWvL.png"-->
            <!--              alt="Forigin Wechat account"-->
            <!--              title="Forigin Wechat account"-->
            <!--            />-->
          </div>
          <!--          <p>Scan to follow Forigin Wechat account</p>-->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AccordionMenu from "@/components/AccordionMenu/AccordionMenu";
import { mapGetters } from "vuex";
export default {
  name: "MainFooter",
  components: {
    AccordionMenu,
  },
  computed: {
    ...mapGetters(["menuData", "configData"]),
    menuDataList() {
      let menuNames = ["首页", "HOMEPAGE"];
      let menuData = this.menuData.filter(({ menuName }) => {
        return menuNames.indexOf(menuName) == -1;
      });
      return menuData;
    },
    websiteCopyright() {
      return (
        this.configData["website_copyright"] ||
        "福瑞莱环保科技（深圳）股份有限公司 版权所有 Copyright © 2022"
      );
    },
    websiteRecord() {
      return this.configData["website_record"] || "粤ICP备16035820号";
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #041d42;
  width: 100%;
  box-sizing: border-box;
  /*align-items: center;*/
  align-content: center;
  justify-content: space-between;
  display: flex;
  padding: 1rem;
}
.left-side {
  margin-right: 0.8rem;
}
.left-side .copyright-box {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.24rem;
  font-size: var(--text-size);
  margin-bottom: 0;
}
.left-side .copyright-box a {
  color: inherit;
}
.left-side .copyright-box a:hover {
  color: #2178c9;
}
.right-side .qr-box {
  display: flex;
  /*align-items: center;*/
}
.right-side .qr-box li {
  /*display: flex;*/
  /*align-items: center;*/
  /*align-content: center;*/
  padding: 0.5rem 0.15rem;
  font-size: 0;
  transition: all 0.15s ease-in;
  transform: translateY(0);
  text-align: center;
  /*margin-right: 0.5rem;*/
}
.right-side .qr-box li:last-child {
  margin-right: 0rem;
}
/*.right-side .qr-box li:hover {*/
/*  transform: translateY(-10px);*/
/*}*/
.right-side .qr-box li .qr-background {
  /*width: 1.8rem;*/
  /*border-radius: 0.1rem;*/
  /*margin-right: 0.2rem;*/
  overflow: hidden;
  font-size: 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.right-side .qr-box li .qr-background img {
  width: 100%;
}
.right-side .qr-box li .qr-background svg {
  font-size: .50rem;
}
.right-side .qr-box li p {
  color: #ffffff;
  font-size: var(--subtext-size);
  margin-top: 0.1rem;
}

@media screen and (max-width: 960px) {
  .footer {
    padding: 1rem 0.5rem;
    flex-direction: column;
  }
  .footer .left-side {
    width: 100%;
    order: 2;
    margin: 0;
  }
  .left-side .copyright-box {
    text-align: center;
    margin: 1rem 0;
    font-size: var(--subtext-size);
  }

  .right-side {
    order: 1;
    width: 100%;
    overflow: hidden;
    margin: 0;
  }
  .right-side .qr-box {
    justify-content: flex-end;
  }

  .right-side .qr-box li {
    padding: 0.5rem;
    flex-direction: column;
    align-items: center;
    max-width: 30%;
  }
  .right-side .qr-box li .qr-background {
    /*border-radius: 0.5rem;*/
    width: auto;
    margin: 0 auto;
  }
  .right-side .qr-box li .qr-background svg {
    font-size: 1rem;
  }
  .right-side .qr-box li p {
    text-align: center;
    margin-top: 0.3rem;
  }

  .mobile-menu-box li {
    position: relative;
    padding: 1.2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-menu-box li::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    transform: scaleY(0.5);
  }
  .mobile-menu-box li p {
    font-size: 1.2rem;
  }
  .mobile-menu-box li .chindren {
    max-height: 0px;
    width: 90%;
    margin: 0 auto;
    padding-top: 0;
    transition: all 0.25s ease-in;
    overflow: hidden;
  }
  .mobile-menu-box li .chindren dd:not(:last-child) {
    padding-bottom: 1.2rem;
  }
  .mobile-menu-box li .chindren dd {
    opacity: 0.5;
    font-size: 1.1rem;
  }
  .mobile-menu-box li .open-menu-icon {
    width: 1.5rem;
    transform: rotate(0);
    transition: all 0.15s ease-in;
  }
}
@media all and (max-width: 750px){
  .right-side .qr-box li .qr-background svg {
    font-size: 1.5rem;
  }
}
</style>
