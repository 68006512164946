<template>
  <div class="typical-case">
    <HomeItemTitle
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></HomeItemTitle>
    <CaseListSwiper
      class="home-case-swiper"
      :case-list-data="homeCaseList"
    ></CaseListSwiper>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CaseListSwiper from "@/components/CaseListSwiper/CaseListSwiper.vue";
import HomeItemTitle from "@/components/HomeItemTitle/HomeItemTitle.vue";
export default {
  name: "TypicalCase",
  components: { HomeItemTitle, CaseListSwiper },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      caseRoundIndex: 0,
      homeCaseSwiper: null,
    };
  },
  computed: {
    ...mapGetters(["homeCaseList"]),
  },
  async serverPrefetch() {
    await this.$store.dispatch("getHomeCaseList", { type: this.setting.type });
  },
  async mounted() {
    if (this.homeCaseList == null) {
      await this.$store.dispatch("getHomeCaseList", {
        type: this.setting.type,
      });
    }
  },
  methods: {},
};
</script>
<style scoped>
.typical-case {
  overflow: hidden;
  background-color: #f8f8f8;
  padding: 1rem;
}
.typical-case .case-box {
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
}
.home-case-swiper {
  margin-top: 0.7rem;
}
@media screen and (min-width: 961px) {
  .typical-case .dot-box {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .typical-case .case-box li {
    /*width: calc(100% / 4 - 0.262em);*/
    /*margin-right: 0.35em;*/
  }
  .case-box li .pic-box {
    height: calc(100vw * 0.15);
    border-radius: 0.1rem;
  }
  .case-box li .text-box {
    font-size: 0.14rem;
    padding: calc(100vw * 0.02) calc(100vw * 0.005);
    border-bottom: 1px solid #dfdfdf;
  }
  .case-box li .pic-box p {
    padding: 20px calc(100vw * 0.005);
    font-size: 0.14rem;
  }

  .case-box li .text-box::after {
    width: 0;
    background-color: #2178c9;
    bottom: -1px;
  }
  .case-box li:hover .text-box::after {
    width: 100%;
  }
  .dot-box,
  .dot-box .dot-item {
    display: flex;
  }
}
@media screen and (max-width: 960px) {
  .typical-case .dot-box {
    display: none;
  }
  .typical-case .case-box li {
    /*width: calc(100% * 0.7);*/
    /*margin-right: calc(100vw * 0.04);*/
  }
  .case-box li .pic-box {
    height: calc(100vw * 0.4);
    border-radius: calc(100vw * 0.03);
  }
  .case-box li .text-box {
    font-size: 1rem;
    padding: 1.5rem 0.5rem;
    position: relative;
  }
  .case-box li .text-box::after {
    width: 100%;
    transform: scaleY(0.5);
    background-color: rgba(34, 34, 34, 0.2);
    bottom: 0;
  }
  .case-box li .pic-box p {
    padding: 1rem 0.5rem;
    font-size: 1rem;
  }
  .typical-case .case-box {
    overflow-x: auto;
    padding: 0 1rem 1rem;
  }
  .typical-case .case-box::-webkit-scrollbar {
    display: none;
  }
}
</style>
