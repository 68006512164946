<template>
  <div class="corporate-culture" id="corporate-culture">
    <item-title
      class="title-box"
      title-color="#ffffff"
      sub-title-color="#ffffff"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="culture-content">
      <ul class="missionAndIdea">
        <li class="wow fadeInLeft" data-wow-delay="0.1s">
          <h1>Corporate mission</h1>
          <!--          <h5>Our Mission</h5>-->
          <i></i>
          <p>
            Lead the world into a new era of decentralized wastewater treatment
          </p>
        </li>
        <li class="wow fadeInLeft" data-wow-delay="0.3s">
          <h1>Business philosophy</h1>
          <!--          <h5>Business Philosophy</h5>-->
          <i></i>
          <p>Integrity, responsibility, innovation, symbiosis</p>
        </li>
      </ul>
      <ul class="visionAndLocation">
        <li class="wow fadeInUp" data-wow-delay="0.1s">
          <div>
            <h1>Corporate vision</h1>
            <!--            <h5>Corporate Vision</h5>-->
            <i></i>
          </div>
          <div class="better_water_better_life_container">
            <img
              src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/rca0ZYig.png"
              alt="Better water, Better life"
              class="better_water_better_life"
            />
          </div>
        </li>
        <li class="wow fadeInUp" data-wow-delay="0.3s">
          <div>
            <h1>Corporate Positioning</h1>
            <!--            <h5>Corporate Positioning</h5>-->
            <i></i>
          </div>
          <div class="position">
            <p>Global perspective, historical depth, local roots</p>
            <p>
              Integrate international cutting-edge technology, respond to
              national strategic needs, and serve the main battlefield of
              circular economy
            </p>
            <p>A global pioneer in decentralized wastewater treatment</p>
          </div>
        </li>
      </ul>
      <ul class="senseOfWorth">
        <li class="wow fadeInRight" data-wow-delay="0.1s">
          <div>
            <h1>Core values</h1>
            <!--            <h5>Core Value</h5>-->
            <i></i>
          </div>
          <p>Stay hungry, Stay foolish,<br />Empower others, Be a better you</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * 企业文化
 */
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "CompanyCulture",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}

.corporate-culture {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/corporate_culture_pic.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-color: #f8f8f8;
  height: 8.68rem;
  background-size: auto 100%;
}
.corporate-culture .culture-content {
  padding: 0 1rem;
  display: flex;
}
.corporate-culture .culture-content > div {
  height: 5rem;
}
.corporate-culture .culture-content .missionAndIdea li {
  width: 4.6rem;
  box-sizing: border-box;
  padding: 0.6rem 0 0 0.6rem;
  transition: all 0.3s ease-in;
}
.corporate-culture .culture-content .missionAndIdea li,
.corporate-culture .culture-content .visionAndLocation li {
  height: 2.5rem;
  color: #ffffff;
}
.corporate-culture .culture-content .missionAndIdea li h1,
.corporate-culture .culture-content .visionAndLocation li h1,
.corporate-culture .culture-content .senseOfWorth h1 {
  font-size: var(--h3title-size);
  width: 100%;
  transition: all 0.5s ease-in;
  transform: translateX(0);
  transform: translateY(0);
}

.corporate-culture .culture-content .missionAndIdea li h5,
.corporate-culture .culture-content .visionAndLocation li h5,
.corporate-culture .culture-content .senseOfWorth h5 {
  font-size: var(--subtext-size);
  font-weight: normal;
  margin-top: calc(100vw / 1920 * 10);
  font-family: "DINPro";
  width: 100%;
  transition: all 0.5s ease-in;
  transform: translateX(0);
  transform: translateY(0);
}
.corporate-culture .culture-content .missionAndIdea li i,
.corporate-culture .culture-content .visionAndLocation li i,
.corporate-culture .culture-content .senseOfWorth i {
  display: block;
  width: 0.1rem;
  height: 1px;
  background-color: #ffffff;
  margin: 0.3rem 0;
  transition: all 0.5s ease-in;
  transform: translateX(0);
  transform: translateY(0);
}

.corporate-culture .culture-content .missionAndIdea li p {
  font-size: var(--text-size);
  width: 100%;
  transition: all 0.5s ease-in;
  transform: translateX(0);
}
.corporate-culture .culture-content .missionAndIdea li:first-child {
  background-color: rgba(33, 120, 201, 0.8);
}

.corporate-culture .culture-content .missionAndIdea li:last-child {
  background-color: rgba(15, 47, 166, 0.8);
}

.corporate-culture .culture-content .visionAndLocation {
  width: 8rem;
}

.corporate-culture .culture-content .visionAndLocation li {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0 0 0 0.6rem;
  transition: all 0.3s ease-in;
}
.corporate-culture .culture-content .visionAndLocation li div i {
  margin-bottom: 0;
}
.corporate-culture
  .culture-content
  .visionAndLocation
  li
  .better_water_better_life {
  width: 3.72rem;
  margin-left: 0.8rem;
}
.corporate-culture .culture-content .visionAndLocation li .position p,
.corporate-culture .culture-content .senseOfWorth li p {
  font-size: var(--text-size);
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}
.corporate-culture .culture-content .visionAndLocation li .position p::after {
  content: "";
  position: absolute;
  left: calc(100vw / 1920 * -10);
  width: 0.05rem;
  height: 0.05rem;
  border-radius: 50%;
  background-color: #ffffff;
  top: calc(var(--text-size) / 2);
  transform: translateY(50%);
}
.corporate-culture .culture-content .visionAndLocation li .position {
  margin-left: 0.8rem;
}
.corporate-culture
  .culture-content
  .visionAndLocation
  li
  div
  p:not(:last-child) {
  margin-bottom: 0.1rem;
}
.corporate-culture .culture-content .senseOfWorth {
  background-color: rgba(13, 27, 76, 0.8);
  width: 4.6rem;
  transition: all 0.3s ease-in;
}

.corporate-culture .culture-content .senseOfWorth li {
  color: #ffffff;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  height: 5rem;
  box-sizing: border-box;
  padding-left: 1rem;
}
.corporate-culture .culture-content .senseOfWorth li i {
  margin-bottom: 0.6rem;
}
.corporate-culture .culture-content .senseOfWorth li p {
  transition: all 0.5s ease-in;
  transform: translateY(0);
}
.corporate-culture .culture-content .visionAndLocation li:first-child {
  background-color: rgba(15, 47, 166, 0.8);
}
.corporate-culture .culture-content .visionAndLocation li:last-child {
  background-color: rgba(33, 120, 201, 0.8);
}

@media all and (min-width: 961px) {
  .corporate-culture .culture-content .missionAndIdea li:hover h1,
  .corporate-culture .culture-content .visionAndLocation li:hover h1,
  .corporate-culture .culture-content .missionAndIdea li:hover h5,
  .corporate-culture .culture-content .visionAndLocation li:hover h5,
  .corporate-culture .culture-content .missionAndIdea li:hover i,
  .corporate-culture .culture-content .visionAndLocation li:hover i,
  .corporate-culture .culture-content .missionAndIdea li:hover p,
  .corporate-culture .culture-content .senseOfWorth:hover p {
    transform: translateX(10px);
  }
  .corporate-culture .culture-content .senseOfWorth:hover i,
  .corporate-culture .culture-content .missionAndIdea li:hover i,
  .corporate-culture .culture-content .visionAndLocation li:hover i {
    width: 0.5rem;
  }
  .corporate-culture .culture-content .missionAndIdea li:first-child:hover {
    background-color: rgba(33, 120, 201, 0.7);
  }
  .corporate-culture .culture-content .missionAndIdea li:last-child:hover {
    background-color: rgba(15, 47, 166, 0.7);
  }
  .corporate-culture .culture-content .visionAndLocation li:first-child:hover {
    background-color: rgba(15, 47, 166, 0.7);
  }
  .corporate-culture .culture-content .visionAndLocation li:last-child:hover {
    background-color: rgba(33, 120, 201, 0.7);
  }
  .corporate-culture .culture-content .senseOfWorth:hover {
    background-color: rgba(13, 27, 76, 0.7);
  }
  .corporate-culture .culture-content .senseOfWorth:hover p {
    transform: translateY(-10px);
  }
  .corporate-culture .culture-content .senseOfWorth:hover h1,
  .corporate-culture .culture-content .senseOfWorth:hover h5,
  .corporate-culture .culture-content .senseOfWorth:hover i {
    transform: translateY(-10px);
  }
}
@media all and (max-width: 1200px) {
  .corporate-culture .culture-content .missionAndIdea li i,
  .corporate-culture .culture-content .visionAndLocation li i,
  .corporate-culture .culture-content .senseOfWorth i {
    margin: 0.1rem 0;
  }
  .corporate-culture .culture-content .senseOfWorth li i {
    margin-bottom: 0.2rem;
  }
  .corporate-culture .culture-content .visionAndLocation li .position {
    margin-left: 0.5rem;
  }
  .corporate-culture .culture-content .missionAndIdea li,
  .corporate-culture .culture-content .visionAndLocation li {
    padding-left: 0.3rem;
    height: 3rem;
  }
  .corporate-culture .culture-content .senseOfWorth li {
    padding-left: 0.5rem;
    height: 6rem;
  }
}
@media all and (max-width: 960px) {
  .corporate-culture .culture-content .senseOfWorth li > p,
  .corporate-culture .culture-content .visionAndLocation li .position p,
  .corporate-culture .culture-content .missionAndIdea li p {
    font-size: 0.4rem;
  }
  .corporate-culture .culture-content .senseOfWorth li,
  .corporate-culture .culture-content .visionAndLocation li {
    display: block;
    height: auto;
  }
  .corporate-culture
    .culture-content
    .visionAndLocation
    li
    .better_water_better_life,
  .corporate-culture .culture-content .visionAndLocation li .position p {
    margin-top: 0.5rem;
  }

  .title-box {
    padding: 1.5rem 1rem 0.7rem;
  }
  .corporate-culture {
    height: auto;
  }
  .corporate-culture .culture-content {
    padding: 0 0.5rem 0.5rem 0.5rem;
    display: flex;
  }
  .corporate-culture .culture-content {
    flex-direction: column;
  }
  .corporate-culture .culture-content .missionAndIdea {
    /*display: flex;*/
  }
  .corporate-culture .culture-content .senseOfWorth,
  .corporate-culture .culture-content .visionAndLocation li,
  .corporate-culture .culture-content .missionAndIdea li {
    width: 100%;
    height: auto;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  .corporate-culture .culture-content .missionAndIdea li:last-child {
    background-color: rgba(33, 120, 201, 0.8);
  }
  .corporate-culture .culture-content .senseOfWorth i,
  .corporate-culture .culture-content .visionAndLocation li div i,
  .corporate-culture .culture-content .missionAndIdea li i {
    display: none;
  }
  /*.corporate-culture .culture-content .visionAndLocation li {*/
  /*  height: auto;*/
  /*}*/

  .corporate-culture .culture-content .visionAndLocation {
    width: 100%;
  }
  .corporate-culture .culture-content .visionAndLocation li .position p::after {
    content: "";
    position: absolute;
    left: calc(100vw / 1920 * -20);
    width: 0.1rem;
    height: 0.1rem;
    top: calc(var(--text-size) / 2);
    border-radius: 50%;
    background-color: #ffffff;
  }
  .corporate-culture
    .culture-content
    .visionAndLocation
    li
    .better_water_better_life_container {
    text-align: right;
  }
  .corporate-culture
    .culture-content
    .visionAndLocation
    li
    .better_water_better_life {
    width: 11rem;
  }
  /*.corporate-culture .culture-content .senseOfWorth li {*/
  /*  padding: 0;*/
  /*  height: auto;*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  flex-wrap: nowrap;*/
  /*}*/
  .corporate-culture .culture-content .senseOfWorth li div {
    white-space: nowrap;
    margin-right: 0.5rem;
  }
  /*.corporate-culture .culture-content .senseOfWorth li > p {*/
  /*  white-space: nowrap;*/
  /*}*/
}
</style>
