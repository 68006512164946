<template>
  <div class="case-list-page">
    <div class="typical-case-content">
      <a-spin :spinning="loadList">
        <case-list
          :project-list="projectList"
          :type="projectListType"
        ></case-list>
        <a-pagination
          :hideOnSinglePage="true"
          v-model="currentPage"
          :default-page-size="pageNum"
          :total="projectListTotal"
          @change="projectListSizeChange"
          class="a-pagination wow fadeInUp"
          :simple="paginationSimple"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CaseList from "@/components/CaseList/CaseList.vue";

export default {
  name: "CasePageList",
  components: { CaseList },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  async serverPrefetch() {
    if (this.setting.pageNum) {
      this.pageNum = this.setting.pageNum;
    }
    try {
      await this.handleGetList(
        this.setting.type,
        this.currentPage,
        this.pageNum
      );
    } catch (e) {
      console.log("serverPrefetch:", e);
    }
  },
  data() {
    return {
      currentPage: 1,
      pageNum: 12,
      paginationSimple: false,
      loadList: false,
    };
  },
  computed: {
    ...mapGetters("project", [
      "projectList",
      "projectListType",
      "projectListTotal",
    ]),
    ...mapGetters(["windowsWidth"]),
  },
  watch: {
    windowsWidth(newData) {
      if (newData > 960) {
        this.paginationSimple = false;
      } else {
        this.paginationSimple = true;
      }
    },
  },
  mounted() {
    if (this.setting.pageNum) {
      this.pageNum = this.setting.pageNum;
    }
    if (
      this.projectListTotal == null ||
      this.projectList.length == 0 ||
      this.projectListType != this.setting.type
    ) {
      let type = this.setting.type;
      this.handleGetList(type, this.currentPage, this.pageNum);
    }
    if (this.windowsWidth > 960) {
      this.paginationSimple = false;
    } else {
      this.paginationSimple = true;
    }
  },
  methods: {
    projectListSizeChange(page) {
      if (document && document.getElementById) {
        let caseTitle = document.getElementById("case-title");
        if (caseTitle) {
          caseTitle.scrollIntoView({ behavior: "smooth" });
        }
      }
      this.handleGetList(this.setting.type, page, this.pageNum);
    },
    async handleGetList(typeId, page, limit) {
      // console.log("typeId:", typeId);
      this.loadList = true;
      await this.$store.dispatch("project/getProjectPageData", {
        page: page,
        limit: limit,
        type: typeId,
      });
      this.loadList = false;
    },
  },
};
</script>

<style scoped>
.typical-case-content {
  padding-bottom: 1.5rem;
}
.a-pagination {
  margin-top: 0.2rem;
}

@media screen and (max-width: 750px) {
}
</style>
