<template>
  <div id="homeBanner" class="home-banner banner">
    <swiper
      ref="mySwiper"
      :auto-update="true"
      :auto-destroy="true"
      :options="homeBannerSwiperOpting"
      @slideChange="handleSwiperChange"
    >
      <template v-for="(item, index) in setting.bannerList">
        <swiper-slide
          :key="index"
          :data-swiper-autoplay="item.stopTime * 1000 || 5 * 1000"
        >
          <div class="banner-item" :key="index">
            <a :href="item.href ? item.href : '/'" :title="item.title">
              <img :src="item.pcImageUrl" mobilehide :alt="item.title" />
              <img :src="item.mobileImageUrl" pchide :alt="item.title" />
            </a>
            <div v-if="item.content" v-html="item.content"></div>
          </div>
        </swiper-slide>
      </template>
    </swiper>

    <ul class="banner-dot-box">
      <template v-for="(item, index) in setting.bannerList">
        <li
          :class="{ 'banner-dot-item': bannerIndex == index }"
          :index="index"
          :key="index"
          @click="dotItemOver(index)"
        >
          <p class="wrapper right">
            <i
              class="circleProgress rightcircle"
              :style="{ animationDuration: `${item.stopTime || 5}s` }"
            ></i>
          </p>
          <p class="wrapper left">
            <i
              class="circleProgress leftcircle"
              :style="{ animationDuration: `${item.stopTime || 5}s` }"
            ></i>
          </p>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
export default {
  name: "HomeBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      bannerIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    homeBannerSwiperOpting() {
      return {
        observer: true,
        observeParents: true,
        autoplay: {
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
      };
    },
  },
  methods: {
    handleSwiperChange() {
      this.bannerIndex = this.swiper.realIndex;
    },
    dotItemOver(index) {
      if (this.swiper === null) {
        return;
      }
      if (index != null) {
        this.swiper.slideTo(index);
      }
    },
  },
};
</script>

<style scoped>
.home-banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.banner-item {
  /*height: 100vh;*/
  font-size: 0;
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.banner-item a {
  display: block;
  width: 100%;
}
.banner-item img {
  font-size: 0;
  width: 100%;
  text-align: center;
  object-fit: cover;
}
.home-banner .banner-dot-box {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 2;
}
.home-banner .banner-dot-box li {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}
.home-banner .banner-dot-box li::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.banner-dot-box .banner-dot-item .wrapper {
  width: 10px;
  height: 20px;
  position: absolute;
  top: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.banner-dot-box .banner-dot-item .right {
  right: 0;
}
.banner-dot-box .banner-dot-item .left {
  left: 0;
}
.banner-dot-item .wrapper .circleProgress {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  display: block;
}
.banner-dot-item .wrapper .rightcircle {
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  right: 0;
  animation: CPL_right linear 0s 1 normal;
}
.banner-dot-item .wrapper .leftcircle {
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  left: 0;
  animation: CPL_left linear 0s 1 normal;
}
@-webkit-keyframes CPL_right {
  0% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    -webkit-transform: rotate(45deg);
  }
  50% {
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    -webkit-transform: rotate(225deg);
  }
  100% {
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    -webkit-transform: rotate(225deg);
  }
}
@-webkit-keyframes CPL_left {
  0% {
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    -webkit-transform: rotate(45deg);
  }
  50% {
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    -webkit-transform: rotate(45deg);
  }
  100% {
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    -webkit-transform: rotate(225deg);
  }
}
@media all and (min-width: 961px) {
  .banner-item img {
    font-size: 0;
    width: 100%;
    text-align: center;
    object-fit: cover;
    height: 100vh;
  }
}
@media screen and (max-width: 1440px) {
  .banner-item {
    height: auto;
  }
  .banner-item img {
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .content .banner .banner-dot-box {
    transform: scale(0.7);
  }
}
</style>
