import axios from "axios";

const baseUrl = process.client
  ? process.env.CLIENT_BASE_URL
  : process.env.SERVER_BASE_URL;
const http = axios.create({
  baseURL: baseUrl,
  timeout: 50000,
});

http.interceptors.request.use(
  function (config) {
    // console.log(process.env);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (res) {
    let { data } = res || {};
    let { code } = data || {};

    if (code === 0) {
      return Promise.resolve(data);
    } else {
      const err = new Error();
      err.code = code;
      err.message = data.msg || "unknown error";
      return Promise.reject(err);
    }
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export { http as request };
