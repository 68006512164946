<template>
  <div class="Layout">
    <div padhide>
      <top-nav></top-nav>
    </div>
    <MainHeader :topFixed="isPosition"></MainHeader>
    <transition name="fade-transform" mode="out-in">
      <router-view></router-view>
    </transition>
    <MainFooter></MainFooter>
  </div>
</template>

<script>
import MainFooter from "@/components/Footer/Footer";
import MainHeader from "@/components/Header/MainHeader";
import { mapGetters } from "vuex";
import TopNav from "@/components/TopNav/TopNav.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Layout",
  components: {
    TopNav,
    MainHeader,
    MainFooter,
  },
  async serverPrefetch() {
    await this.$store.dispatch("getConfig");
    await this.$store.dispatch("getMenu");
  },
  computed: {
    ...mapGetters(["menuData", "configData", "isPosition"]),
    fixedHeader() {
      return this.$route.meta.fixedHeader == undefined ||
        this.$route.meta.fixedHeader == null
        ? true
        : false;
    },
  },
  data() {
    return {
      isTopBoxShow: false,
    };
  },
  async mounted() {
    if (this.menuData.length == 0) {
      await this.$store.dispatch("getMenu");
    }
    if (this.configData == null) {
      await this.$store.dispatch("getConfig");
    }
  },
};
</script>

<style>
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.2s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
