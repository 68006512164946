<template>
  <div class="company-profile-component">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="company-introduction">
      <div class="left-side wow fadeInLeft">
        <h1>{{ setting.descTitle }}</h1>
        <template v-for="(item, index) in setting.descContents">
          <p :key="index">
            {{ item }}
          </p>
        </template>
      </div>
      <div class="right-side wow fadeInRight">
        <img :src="setting.imageUrl" :alt="setting.descTitle" />
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "CompanyProfile",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.company-introduction {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 0.8em;
}
.company-introduction .left-side {
  width: 8.3rem;
}
.company-introduction .left-side h1 {
  font-size: var(--h3title-size);
  color: #2178c9;
  font-weight: normal;
  margin-bottom: 0.35rem;
}

.company-introduction .left-side p {
  font-size: var(--text-size);
  color: #222222;
  text-align: justify;
  margin-bottom: calc(100vw / 1920 * 45);
  word-break: break-all;
  word-wrap: break-word;
}

.company-introduction .right-side img {
  width: 8.3rem;
}
@media all and (max-width: 960px) {
  .company-introduction {
    flex-direction: column;
  }
  .company-introduction .left-side,
  .company-introduction .right-side img {
    width: 100%;
  }
}
</style>
