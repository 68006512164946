<template>
  <div id="app">
    <router-view></router-view>
    <ScrollTop></ScrollTop>
    <Loading v-show="pageLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ScrollTop from "@/components/ScrollTop/ScrollTop";
import Loading from "@/components/Loading/Loading";
// import { sendRecord } from "@/api/public";
export default {
  components: { ScrollTop, Loading },
  data() {
    return {
      isTopBoxShow: false,
      beforeUnload_time: 0,
      gap_time: 0,
    };
  },
  computed: {
    ...mapGetters(["configData", "pageLoading"]),
  },
  mounted() {
    if (this.$isServer) return;
    this.removeLoading();
    window.addEventListener("scroll", this.debounce(this.scroll, 100));
    window.addEventListener("resize", this.handleChangeWindowWidthStatus);
    this.handleChangeWindowWidthStatus();
  },
  methods: {
    handleChangeWindowWidthStatus() {
      let windowClientWidth = document.documentElement.clientWidth;
      this.$store.dispatch("changeWindowWidth", windowClientWidth);
    },
    /**
     * 截流函数一段时间只会执行一次
     * @param callback
     * @param time
     * @returns {(function(*=): void)|*}
     */
    debounce(callback, time = 1000) {
      let timeId = null;
      return function (e) {
        if (timeId !== null) {
          clearTimeout(timeId);
        }
        timeId = setTimeout(() => {
          callback.call(this, e);
          timeId = null;
        }, time);
      };
    },
    scroll() {
      this.$store.dispatch("changeScrollTop");
      if (this.$store.getters.scrollTop > 0) {
        this.$store.commit("setPosition", { value: true });
      } else {
        this.$store.commit("setPosition", { value: false });
      }
    },
    removeLoading() {
      if (this.$isServer) return;
      let pageLoading = document.getElementById("bodyPageLoading");
      if (!pageLoading) return;
      pageLoading.remove();
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },
};
</script>

<style>
@font-face {
  font-family: "en-regular.otf";
  font-display: fallback;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/AlibabaPuHuiTi-2-55-Regular.otf");
}
@font-face {
  font-family: "en-extraBold.otf";
  font-display: fallback;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/AlibabaPuHuiTi-2-95-ExtraBold.otf");
}

@font-face {
  font-family: "en-regular";
  font-display: fallback;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/AlibabaPuHuiTi-2-55-Regular.otf");
}
@font-face {
  font-family: "en-extraBold";
  font-display: fallback;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/AlibabaPuHuiTi-2-95-ExtraBold.otf");
}

@font-face {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-display: fallback;
  font-style: normal;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/msyhbd.ttc");
}
@font-face {
  font-family: "Microsoft YaHei";
  font-weight: 400;
  font-display: fallback;
  font-style: normal;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/msyh.ttc");
}

@font-face {
  font-family: "DINPro";
  font-weight: 400;
  font-display: fallback;
  font-style: normal;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/DINPro-Regular.otf")
    format("opentype");
}
@font-face {
  font-family: "DINPro";
  font-weight: 500;
  font-display: fallback;
  font-style: normal;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/DINPro-Medium.otf")
    format("opentype");
}
@font-face {
  font-family: "DINPro";
  font-weight: bold;
  font-display: fallback;
  font-style: normal;
  src: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/fonts/DINPRO-BOLD.OTF")
    format("opentype");
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  font-family: Microsoft YaHei;
}

body {
  /*-ms-overflow-style: none;*/
}

/* 设置滚动条的样式 */
body::-webkit-scrollbar {
  border-radius: 6px;
  width: 0px;
}
body::-webkit-scrollbar-button {
  display: none;
}
/* 滚动槽 */
body::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
/* 滚动条滑块 */
/* 默认时隐藏*/
body::-webkit-scrollbar-thumb {
  width: 4px;
  height: 60px;
  background-color: #ccc;
  border-radius: 3px;
}

.a-pagination {
  text-align: center;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-bottom: 2px;
}
@media screen and (min-width: 961px) {
  .global-top-box {
    width: 0.8rem;
    height: 0.8rem;
    right: 0.2rem;
    bottom: -1rem;
  }
}

.label-hover {
  cursor: pointer;
}
.label-hover:hover {
  color: #2178c9 !important;
}
</style>
