<template>
  <div class="list-content">
    <ul class="list" v-if="newsList.length > 0">
      <template v-for="(item, index) in newsList">
        <li
          :key="item.id"
          :index="index"
          class="wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <a :href="`/news/detail?id=${item.id}`" :title="item.title">
            <div class="img-panel">
              <div class="img-box">
                <img :src="item.imageUrl" :alt="item.title" />
              </div>
            </div>
            <div class="content">
              <h3>{{ item.title }}</h3>
              <p>{{ item.remark }}</p>
            </div>
            <div class="date">
              <span>{{ formatTextInfo(item.releaseTime) }}</span>
              <svg-icon name="right-arrow"></svg-icon>
            </div>
          </a>
        </li>
      </template>
    </ul>
    <a-empty class="wow fadeInUp" v-else description="No data" />
  </div>
</template>

<script>
import { formatTime } from "@/utils/util";
export default {
  name: "NewList",
  props: {
    newsList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatTextInfo(data) {
      return formatTime(data, "YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.list-content {
  position: relative;
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}
.list li {
  background-color: #f2f2f2;
  width: calc(100% / 3 - 0.23rem);
  border-radius: 0.1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: 0 0 0.4rem 0;
  display: flex;
  flex-direction: column;
}
.list li:not(:nth-child(3n)) {
  margin-right: calc((3 * 0.229rem) / 2);
}
.list li .img-panel {
  width: 100%;
  position: relative;
  padding-bottom: calc(100% * 0.75);
  box-sizing: border-box;
}
.list li .img-box {
  position: absolute;
  width: 100%;
  height: 100%;
  /*height: -webkit-calc(100% * 0.75);*/
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
}
.list li .img-box img {
  width: 100%;
  height: 100%;
  /*max-width: 100%;*/
  /*max-height: 100%;*/
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  object-fit: cover;
}
.list li .content {
  flex-grow: 1;
}
.list li .content h3 {
  color: #222222;
  font-size: var(--h3title-size);
  font-weight: inherit;
  padding: 0 0.4rem;
  margin: 0.2rem 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list li .content p {
  font-size: var(--text-size);
  color: rgba(34, 34, 34, 0.65);
  padding: 0 0.4rem;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.list li .date {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 0.4rem;
  font-size: var(--text-size);
  font-family: "DINPro";
  color: #9f9f9f;
  margin-top: 0.2rem;
}
.list li .date span {
  transition: all 0.3s ease-in-out;
}
.list li .date svg {
  transition: right 0.3s ease-in-out;
  font-size: var(--subtitle-size);
  position: relative;
  right: 0.3rem;
  opacity: 0;
}

@media all and (min-width: 961px) {
  .list li:hover {
    background-color: #2178c9;
  }
  .list li:hover .img-box img {
    transform: scale(1.1);
  }
  .list li:hover .date svg,
  .list li:hover .content h3,
  .list li:hover .content p {
    color: #ffffff;
  }
  .list li:hover .date span {
    color: #222222;
  }
  .list li:hover .date svg {
    right: -0rem;
    opacity: 1;
  }
}

@media all and (max-width: 960px) {
  .list {
    margin-bottom: 1rem;
  }
  .list li {
    width: calc(100% / 2 - 0.23rem);
  }
  .list li:not(:nth-child(3n)) {
    margin-right: inherit;
  }
  .list li:not(:nth-child(2n)) {
    margin-right: calc((2 * 0.229rem) / 2);
  }
  .list li .date svg {
    right: -0rem;
    opacity: 1;
  }
}
</style>
