<template>
  <div class="talent-cecruitment">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <p
      class="describe wow fadeInUp"
      v-if="setting.descText"
      v-html="setting.descText"
    ></p>
    <ul class="list">
      <li class="wow fadeInUp">
        <div class="recruit-title">
          <span class="col1">Position Name</span>
          <span class="col2">Based In</span>
          <span class="col3 mobile-hide">Position Type</span>
          <span class="col4 mobile-hide">Headcount</span>
          <span class="col5">Date of issue</span>
        </div>
      </li>
      <template v-for="(item, index) in recruitList">
        <li :key="item.id" class="wow fadeInUp">
          <div
            class="recruit-title"
            :class="{ open: detailOpen.indexOf(item.id) >= 0 }"
            @click="spread(item.id)"
          >
            <span class="col1">{{ item.recruitName }}</span>
            <span class="col2">{{ item.cityName }}</span>
            <span class="col3 mobile-hide">
              {{ recruit_type[item.recruitType - 1].name }}
            </span>
            <span class="col4 mobile-hide">{{ item.personNumber }}</span>
            <span class="col5">{{ item.recruitDate }}</span>
            <svg-icon name="down" class="down_icon mobile-hide"></svg-icon>
          </div>
          <down-transition>
            <div class="ask" v-show="detailOpen.indexOf(item.id) >= 0">
              <p class="subtitle">Job Duties</p>
              <div class="content" v-html="item.jobRegions"></div>
              <p class="subtitle">Job Requirements</p>
              <div class="content" v-html="item.jobRequire"></div>
              <div
                class="delivery"
                @click="setDeliveryState(item)"
                :data-index="index"
              >
                <img
                  src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/SP2uTllG.png"
                  alt=""
                />
                <span>Submit Resume</span>
              </div>
            </div>
          </down-transition>
        </li>
      </template>
      <a-empty
        class="wow fadeInUp"
        v-if="recruitList.length <= 0"
        description="Currently no data available"
      />
    </ul>
    <send-resume ref="sendResumeModel"></send-resume>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemTitle from "@/components/ItemTitle/ItemTitle";
import { formatTime } from "@/utils/util";
import DownTransition from "@/components/Transition/DownTransition";
import SendResume from "@/components/SendResume/SendResume.vue";
export default {
  name: "TalentRecruitment",
  components: { DownTransition, ItemTitle, SendResume },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  async serverPrefetch() {
    await this.$store.dispatch("getRecruitList");
  },
  data() {
    return {
      deliveryState: false,
      recruit_type: [
        { value: "1", name: "Comprehensive" },
        { value: "2", name: "Technology" },
        { value: "3", name: "Project Type" },
        { value: "4", name: "Sales" },
        { value: "5", name: "R&D" },
      ],
      form: {
        resumeName: "",
        resumeMobile: "",
        resumeEmail: "",
        recruitId: "",
        recruitName: "",
        resumeFile: "",
        resumeFileName: "",
      },
      tipsShow: false,
      detailOpen: [],
      selectRecruit: null,
      showFromModal: false,
    };
  },
  computed: {
    ...mapGetters(["recruitList"]),
  },
  watch: {
    deliveryState(newVal) {
      if (!newVal) {
        this.resetForm();
        if (this.tipsShow) {
          this.tipsShow = false;
        }
      }
    },
  },
  async mounted() {
    if (this.recruitList && this.recruitList.length <= 0) {
      await this.$store.dispatch("getRecruitList");
    }
  },
  methods: {
    formatTextInfo(data) {
      return formatTime(data, "YYYY-MM-DD");
    },
    spread(id) {
      if (this.detailOpen.indexOf(id) >= 0) {
        this.detailOpen = this.detailOpen.filter((item) => {
          return item != id;
        });
      } else {
        this.detailOpen.push(id);
      }
    },
    setDeliveryState(itemData) {
      this.$refs.sendResumeModel.onOpen(itemData);
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.describe {
  font-size: var(--text-size);
  color: #222222;
  padding: 0 1rem;
}

.list {
  padding: 0 calc(100vw / 1920 * 100);
  margin: 0.8rem 0 1.5rem;
}
.list li {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-content: center;
  align-items: center;
  color: #444444;
  min-height: 0.68rem;
}
.down_icon {
  font-size: calc(var(--text-size) + 0.1rem);
  position: absolute;
  right: 0.1rem;
  color: #7f7f7f;
  transition: all 0.3s ease-in;
  transform: rotate(0deg);
}
.recruit-title.open .down_icon {
  transform: rotate(180deg);
}
.list li:first-child {
  background-color: #fafafa;
  border: 1px solid #e1e1e1;
  color: #888888;
  border-top-color: #595857;
}
.list li .recruit-title {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  padding-right: 0.5rem;
}
.list li:not(:first-child) .recruit-title {
  cursor: pointer;
}
.list li:not(:first-child) .recruit-title:hover {
  background-color: rgba(33, 120, 201, 0.1);
}
.list li .recruit-title > span {
  font-size: var(--text-size);
  color: inherit;
  flex: 1;
  box-sizing: border-box;
  padding: 0.3rem 0.4rem;
}
.list li:not(:first-child) {
  border-bottom: 1px solid #e1e1e1;
}
.list li:not(:first-child) .recruit-title > span:last-child {
  font-family: "DINPro";
}
.list li .ask {
  width: 100%;
  padding: 0.5rem 0 0.5rem 0.5rem;
  transition: all 0.3s ease-in;
  overflow: hidden;
  /*max-height: 0;*/
}
.list li .ask .subtitle {
  font-size: var(--h3title-size);
  color: #333333;
  margin-bottom: calc(100vw / 1920 * 30);
}
.list li .ask .content {
  color: #888888;
  font-size: var(--text-size);
  margin-bottom: 0.6rem;
  line-height: 1.5;
}
.list li .ask .delivery {
  color: #ffffff;
  background-color: #2178c9;
  /*width: 1.4rem;*/
  /*height: 0.5rem;*/
  padding: 0.2rem 0.5rem;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 0.04rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.list li .ask .delivery:hover {
  opacity: 0.9;
}
.list li .ask .delivery img {
  width: calc(var(--text-size) + 0.03rem);
  margin-right: 0.13rem;
}
.list li .ask .delivery span {
  font-size: var(--text-size);
}
@media all and (max-width: 1200px) {
}
@media all and (max-width: 960px) {
  .list li .ask .delivery {
    padding: 0.5rem 0.8rem;
  }
  .mobile-hide {
    display: none;
  }
  .list li .recruit-title {
    padding-right: 0;
  }
  .list li .recruit-title > span {
    padding: 0.5rem 0.2rem;
  }
  .list li .recruit-title > span.col1 {
    flex: 1 0 5rem;
  }
  .list li .recruit-title > span.col5 {
    flex: 1 0 2rem;
  }
}
</style>
