<template>
  <div class="applicable-scene-component">
    <img
      src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/rAptpZt4.png"
    />
    <div class="scene-content">
      <item-title
        class="scene-title"
        :title="setting.title"
        :sub-title="setting.subTitle"
        title-color="#ffffff"
        sub-title-color="#ffffff"
      ></item-title>
      <ul class="img-list">
        <template v-for="(item, index) in setting.sceneImgData">
          <li
            :key="index"
            class="wow fadeInRight"
            :data-wow-delay="`${0.1 * (index + 1)}s`"
          >
            <div class="image">
              <img :src="item.imgUrl" />
            </div>
            <p>{{ item.name }}</p>
          </li>
        </template>
      </ul>
    </div>
    <img
      src="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/c6GeequC.png"
    />
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "ApplicableScene",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.applicable-scene-component {
  position: relative;
  font-size: 0;
}
.applicable-scene-component > img {
  width: 100%;
}
.scene-content {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/qa9CSx0Q.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  min-height: 5.16rem;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-top: -1px;
}
.scene-content .scene-title {
  padding: 0.5rem 0;
}
.img-list {
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  /*justify-content: space-between;*/
}
.img-list li {
  /*margin-right: 0.3rem;*/
  overflow: hidden;
  padding: 0 0.15rem;
  box-sizing: border-box;
  flex: 1;
}
/*.img-list li:last-child {*/
/*  margin-right: 0;*/
/*}*/
.img-list li .image {
  text-align: center;
  font-size: 0;
  max-width: 100%;
}
.img-list li .image img {
  width: 100%;
  border-radius: 50%;
}
.img-list li p {
  text-align: center;
  font-size: var(--text-size);
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.3rem;
}
@media screen and (max-width: 960px) {
  .img-list {
    flex-wrap: wrap;
  }
  .img-list li {
    width: 25%;
    flex: 0 0 25%;
    padding: 0.5rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
  }
}
</style>
