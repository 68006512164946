<template>
  <transition name="fade">
    <div class="delivery-layer" @click="handleClose" v-if="showFromModal">
      <div class="content" @click.stop="() => {}">
        <svg-icon
          @click="handleClose"
          class="close_delivery_layer"
          name="menu-close-btn"
        ></svg-icon>
        <h4>Apply for position</h4>
        <h2>{{ form.recruitName }}</h2>
        <ul class="form" v-if="!tipsShow">
          <li>
            <input type="text" placeholder="Name" v-model="form.resumeName" />
            <span class="required">required</span>
          </li>
          <li>
            <input
              type="text"
              placeholder="Mobilephone number"
              v-model="form.resumeMobile"
            />
            <span class="required">required</span>
          </li>
          <li>
            <input type="text" placeholder="Email" v-model="form.resumeEmail" />
          </li>
          <li>
            <input
              type="text"
              placeholder="Upload resume (upload Word or PDF file)"
              v-model="form.resumeFileName"
              disabled
            />
            <input
              type="file"
              ref="uploadFile"
              @change="inputUploadFile"
              style="width: 0px; height: 0px; opacity: 0"
              accept=".doc,.pdf,.docx,.PDF"
            />
            <a-spin :spinning="uploadFileLoad">
              <button class="selectFile" @click="selectFile">
                <svg-icon class="btn-svg-icon" name="resume"></svg-icon>
                {{ form.resumeFileName ? "reselect" : "Select file" }}
              </button>
            </a-spin>
          </li>
        </ul>
        <a-spin :spinning="sendLoad">
          <button class="submit" @click="sendResumeFrom" v-if="!tipsShow">
            Submit
          </button>
        </a-spin>
        <p class="tips" v-if="tipsShow">
          We have received your resume and will contact you promptly if needed.
          <br />Thank you for your submission!
        </p>
        <div class="delivery_decorate">
          <span>Recruitment email:</span>
          <a href="mailto:hr@fairylands.com.cn"
            ><span>hr@fairylands.com.cn</span></a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { sendResumeApi, uploadFile } from "@/api/public";
import { validMile, validTelNumber } from "@/utils/validate";
export default {
  name: "SendResume",
  props: {
    recruitInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        recruitId: undefined,
        recruitName: undefined,
        resumeName: "",
        resumeMobile: "",
        resumeEmail: "",
        resumeFile: "",
        resumeFileName: "",
      },
      tipsShow: false,
      showFromModal: false,
      uploadFileLoad: false,
      sendLoad: false,
    };
  },
  methods: {
    onOpen(recruitInfo) {
      this.form.recruitId = recruitInfo.id;
      this.form.recruitName = recruitInfo.recruitName;
      this.showFromModal = true;
    },
    handleClose() {
      this.resetForm();
      this.showFromModal = false;
      this.tipsShow = false;
    },
    selectFile() {
      this.$refs.uploadFile.click();
    },

    sendResumeFrom() {
      if (this.form.recruitId == "" || this.form.recruitId == null) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Failed to obtain position information. Please try again.",
        });
        return;
      }
      if (this.form.resumeName == "" || this.form.resumeName == null) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Please enter your name",
        });
        return;
      }

      if (this.form.resumeMobile == "" || this.form.resumeMobile == null) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Please enter your mobilephone number.",
        });
        return;
      }

      if (!validTelNumber(this.form.resumeMobile)) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Mobile number is entered wrong.",
        });
        return;
      }

      if (!validMile(this.form.resumeEmail)) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Email address is entered wrong.",
        });
        return;
      }

      if (this.form.resumeFile == "" || this.form.resumeFile == null) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Please upload your resume.",
        });
        return;
      }
      this.sendLoad = true;
      sendResumeApi(this.form)
        .then((res) => {
          const { code, msg } = res;
          if (code == 0) {
            this.$notification.success({
              message: "Kind reminder",
              description: msg,
            });
            this.resetForm();
            this.tipsShow = true;
          } else {
            this.$notification.error({
              message: "Kind reminder",
              description: msg,
            });
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "Kind reminder",
            description: "Submission failed. Please try again later or contact us directly.",
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    inputUploadFile(e) {
      const [file] = e.target.files;
      if (!this.validateFile(file.name)) {
        this.$notification.error({
          message: "Kind reminder",
          description: "Only Word or PDF files are permitted.",
        });
        return;
      }
      if (file.size > 30 * 1024 * 1024) {
        this.$notification.error({
          message: "Kind reminder",
          description: "The file size cannot exceed 30M.",
        });
        return;
      }
      this.uploadFileLoad = true;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "resume");
      uploadFile(formData)
        .then((res) => {
          const { code, data } = res;
          if (code == 0) {
            let { fileName, fileUrl } = data;
            this.form.resumeFileName = fileName;
            this.form.resumeFile = fileUrl;
            this.$notification.success({
              message: "Kind reminder",
              description: "Uploading successful.",
            });
          } else {
            this.$notification.error({
              message: "Kind reminder",
              description: "Uploading failed.",
            });
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "Kind reminder",
            description: "Uploading failed.",
          });
        })
        .finally(() => {
          this.uploadFileLoad = false;
        });
    },
    resetForm() {
      if (this.$refs.uploadFile) {
        this.$refs.uploadFile.value = null;
      }
      this.form = Object.assign(this.form, {
        resumeName: "",
        resumeMobile: "",
        resumeEmail: "",
        resumeFile: "",
        resumeFileName: "",
      });
    },
    validateFile(fileName) {
      const reg = /(\.doc|\.pdf|\.docx)$/g;
      return reg.test(fileName);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.delivery-layer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 9;
}
.delivery-layer .content {
  width: 7.6rem;
  z-index: 9999;
  max-height: 90%;
  overflow-y: auto;
  background-color: #ffffff;
  position: relative;
  /*padding-bottom: 1.33rem;*/
}
.delivery-layer .content h2,
.delivery-layer .content h4 {
  font-weight: normal;
  text-align: center;
  font-size: var(--text-size);
  color: #222222;
}
.delivery-layer .content h4 {
  padding: 0.75rem 0 0.17rem;
}
.delivery-layer .content h2 {
  color: #2178c9;
  padding-bottom: 0.3rem;
}
.delivery-layer .content .form li {
  width: 80%;
  border-bottom: 1px solid #dddddd;
  padding: 0.25rem 0 0.15rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivery-layer .content .form li input {
  max-width: 80%;
  width: 100%;
  border: none;
  font-size: var(--text-size);
  outline: none;
}
.delivery-layer .content .form li span {
  font-size: var(--text-size);
  color: #888888;
}
.delivery-layer .content .form li:nth-child(4) {
  /*padding-top: 0.15rem;*/
}
.delivery-layer .content .form li:nth-child(4) input {
  background-color: #ffffff;
}
.delivery-layer .content .form li .selectFile {
  border: none;
  outline: none;
  font-size: var(--text-size);
  padding: 0.15rem 0.23rem;
  color: #ffffff;
  background-color: #2177c9;
  white-space: nowrap;
  border-radius: 0.06rem;
  cursor: pointer;
}
.delivery-layer .content .form li .selectFile .btn-svg-icon {
  font-size: calc(var(--text-size) + 0.05rem);
}
.delivery-layer .content .form li .selectFile:hover {
  opacity: 0.9;
}
.delivery-layer .content .submit {
  padding: 0.15rem 0.5rem;
  border: 2px solid #2178c9;
  border-radius: 0.33rem;
  background-color: transparent;
  display: block;
  margin: 0.5rem auto;
  color: #2178c9;
  font-size: var(--text-size);
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.delivery-layer .content .submit:hover {
  background-color: #2178c9;
  color: #ffffff;
}
.delivery-layer .content .tips {
  text-align: center;
  color: #888888;
  font-size: 0.16rem;
  position: relative;
  z-index: 1;
}
.delivery-layer .content .delivery_decorate {
  /*position: absolute;*/
  /*left: 0;*/
  /*bottom: 0;*/
  width: 100%;
  /*height: auto;*/
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/gkO3Sc6R.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delivery-layer .content .delivery_decorate a {
  display: inherit;
  font-size: 0;
  vertical-align: middle;
  line-height: inherit;
}
.delivery-layer .content .delivery_decorate a:hover,
.delivery-layer .content .delivery_decorate a:link {
  font-style: normal;
  text-decoration: none;
}
.delivery-layer .content .delivery_decorate span {
  color: #ffffff;
  font-size: var(--text-size);
  padding: 0.5rem 0 0.2rem 0;
  margin-top: 0.3rem;
}
.delivery-layer .content .close_delivery_layer {
  position: absolute;
  top: 0.2rem;
  right: 0.3rem;
  font-size: 0.3rem;
  color: #007aff;
  transition: all 0.15s ease-in;
  transform: rotate(0deg);
  cursor: pointer;
}
.delivery-layer .content .close_delivery_layer:hover {
  transform: rotate(180deg);
}
@media all and (max-width: 960px) {
  .delivery-layer .content {
    width: 90%;
    position: relative;
  }
  .delivery-layer .content .close_delivery_layer {
    font-size: 0.8rem;
  }
  .delivery-layer .content .form li {
    width: 90%;
    padding: 0.5rem 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delivery-layer .content .submit {
    padding: 0.3rem 0.8rem;
    border: 2px solid #2178c9;
    border-radius: 1rem;
    margin: 0.5rem auto;
  }
  .delivery-layer .content .delivery_decorate {
    width: 100%;
    height: auto;
  }
  .delivery-layer .content .delivery_decorate span {
    margin-top: 0rem;
    display: inline-block;
    padding: 1.3rem 0 0.3rem 0;
  }
  .delivery-layer .content .form li input {
    font-size: var(--text-size);
    outline: none;
  }
  .delivery-layer .content .form li .selectFile {
    padding: 0.23rem 0.35rem;
  }
}
</style>
