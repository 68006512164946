<template>
  <div class="product-menu" id="productMenu">
    <swiper ref="mySwiper" :options="swiperOpting">
      <template v-for="(item, index) in productMenu">
        <swiper-slide :key="item.id">
          <div
            :index="index"
            class="menu-item wow fadeInLeft"
            :data-wow-delay="`${0.1 * index}s`"
            :class="{ active: activePath == item.path }"
          >
            <router-link :to="item.path" :title="item.name">
              <div class="icon-img">
                <img
                  :src="
                    activePath == item.path ? item.activeIconUrl : item.iconUrl
                  "
                />
              </div>
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "ProductMenu",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      productMenu: [
        {
          name: "Panda-Box",
          iconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/s4TOIKuG.png",
          activeIconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/A5MeEb0n.png",
          path: "/product/panda-box",
        },
        {
          name: "Bee-Box",
          iconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/qJnywKHd.png",
          activeIconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/DnLJ7oK2.png",
          path: "/product/bee-box",
        },
        {
          name: "Decentralized Renewable Energy Supply Systems",
          iconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/7T1ycfgw.png",
          activeIconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/xeODoDkw.png",
          path: "/product/wind-light",
        },
        {
          name: "Remote Terminal Control Unit(RTU)",
          iconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/SWZLMxxP.png",
          activeIconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/Z9z5d2YF.png",
          path: "/product/rtu-desc",
        },
        {
          name: "Phoenest O&M System",
          iconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/aAuoT4ka.png",
          activeIconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/qr2RX0mJ.png",
          path: "/product/phoenix-nest",
        },
        {
          name: "Red-Bee System",
          iconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/lXlPmaxC.png",
          activeIconUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/Zj9g0O5M.png",
          path: "/product/red-bee",
        },
      ],
      swiperMenu: null,
      showNum: 6,
      activePath: "",
    };
  },
  computed: {
    swiperOpting() {
      return {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        breakpoints: {
          1201: {
            slidesPerView: this.showNum,
            freeMode: false,
            spaceBetween: 10,
          },
        },
      };
    },
  },
  watch: {
    $route(to) {
      this.activePath = to.path;
    },
  },
  mounted() {
    this.activePath = this.$route.path;
  },
  methods: {
    handelToPage(path) {
      if (path) {
        window.location.href = path;
      }
    },
  },
};
</script>

<style scoped>
.product-menu {
  border-bottom: #e5e5e5 1px solid;
}
.menu-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
}
.menu-item.active:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  height: 3px;
  background-color: #2178c9;
  left: 0;
  right: 0;
}
.menu-item p {
  font-size: var(--text-size);
  font-family: DINPro;
  font-weight: 400;
  color: #222222;
  margin-top: 0.1rem;
  text-align: center;
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.menu-item:hover p,
.menu-item.active p {
  font-weight: 500;
  color: #2178c9;
}
.menu-item .icon-img {
  font-size: 0;
  height: 1.1rem;
  text-align: center;
  line-height: 1.1rem;
}
.menu-item .icon-img img {
  vertical-align: middle;
  max-height: 100%;
}
@media all and (max-width: 1200px) {
  .product-menu .menu-item {
    padding: 0.5rem 0.5rem;
  }
  .product-menu .menu-item p {
    margin-top: 0.5rem;
  }
  .product-menu .menu-item .icon-img {
    height: 1.5rem;
    line-height: 1.5rem;
  }
}
</style>
<style>
@media all and (max-width: 1200px) {
  .product-menu .swiper-slide {
    width: 30%;
    height: auto;
  }
}
</style>
