<template>
  <div class="title-banner">
    <img :src="setting.imageUrl" mobilehide />
    <img
      :src="setting.smallImageUrl ? setting.smallImageUrl : setting.imageUrl"
      pchide
    />
    <div class="banner-text wow fadeInUp" v-if="setting.bannerText">
      <p>{{ setting.bannerText }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TitleBanner",
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      showImgUrl: this.imageUrl,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
  },
  methods: {},
};
</script>

<style scoped>
.title-banner {
  display: block;
  font-size: 0;
  position: relative;
  overflow: hidden;
}
.title-banner img {
  width: 100%;
  animation: bannerAnimate 0.8s linear;
  /*animation-direction: alternate;*/
  transform: scale(1.06);
}
.title-banner .banner-text {
  font-family: DINPro;
  font-size: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  color: #ffffff;
  transform: translate(-50%, -50%);
}
.crumbs ul {
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  left: 4.1rem;
  bottom: 2.8rem;
}
.crumbs li {
  color: #ffffff;
  font-size: 0.12rem;
  margin: 0 calc(100vw / 1920 * 7);
}
@keyframes bannerAnimate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.06);
  }
}
</style>
