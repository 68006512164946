<template>
  <div class="solve-plan">
    <HomeItemTitle
      class="title-content"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></HomeItemTitle>
    <div class="content-box">
      <div class="left-swiper wow fadeInLeft" id="solvePlanSwiper">
        <swiper
          ref="mySwiper"
          :options="solvePlanSwiperOpting"
          @slideChange="handleSwiperChange"
        >
          <template v-for="(item, index) in setting.solutionData">
            <swiper-slide :key="index">
              <div class="imageShow" :index="index">
                <a :href="item.url">
                  <img :src="item.imageUrl" style="width: 100%" />
                  <div class="product_desc">
                    <p class="desc_name">{{ item.title }}</p>
                    <p class="desc_text">{{ item.shortDesc }}</p>
                  </div>
                </a>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <ul class="text-box right-label wow fadeInRight">
        <template v-for="(item, index) in setting.solutionData">
          <li
            :key="index"
            :index="index"
            :class="{ active: solvePlanIndex == index }"
            @click="handleTabChange(index)"
          >
            <p>{{ item.title }}</p>
            <p>{{ item.shortDesc }}</p>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
import HomeItemTitle from "@/components/HomeItemTitle/HomeItemTitle.vue";
export default {
  name: "SolvePlan",
  components: {
    Swiper,
    SwiperSlide,
    HomeItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      solvePlanIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["windowsWidth"]),
    solvePlanSwiperOpting() {
      return {
        direction: "vertical",
        slidesPerView: "auto",
        allowTouchMove: false,
        spaceBetween: 10,
        breakpoints: {
          961: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      };
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  watch: {},
  methods: {
    handleSwiperChange() {
      this.solvePlanIndex = this.swiper.realIndex;
    },
    handleTabChange(index) {
      if (this.swiper != null) {
        this.swiper.slideTo(index);
      }
      this.solvePlanIndex = index;
    },
  },
};
</script>

<style scoped>
.title-content {
  padding: 1rem 1rem 0.7rem;
}

.solve-plan .content-box {
  align-items: center;
  align-content: center;
  height: calc(100vw * 0.33);
  overflow: hidden;
}
.solve-plan .content-box .left-swiper {
  position: relative;
  width: 60%;
  height: 100%;
  border-radius: 0 0.5rem 0.5rem 0;
  overflow: hidden;
}
.solve-plan .content-box .left-swiper .swiper-container {
  height: 100%;
}
.solve-plan .content-box .imageShow {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.solve-plan .content-box .text-box {
  width: 40%;
  box-sizing: border-box;
  padding-left: calc(40% * 0.22);
  margin-bottom: 0;
}
.solve-plan .content-box .text-box li {
  margin: calc(100vw * 0.04) 0;
  position: relative;
  cursor: pointer;
}
.solve-plan .content-box .text-box li p:nth-child(1) {
  font-size: var(--subtitle-size);
  font-weight: bold;
  color: #888888;
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 0;
}
.solve-plan .content-box .text-box li p:nth-child(2) {
  color: #888888;
  margin-top: 0.1rem;
  font-size: var(--text-size);
  margin-bottom: 0;
}
.solve-plan .content-box .text-box li.active p:nth-child(1) {
  color: #2178c9;
}
.solve-plan .content-box .text-box li.active p:nth-child(2) {
  color: #222222;
}
.solve-plan .content-box .text-box li.active p:nth-child(1)::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0.1rem solid transparent;
  border-right: 0.17rem solid #2178c9;
  border-bottom: 0.1rem solid transparent;
  position: absolute;
  left: -0.3rem;
}
.solve-plan .content-box {
  display: flex;
}
.product_desc {
  display: none;
}
@media screen and (max-width: 960px) {
  .solve-plan {
    padding: 0 0.5rem;
  }
  .solve-plan .right-label {
    display: none;
  }
  .solve-plan .content-box {
    height: auto;
  }
  .solve-plan .content-box .left-swiper {
    width: 100%;
    border-radius: 0;
    overflow: hidden;
  }
  .solve-plan .content-box .imageShow {
    border-radius: 0.3rem;
    overflow: hidden;
    font-size: 0;
  }
  .solve-plan .content-box .imageShow:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    content: "";
    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9)
    );
    z-index: 1;
  }
  .imageShow .product_desc {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /*background-color: rgba(0, 0, 0, 0.3);*/
    font-size: 0.16rem;
    color: #ffffff;
  }
  .imageShow .product_desc {
    z-index: 9;
    padding: 0.5rem 0.7rem;
    text-align: left;
    font-family: "DINPro";
    box-sizing: border-box;
    white-space: nowrap;
  }
  .imageShow .product_desc .desc_name {
    font-weight: bold;
    font-size: var(--subtitle-size);
  }
  .imageShow .product_desc .desc_text {
    font-size: var(--text-size);
    margin-top: 0.1rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
<style>
@media screen and (max-width: 960px) {
  .solve-plan .swiper-slide {
    /*width: calc(100vw * 0.75);*/
  }
}
</style>
