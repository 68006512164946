<template>
  <div
    class="global-top-box"
    @click="bodyTop"
    :style="{
      right: isTopBoxShow ? '10px' : '-100px',
    }"
  ></div>
</template>

<script>
export default {
  name: "ScrollTop",
  data() {
    return {
      // isTopBoxShow: false,
      isPc: false,
    };
  },
  computed: {
    isTopBoxShow() {
      if (this.$store.getters.scrollTop > 800) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    let userAgent = window.navigator.userAgent;
    let isAndroid = userAgent.indexOf("Android") > -1;
    let isIos = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.isPc = !isAndroid && !isIos;
  },
  methods: {
    bodyTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.global-top-box {
  position: fixed;
  transition: right 0.3s ease-in;
  background: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/zjyw8t6b.png")
    no-repeat center center rgba(0, 0, 0, 0.5);
  background-size: 40%;
  cursor: pointer;
  z-index: 9999;
  bottom: 20%;
}
.global-top-box:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 960px) {
  .global-top-box {
    width: 1.5rem;
    height: 1.5rem;
    right: 0.5rem;
  }
}
@media screen and (max-width: 750px) {
  .global-top-box {
    width: 2.5rem;
    height: 2.5rem;
    right: 0.8rem;
  }
}
</style>
