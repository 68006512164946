export function getCookie(name) {
  let arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) {
    return unescape(arr[2]);
  } else {
    return null;
  }
}

export function toTreeeData(arr = [], pid = 0) {
  let child = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].pid == pid) {
      arr[i].children = toTreeeData(arr, arr[i].id);
      child.push({ ...arr[i] });
    }
  }
  return child;
}
export function formatTime(time = 0, format = "YYYY-MM-DD hh:mm:ss") {
  let reqTime = null;
  if (typeof time == "string") {
    reqTime = time.replaceAll("-", "/");
  } else {
    reqTime = time;
  }
  const now = new Date().getTime();
  if (!reqTime) reqTime = now;
  while (reqTime.toString().length < 13) reqTime += "0";
  const date = new Date(reqTime);
  date.getMonth();
  const arg = {
    year: formatIndex(date.getFullYear(), 4),
    month: formatIndex(date.getMonth() + 1, 2),
    day: formatIndex(date.getDate(), 2),
    hours: formatIndex(date.getHours(), 2),
    minutes: formatIndex(date.getMinutes(), 2),
    seconds: formatIndex(date.getSeconds(), 2),
  };
  switch (format) {
    case "YYYY-MM-DD hh:mm:ss":
      return `${arg.year}-${arg.month}-${arg.day} ${arg.hours}:${arg.minutes}:${arg.seconds}`;
    case "YYYY-MM-DD":
      return `${arg.year}-${arg.month}-${arg.day}`;
    case "MM-DD":
      return `${arg.month}-${arg.day}`;
    case "hh:mm:ss":
      return `${arg.hours}:${arg.minutes}:${arg.seconds}`;
    case "hh:mm":
      return `${arg.hours}:${arg.minutes}`;
    case "computed":
      if (now > reqTime) {
        const dt = Math.abs(time - now), //时间已过去多少毫秒
          S = dt / 1000,
          M = S / 60,
          H = M / 60,
          D = H / 24,
          W = D / 7;
        if (~~W > 0 && W < 3) {
          return ~~W + "周前";
        } else if (D < 7 && ~~D > 0) {
          return ~~D + "天前";
        } else if (~~H > 0 && H < 24) {
          return ~~H + "小时前";
        } else if (~~M > 0 && M < 60) {
          return ~~M + "分钟前";
        } else if (~~S > 0 && S < 60) {
          return ~~S + "秒前";
        }
      } else {
        console.log("未来的时间");
      }
      return `${arg.year}-${arg.month}-${arg.day} ${arg.hours}:${arg.minutes}:${arg.seconds}`;
  }
}

export function formatIndex(num, index, str = "0") {
  return Array(index - String(num).length + 1).join(str) + num;
}

/**
 * 滚动距离
 * @returns {number}
 */
export function getScrollTopNum() {
  let scroll_top = 0;
  if (typeof window != "object") {
    return scroll_top;
  }
  if (document.documentElement && document.documentElement.scrollTop) {
    scroll_top = document.documentElement.scrollTop;
  } else if (document.body) {
    scroll_top = document.body.scrollTop;
  }
  return scroll_top;
}

/**
 * 判断元素是否在可视范围
 * @param el 判断的dom
 * @returns {boolean}
 * 当距离屏幕100px的时候返回true
 */
export function screenSee(el) {
  //获取元素是否在可视区域
  let rect = el.getBoundingClientRect();
  // 可视大小
  let wHeight = window.innerHeight || document.documentElement.clientHeight;
  let wWidth = window.innerWidth || document.documentElement.clientWidth;
  return rect.top - wHeight < 0 && rect.right >= 0 && rect.left <= wWidth;
}
