<template>
  <div class="development-page">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <h1 class="level-title wow fadeInUp">
      {{ setting.listTitle1 || "国内高校" }}
    </h1>
    <ul class="domestic-school">
      <template v-for="item in setting.rdList1">
        <li
          :key="item.name"
          class="wow fadeInUp"
          :data-wow-delay="`${Math.ceil(Math.random() * 0.3)}s`"
          :title="item.name"
        >
          <img :src="item.imageUrl" />
          <span>{{ item.name }}</span>
        </li>
      </template>
    </ul>
    <h1 class="level-title wow fadeInUp">{{ setting.listTitle2 }}</h1>
    <ul class="domestic-school">
      <template v-for="item in setting.rdList2">
        <li
          :key="item.name"
          class="wow fadeInUp"
          :data-wow-delay="`${Math.ceil(Math.random() * 0.5)}s`"
          :title="item.name"
        >
          <img :src="item.imageUrl" />
          <span>{{ item.name }}</span>
        </li>
      </template>
    </ul>
    <img :src="setting.imageUrl" alt="" class="development_pic wow fadeInUp" />
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "RDLayout",
  components: {
    ItemTitle,
  },
  props: {
    setting: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      domesticUniversities: [
        {
          name: "北京大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/PwrwNHw1.png",
        },
        {
          name: "华北水利水电大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/oQv66Jlo.png",
        },
        {
          name: "华南农业大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/xO1ERZyg.png",
        },
        {
          name: "南方科技大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/f68kKf5a.png",
        },
        {
          name: "清华大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/gqmDZBG1.png",
        },
        {
          name: "深圳大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/8KUPzBkN.png",
        },
        {
          name: "中国科学院深圳先进技术研究院",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/OsCwdZTm.png",
        },
      ],
      foreignUniversities: [
        {
          name: "澳大利亚莫纳什大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/ObYiEJVF.png",
        },
        {
          name: "北卡罗来纳州立大学 ",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/zAspq14s.png",
        },
        {
          name: "韩国首尔大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/cn3ZKvW4.png",
        },
        {
          name: "日本宇都宫大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/M0BUJOpt.png",
        },
        {
          name: "新加坡国立大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/o12sWHcP.png",
        },
        {
          name: "宾夕法尼亚州立大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/HOPuXNEU.png",
        },
        {
          name: "东京工业大学",
          imageUrl:
            "https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/nsXpCGyQ.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}

.level-title {
  text-align: center;
  font-size: var(--describe-text-size);
  font-weight: normal;
}
.domestic-school {
  display: flex;
  justify-content: center;
  margin: 1rem auto 1.2rem;
  flex-wrap: wrap;
  padding: 0 2rem;
}
.domestic-school li {
  position: relative;
  width: 25%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}
.domestic-school li img {
  width: 1rem;
  display: block;
  margin: 0 auto 0.2rem;
}
.domestic-school li span {
  font-size: var(--text-size);
  color: #222222;
  margin: 0 auto;
  display: block;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.development_pic {
  margin: 1.8rem auto 2.4rem;
  display: block;
  width: calc(100vw / 1920 * 1329);
}
@media all and (max-width: 1200px) {
  /*.domestic-school li {*/
  /*  width: 2rem;*/
  /*}*/
  .domestic-school {
    padding: 0 1rem;
  }
}
@media all and (max-width: 960px) {
  .level-title {
    margin-top: 1rem;
    font-weight: bold;
  }
  .domestic-school {
    margin: 1.2rem auto 1.5rem;
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
  }
  .domestic-school li {
    width: 50%;
    /*padding: 0 0.5re;*/
  }
  .domestic-school li img {
    width: 2rem;
    display: block;
    margin: 0 auto 0.2rem;
  }
  .development_pic {
    margin: 1.8rem auto;
    display: block;
    width: 90%;
  }
}
</style>
