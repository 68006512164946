import { render, staticRenderFns } from "./SendResume.vue?vue&type=template&id=53c11ef8&scoped=true"
import script from "./SendResume.vue?vue&type=script&lang=js"
export * from "./SendResume.vue?vue&type=script&lang=js"
import style0 from "./SendResume.vue?vue&type=style&index=0&id=53c11ef8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c11ef8",
  null
  
)

export default component.exports