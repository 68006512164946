<template>
  <div class="introduce-component">
    <div class="introduce-content">
      <div class="content-left wow fadeInLeft">
        <div class="iamge-back">
          <div><img :src="setting.imageUrl" /></div>
        </div>
      </div>
      <div class="content-right wow fadeInRight">
        <div class="title">
          <h2>{{ setting.title }}</h2>
          <p>{{ setting.subtitle }}</p>
        </div>
        <div class="desc" v-html="setting.descText"></div>
        <div class="icons">
          <ul class="icons-list" :style="{ justifyContent: setting.iconAlign }">
            <template v-for="(item, index) in setting.iconData">
              <li
                class="icon-item"
                :index="index"
                :style="setting.iconItemStyle"
              >
                <div class="icon-img">
                  <img :src="item.iconUrl" />
                </div>
                <p>{{ item.name }}</p>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SewageDisposalIntroduce",
  props: {
    setting: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      iconData: [
        {
          name: "社康",
          image: "../../../../../static/img/waste-transfer-station.png",
        },
      ],
    };
  },
};
</script>

<style>
.introduce-component {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/0I3x2UdH.jpg");
  background-size: 19.2rem 100%;
  background-color: #1360a9;
  background-repeat: no-repeat;
  background-position: bottom center;
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
  position: relative;
}
.introduce-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.introduce-content .content-left .iamge-back {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/PcOMBpgf.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top center;
  width: 6.48rem;
  height: 6.28rem;
  padding: 0.74rem 0.84rem;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 0;
}
.introduce-content .content-left .iamge-back > div {
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.introduce-content .content-left .iamge-back img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
}
.introduce-content .content-left .iamge-back:hover img {
  transform: scale(1.1);
}
.content-right {
  max-width: 10rem;
}
.content-right .title h2 {
  font-size: var(--h1title-size);
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
}
.content-right .title p {
  font-size: var(--subtitle-size);
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.2rem;
}
.content-right .desc {
  margin-top: 0.6rem;
}
.content-right .desc p {
  margin-top: 0.35rem;
  font-size: var(--text-size);
  font-weight: 400;
  color: #ffffff;
}
.content-right .icons {
  margin-top: 0.7rem;
}
.content-right .icons .icons-list {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.content-right .icons .icons-list .icon-item {
  width: 11.11%;
  text-align: center;
  font-size: 0;
  margin-bottom: 0.4rem;
  box-sizing: border-box;
}
.content-right .icons .icons-list .icon-item .icon-img {
  height: 0.5rem;
  line-height: 0.5rem;
  overflow: hidden;
}
.content-right .icons .icons-list .icon-item img {
  vertical-align: middle;
  height: 100%;
}
.content-right .icons .icons-list .icon-item p {
  font-size: var(--text-size);
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.2rem;
}
@media all and (max-width: 960px) {
  .introduce-component {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: auto;
    width: 100%;
  }
  .content-right .title p {
    margin-top: 0.5rem;
  }
  .introduce-content {
    flex-direction: column;
  }
  .content-right {
    order: 1;
    max-width: none;
    width: 100%;
  }
  .content-left {
    order: 2;
    margin-top: 1rem;
  }
  .introduce-content .content-left .iamge-back {
    width: 10rem;
    height: 10rem;
  }
  .content-right .icons .icons-list .icon-item {
    margin-bottom: 0.8rem;
  }
  .content-right .icons .icons-list .icon-item .icon-img {
    height: 1rem;
    line-height: 1rem;
    overflow: hidden;
  }
}
</style>
