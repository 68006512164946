<template>
  <div class="product-page">
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/j82omWCr.png"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/9qQxK25k.png"
    ></page-banner>
    <product-menu></product-menu>
    <transition name="fade-transform" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import ProductMenu from "@/components/ProductMenu/ProductMenu.vue";
import PageBanner from "@/components/PageBanner/PageBanner";
export default {
  name: "ProductPage",
  components: { ProductMenu, PageBanner },
};
</script>

<style scoped>
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.2s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.product-banner {
  font-size: 0;
  overflow: hidden;
  position: relative;
}
.product-banner img {
  width: 100%;
}
.menu-item {
  height: 200px;
}
</style>
