import { defineComponent, onErrorCaptured } from "vue";
import registry from "./registry";
export default defineComponent({
  name: "PageComponentItem",
  props: {
    index: {
      type: [String, Number],
      default: 0,
    },
    componentOptions: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    onErrorCaptured((err, instance, info) => {
      console.log("PageComponentItem:", err);
      console.log("Component:", instance.name);
      console.log("error info:", info);
      return false;
    });
    return () => {
      let { componentOptions } = props;
      const Component =
        registry.modules[componentOptions.componentName] || null;
      // console.log(registry.modules);
      return (
        <div className={`page-content-item page-content-item-${props.index}`}>
          <Component setting={componentOptions.data}></Component>
        </div>
      );
    };
  },
});
