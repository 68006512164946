<template>
  <div class="strategy-page">
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/YGZPSlzp.png"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/Zt9QWjGw.png"
    ></page-banner>
    <div class="nav">
      <div class="crumbs" padhide>
        <ul class="wow fadeInLeft">
          <li class="icon">
            <svg-icon name="home"></svg-icon>
          </li>
          <li class="label-hover" @click="$router.replace({ path: `/` })">
            HOMEPAGE
          </li>
          <li class="icon">
            <svg-icon name="right"></svg-icon>
          </li>
          <li class="label-hover">STRATEGIC LAYOUT</li>
          <li class="icon">
            <svg-icon name="right"></svg-icon>
          </li>
          <li>{{ title }}</li>
        </ul>
      </div>
      <div class="tab">
        <ul>
          <li
            :class="[
              $route.path == '/strategy/market' ? 'active' : '',
              'wow',
              'fadeInRight',
            ]"
            @click="$router.push('/strategy/market')"
            data-wow-delay="0.1s"
          >
            Market Layout
          </li>
          <li
            @click="$router.push('/strategy/development')"
            :class="[
              $route.path == '/strategy/development' ? 'active' : '',
              'wow',
              'fadeInRight',
            ]"
            data-wow-delay="0.3s"
          >
            R&D Layout
          </li>
        </ul>
      </div>
    </div>
    <div class="page-content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner/PageBanner";
export default {
  name: "StrategyPage",
  components: { PageBanner },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
};
</script>

<style scoped>
.nav {
  height: 0.8rem;
  border-bottom: 1px solid #f5f5f5;
  padding: 0.1rem 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.crumbs {
  flex: 1;
}
.tab {
  width: 100%;
  flex: 0;
  white-space: nowrap;
}
.crumbs ul {
  display: flex;
  align-content: center;
  align-items: center;
}
.crumbs li {
  font-size: var(--text-size);
  margin: 0 calc(100vw / 1920 * 7);
  color: #888888;
}
.tab ul {
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.tab li {
  font-size: var(--text-size);
  color: #888888;
  margin-left: calc(100vw / 1920 * 48);
  position: relative;
  height: inherit;
  line-height: calc(100vw / 1920 * 80);
  cursor: pointer;
}
.tab li.active {
  color: #2178c9;
}
.tab li.active::after {
  position: absolute;
  left: 0;
  bottom: -1px;
  content: "";
  height: 2px;
  background-color: #2178c9;
  animation: borderWidth 0.3s forwards;
}
@media all and (max-width: 960px) {
  .nav {
    padding: 0.5rem 1rem;
  }
  .tab {
    width: auto;
    flex: 1;
    white-space: nowrap;
  }
}
</style>
