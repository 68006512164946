<template>
  <div class="common-page-container">
    <page-component
      :page-data="pageData.pageContent"
      :page-path="pageData.pagePath"
    ></page-component>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
export default defineComponent({
  name: "CommonPage",
  async serverPrefetch() {
    try {
      await this.$store.dispatch("page/getPageContent", {
        path: this.$route.path,
      });
    } catch (e) {
      console.log("serverPrefetch:", e);
    }
  },
  computed: {
    ...mapGetters("page", ["pageData"]),
  },
  async mounted() {
    // let path = this.$route.path
    if (this.pageData == null || this.pageData.pagePath != this.$route.path) {
      await this.$store.dispatch("page/getPageContent", {
        path: this.$route.path,
      });
    }
  },
});
</script>

<style scoped></style>
