<template>
  <div class="about-page">
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/96tPcgiR.png"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/R9tcdoOP.png"
    ></page-banner>
    <div class="nav">
      <div class="crumbs" padhide>
        <ul class="wow fadeInLeft">
          <li class="icon">
            <svg-icon name="home"></svg-icon>
          </li>
          <li class="label-hover" @click="$router.replace({ path: `/` })">
            HOMEPAGE
          </li>
          <li class="icon">
            <svg-icon name="right"></svg-icon>
          </li>
          <li class="label-hover">ABOUT US</li>
        </ul>
      </div>
      <div class="tab">
        <ul>
          <li
            class="wow fadeInRight"
            data-wow-delay="0.1s"
            @click="scrollIntoView('company-introduction')"
          >
            Company Intro
          </li>
          <li
            @click="scrollIntoView('core-idea')"
            class="wow fadeInRight"
            data-wow-delay="0.2s"
          >
            Core Concept
          </li>
          <li
            @click="scrollIntoView('development-history')"
            class="wow fadeInRight"
            data-wow-delay="0.3s"
          >
            Development History
          </li>
          <li
            @click="scrollIntoView('management-team')"
            class="wow fadeInRight"
            data-wow-delay="0.4s"
          >
            Managing Team
          </li>
          <li
            @click="scrollIntoView('patent-certificate')"
            class="wow fadeInRight"
            data-wow-delay="0.5s"
          >
            Patent Certificates
          </li>
          <li
            @click="scrollIntoView('corporate-culture')"
            class="wow fadeInRight"
            data-wow-delay="0.6s"
          >
            Enterprise Culture
          </li>
          <li
            @click="scrollIntoView('contact-us')"
            class="wow fadeInRight"
            data-wow-delay="0.7s"
          >
            Contact US
          </li>
        </ul>
      </div>
    </div>
    <page-component :page-data="pageData.pageContent"></page-component>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner/PageBanner.vue";
import { mapGetters } from "vuex";

export default {
  components: { PageBanner },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("page", ["pageData"]),
  },
  async serverPrefetch() {
    await this.$store.dispatch("page/getPageContent", {
      path: "/about",
    });
  },
  async mounted() {
    if (this.pageData == null || this.pageData.pagePath != "/about") {
      await this.$store.dispatch("page/getPageContent", {
        path: "/about",
      });
    }
    if (this.$route.query.to) {
      let params = this.$route.query.to;
      let elementScrollById = document.getElementById(params);
      if (elementScrollById) {
        elementScrollById.scrollIntoView(true);
      }
    }
  },
  methods: {
    scrollIntoView(id) {
      if (this.$isServer) return;
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.nav {
  height: 0.8rem;
  border-bottom: 1px solid #f5f5f5;
  padding: 0.1rem 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.crumbs ul {
  display: flex;
  align-content: center;
  align-items: center;
}
.crumbs li {
  font-size: var(--text-size);
  margin: 0 calc(100vw / 1920 * 7);
  color: #888888;
  white-space: nowrap;
}
.tab {
  width: 100%;
  overflow: hidden;
  margin-left: 1rem;
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab::-webkit-scrollbar {
  display: none;
}
.tab ul {
  /*display: flex;*/
  /*align-content: center;*/
  /*align-items: center;*/
  /*justify-content: end;*/
  white-space: nowrap;
  text-align: right;
  font-size: 0;
}
.tab li {
  font-size: var(--text-size);
  color: #888888;
  /*margin-right: 0.48rem;*/
  position: relative;
  height: inherit;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 0.35rem;
  display: inline-block;
}
.tab li:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  width: 1px;
  background-color: #efefef;
}
.tab li:last-child::after {
  /*margin-right: 0rem;*/
  display: none;
}
.tab li.active {
  color: #2178c9;
}
.tab li.active::after {
  position: absolute;
  left: 0;
  bottom: -1px;
  content: "";
  height: 2px;
  background-color: #2178c9;
  animation: borderWidth 0.3s forwards;
}
@media all and (max-width: 960px) {
  .nav {
    padding: 0.5rem 1rem;
  }
  .tab {
    margin-left: 0;
  }
  .tab li:last-child {
    margin-right: 0.48rem;
  }
  /*.tab ul {*/
  /*  display: flex;*/
  /*  align-content: center;*/
  /*  align-items: center;*/
  /*  justify-content: start;*/
  /*}*/
}
</style>
