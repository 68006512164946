<template>
  <div class="typical-case-page">
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/rBtSFW1V.png"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/qUaSOmI7.png"
    ></page-banner>
    <div class="title" id="case-title">
      <h1 class="wow fadeInLeft">CASE STUDIES</h1>
      <div class="case_tabs">
        <SelectTab :projectTypeList="projectTypeList"></SelectTab>
      </div>
    </div>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageBanner from "@/components/PageBanner/PageBanner";
import SelectTab from "@/components/SelectTab/SelectTab.vue";

export default {
  name: "TypicalCase",
  components: {
    PageBanner,
    SelectTab,
  },
  async serverPrefetch() {
    try {
      await this.$store.dispatch("page/getPageContent", {
        path: this.$route.path,
      });
    } catch (e) {
      console.log("serverPrefetch:", e);
    }
  },
  data() {
    return {
      SelectName: "All",
      currentPage: 1,
      pageNum: 12,
      projectTypeList: [
        {
          id: 0,
          projectTypeName: "All",
          path: "/case/list",
          type: 0,
        },
        {
          id: 1,
          projectTypeName: "Resilient City",
          path: "/case/list-city",
          type: 40,
        },
        {
          id: 2,
          projectTypeName: "Catalyzing SDG 6",
          path: "/case/catalyzing-sdg",
          type: 41,
        },
        {
          id: 3,
          projectTypeName: "Emerging Scenarios",
          path: "/case/list-new",
          type: 42,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("page", ["pageData"]),
    routerKey() {
      return this.$route.meta.type;
    },
  },
  async mounted() {
    if (this.pageData == null || this.pageData.pagePath != this.$route.path) {
      await this.$store.dispatch("page/getPageContent", {
        path: this.$route.path,
      });
    }
  },
};
</script>

<style scoped>
.title {
  padding: 0 1rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title h1 {
  font-size: var(--h1title-size);
}
@media screen and (max-width: 960px) {
  .title {
    flex-direction: column;
    height: auto;
    padding: 35px 10px 30px 10px;
  }
  .title .case_tabs {
    margin-top: 2rem;
    width: 100%;
  }
}
</style>
