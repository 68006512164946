import { getPageTemplateList } from "@/api/public";
import Layout from "../layout/Layout.vue";
import Home from "../views/Home/index.vue";
import TypicalCase from "../views/Case/TypicalCase/TypicalCase.vue";
import CaseDetail from "../views/Case/CaseDetail/detail.vue";
import StrategyPage from "../views/Strategy/index.vue";
import NewsDetail from "../views/News/detail.vue";
import NewsPage from "../views/News/index.vue";
import About from "../views/About/index.vue";
import Solution from "../views/Solution/Solution.vue";
import Products from "../views/Products/index.vue";
import Common from "@/views/Common/index.vue";

const asyncComponents = {
  Home: Home,
  Layout: Layout,
  TypicalCase: TypicalCase,
  CaseDetail: CaseDetail,
  StrategyPage: StrategyPage,
  NewsDetail: NewsDetail,
  NewsPage: NewsPage,
  About: About,
  Common: Common,
};

const defaultLayout = {
  path: "/",
  name: "Layout",
  component: Layout,
  children: [
    {
      path: "/case/detail",
      name: "CaseDetail",
      component: CaseDetail,
    },
    {
      path: "/news/detail",
      name: "NewsDetail",
      component: NewsDetail,
    },
  ],
};
const defaultTypicalCase = {
  path: "/case",
  name: "TypicalCase",
  component: TypicalCase,
  children: [],
};
const defaultStrategyPage = {
  path: "/strategy",
  name: "StrategyPage",
  component: StrategyPage,
  children: [],
};
const defaultNewsPage = {
  path: "/news",
  name: "NewsPage",
  component: NewsPage,
  children: [],
};
const defaultSolution = {
  path: "/solution",
  name: "Solution",
  component: Solution,
  children: [],
};
const defaultProducts = {
  path: "/product",
  name: "Products",
  component: Products,
  children: [],
};

// const asyncTemplate = {
//   Layout: [], // 全局模版
//   TypicalCase: [], //典型案例
//   StrategyPage: [], //战略布局
//   NewsPage: [], //新闻中心
//   Solution: [], //解决方案
//   Products: [], //产品中心
// };

export const generatorDynamicRouter = async () => {
  // const pageList = await getPageList();
  // const pageTemplateList = await getPageTemplateList();
  // return listToRouter(pageList.data);
  try {
    const pageTemplateList = await getPageTemplateList();
    return templateToRouter(pageTemplateList.data);
  } catch (e) {
    console.log("generatorDynamicRouter:", e);
    return null;
  }
};

// const listToRouter = (list) => {
//   list.forEach((item) => {
//     if (item.component && item.routerName && item.url) {
//       let routerItem = {
//         path: item.url.replace("//", "/"),
//         name: item.routerName,
//         component: asyncComponents[item.component],
//         meta: {
//           title: item.title,
//           keyword: item.keyword,
//           description: item.description,
//         },
//       };
//       asyncTemplate[item.template]
//         ? asyncTemplate[item.template].push(routerItem)
//         : (asyncTemplate[item.template] = [routerItem]);
//     }
//   });
//   const temKeys = Object.keys(asyncTemplate);
//   for (let i = 0; i < temKeys.length; i++) {
//     let key = temKeys[i];
//     let routerList = asyncTemplate[key];
//     if (routerList.length) {
//       switch (key) {
//         case "Layout":
//           defaultLayout.children.push(...routerList);
//           break;
//         case "TypicalCase":
//           defaultTypicalCase.children.push(...routerList);
//           defaultTypicalCase["redirect"] = routerList[0].path;
//           defaultLayout.children.push(defaultTypicalCase);
//           break;
//         case "StrategyPage":
//           defaultStrategyPage.children.push(...routerList);
//           defaultStrategyPage["redirect"] = routerList[0].path;
//           defaultLayout.children.push(defaultStrategyPage);
//           break;
//         case "NewsPage":
//           defaultNewsPage.children.push(...routerList);
//           defaultNewsPage["redirect"] = routerList[0].path;
//           defaultLayout.children.push(defaultNewsPage);
//           break;
//         case "Solution":
//           defaultSolution.children.push(...routerList);
//           defaultSolution["redirect"] = routerList[0].path;
//           defaultLayout.children.push(defaultSolution);
//           break;
//         case "Products":
//           defaultProducts.children.push(...routerList);
//           defaultProducts["redirect"] = routerList[0].path;
//           defaultLayout.children.push(defaultProducts);
//           break;
//         default:
//           break;
//       }
//     }
//   }
//   defaultLayout["redirect"] = defaultLayout.children[0].path;
//   return defaultLayout;
// };

const templateToRouter = (templateList) => {
  for (const templateKey in templateList) {
    const listRouter = getListRouter(templateList[templateKey]);
    matchRouter(templateKey, listRouter);
  }
  defaultLayout["redirect"] = defaultLayout.children[0].path;
  return defaultLayout;
};

const matchRouter = (template, routerList) => {
  switch (template) {
    case "Layout":
      defaultLayout.children.push(...routerList);
      break;
    case "TypicalCase":
      defaultTypicalCase.children.push(...routerList);
      defaultTypicalCase["redirect"] = routerList[0].path;
      defaultLayout.children.push(defaultTypicalCase);
      break;
    case "StrategyPage":
      defaultStrategyPage.children.push(...routerList);
      defaultStrategyPage["redirect"] = routerList[0].path;
      defaultLayout.children.push(defaultStrategyPage);
      break;
    case "NewsPage":
      defaultNewsPage.children.push(...routerList);
      defaultNewsPage["redirect"] = routerList[0].path;
      defaultLayout.children.push(defaultNewsPage);
      break;
    case "Solution":
      defaultSolution.children.push(...routerList);
      defaultSolution["redirect"] = routerList[0].path;
      defaultLayout.children.push(defaultSolution);
      break;
    case "Products":
      defaultProducts.children.push(...routerList);
      defaultProducts["redirect"] = routerList[0].path;
      defaultLayout.children.push(defaultProducts);
      break;
    default:
      break;
  }
};

const getListRouter = (list) => {
  let routerList = list
    .filter((item) => item.component && item.routerName && item.url)
    .map((item) => {
      return {
        path: item.url.replace("//", "/"),
        name: item.routerName,
        component: asyncComponents[item.component],
        meta: {
          title: item.title,
          keyword: item.keyword,
          description: item.description,
        },
      };
    });
  return routerList;
};
