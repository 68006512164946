<template>
  <div class="news-center">
    <HomeItemTitle
      class="title-content"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></HomeItemTitle>

    <div class="news-content">
      <div class="left-side wow fadeInLeft">
        <swiper
          ref="mySwiper"
          :options="newSwiperOpting"
          @slideChange="handleSwiperChange"
        >
          <template v-for="(item, index) in swiperNews">
            <swiper-slide :key="item.id">
              <div class="left-side-item" :index="index">
                <a :href="`/news/detail?id=${item.id}`" :title="item.title">
                  <div class="pic-box" type="news">
                    <img :src="item.imageUrl" alt="" srcset="" />
                  </div>
                  <p class="title" type="news">
                    {{ item.title }}
                  </p>
                  <p class="briefly" type="news">
                    {{ item.remark }}
                  </p>
                  <div class="date-box" type="news">
                    <span>{{ formatReleaseTime(item.releaseTime) }}</span>
                    <svg-icon
                      name="right-arrow"
                      class-name="arrow-icon"
                    ></svg-icon>
                  </div>
                </a>
              </div>
            </swiper-slide>
          </template>
        </swiper>

        <div class="dot-box">
          <p class="dot-item">
            <i
              :class="{ active: newsRoundIndex == index }"
              v-for="(item, index) in swiperNews"
              :key="item.id"
              @click="handleDotClick(index)"
            ></i>
          </p>
          <div class="dot-page">
            <svg-icon
              :className="newsRoundIndex == 0 ? 'disabled' : ''"
              name="left"
              @click="handelChangePrev"
            ></svg-icon>
            <svg-icon
              :className="newsRoundIndex == swiperLength - 1 ? 'disabled' : ''"
              name="right"
              @click="handelChangeNext"
            ></svg-icon>
          </div>
        </div>
      </div>
      <div class="right-side wow fadeInRight">
        <div class="news-title-box">
          <span @click="selectNewsType($event, 0)">
            Corporate Information
          </span>
          <span @click="selectNewsType($event, 1)"> Industry information </span>
          <i class="news-title-line"></i>
        </div>
        <ul class="news-list-box" v-if="tabNewListSelect == 0">
          <template v-for="(item, index) in businessNews">
            <li class="news-list-item" :index="index">
              <a :href="`/news/detail?id=${item.id}`" :title="item.title">
                <span>{{ formatReleaseTime(item.releaseTime) }}</span>
                <span>{{ item.title }}</span>
                <svg-icon name="right-arrow" class-name="arrow-icon"></svg-icon>
              </a>
            </li>
          </template>
        </ul>
        <ul class="news-list-box" v-else>
          <template v-for="(item, index) in industryNews">
            <li class="news-list-item" :index="index">
              <a :href="`/news/detail?id=${item.id}`" :title="item.title">
                <span>{{ formatReleaseTime(item.releaseTime) }}</span>
                <span>{{ item.title }}</span>
                <svg-icon name="right-arrow" class-name="arrow-icon"></svg-icon>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeItemTitle from "@/components/HomeItemTitle/HomeItemTitle.vue";
import { formatTime } from "@/utils/util";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "NewsCenter",
  components: { HomeItemTitle, Swiper, SwiperSlide },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      newsRoundIndex: 0,
      newSwiperSide: null,
      tabNewListSelect: 0,
      newSwiperOpting: {
        spaceBetween: 30,
      },
    };
  },
  computed: {
    ...mapGetters(["swiperNews", "businessNews", "industryNews"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    swiperLength() {
      return this.swiperNews != null ? this.swiperNews.length : 0;
    },
  },
  async serverPrefetch() {
    await this.$store.dispatch("getHomeNewsLists");
  },
  async mounted() {
    if (
      this.swiperNews == null ||
      this.businessNews == null ||
      this.industryNews == null
    ) {
      await this.$store.dispatch("getHomeNewsLists");
    }
  },
  methods: {
    handleSwiperChange() {
      this.newsRoundIndex = this.swiper.realIndex;
    },
    handelChangePrev() {
      if (this.newsRoundIndex > 0) {
        this.handleDotClick(this.newsRoundIndex - 1);
      }
    },
    handelChangeNext() {
      if (this.newsRoundIndex < this.swiperLength - 1) {
        this.handleDotClick(this.newsRoundIndex + 1);
      }
    },
    handleDotClick(index) {
      if (this.swiper) {
        this.swiper.slideTo(index);
      }
    },
    selectNewsType(e, index) {
      let windowsWidth = document.documentElement.clientWidth;
      if (windowsWidth < 960) {
        var marginRight = (20 * windowsWidth) / 375;
        document.getElementsByClassName("news-title-line")[0].style.left = `${
          index * (e.target.offsetWidth + marginRight)
        }px`;
      } else {
        let lineWidth = windowsWidth * 0.0625;
        let lineRightWidth = windowsWidth * 0.022;
        document.getElementsByClassName("news-title-line")[0].style.left = `${
          index * (lineWidth + lineRightWidth)
        }px`;
      }
      if (this.instance) {
        this.instance.update();
      }
      this.tabNewListSelect = index;
    },
    formatReleaseTime(time) {
      return formatTime(time, "YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.title-content {
  padding: 1rem 1rem 0.7rem;
}

.left-side-item a,
.left-side-item a:hover,
.left-side-item a:link {
  color: inherit;
  font-style: normal;
  text-underline: none;
  text-decoration: none;
}
.news-center .news-content {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 1rem;
  display: flex;
}
.news-center .news-content .left-side {
  width: calc(100vw * 0.495);
}
.news-center .news-content .right-side {
  width: calc(100vw * 0.4);
  height: calc(100vw * 0.39065);
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  padding: 1.1rem 1rem;
}
.news-center .news-content .left-side .dot-box,
.news-center .news-content .left-side .dot-box .dot-item {
  display: flex;
}

.news-center .news-content .left-side .pic-box {
  width: 100%;
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 4.4rem;
  border-radius: 0.2rem;
  line-height: 4.4rem;
  display: flex;
  align-items: center;
}
.news-center .news-content .left-side .pic-box img {
  width: 100%;
  transform: scale(1);
  transition: all 0.15s ease-in;
  vertical-align: middle;
}
.news-center .news-content .left-side .pic-box:hover img {
  transform: scale(1.1);
}
.news-center .news-content .left-side .title {
  color: #222222;
  cursor: pointer;
  font-size: var(--subtitle-size);
  margin-top: 0.15rem;
  margin-bottom: 0.1rem;
}
.news-center .news-content .left-side .briefly {
  color: rgba(34, 34, 34, 0.65);
  text-align: justify;
  cursor: pointer;
  font-size: var(--text-size);
}
.news-center .news-content .left-side .date-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
  color: rgba(34, 34, 34, 0.4);
  font-size: var(--subtext-size);
  padding: 0.2rem 0;
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid rgba(34, 34, 34, 0.2);
}
.news-center .news-content .left-side .date-box:hover {
  border-bottom-color: #333333;
  color: #333333;
}
.news-center .news-content .left-side .date-box .arrow-icon {
  font-size: 0.3rem;
}

.news-center .news-content .left-side .dot-box,
.news-center .news-content .left-side .dot-box .dot-item {
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.news-center .news-content .left-side .dot-box .dot-item i {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background-color: #cccccc;
  transform: scale(1);
  transition: all 0.15s ease-in;
}
.news-center .news-content .left-side .dot-box .dot-item i:hover,
.news-center .news-content .left-side .dot-box .dot-item i.active {
  transform: scale(1.4);
  background-color: #000000;
}
.news-center .news-content .left-side .dot-box .dot-page {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 0.25rem;
}
.news-center .news-content .left-side .dot-box .dot-page svg {
  margin: 0 0 0 0.4rem;
  cursor: pointer;
}
.news-center .news-content .left-side .dot-box .dot-page svg.disabled {
  color: #c6c6c6;
  cursor: no-drop;
}
.news-center .news-content .right-side {
  background-image: url("http://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/WzlPdYOy.png");
  background-color: #124898;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
}
.news-center .news-content .right-side .news-title-box {
  color: #ffffff;
  position: relative;
  padding-bottom: 0.26rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.3rem;
}
.news-center .news-content .right-side .news-title-box i {
  height: 3px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  transition: all 0.15s ease-in;
  width: calc(100vw * 0.0625);
  bottom: -2px;
}
.news-center .news-content .right-side .news-title-box span {
  font-weight: bold;
  cursor: pointer;
  font-size: var(--subtitle-size);
}
.news-list-box .news-list-item {
  padding: 0.3rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.news-list-box .news-list-item a {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.15s ease-in;
  font-size: 0.16rem;
  color: #ffffff;
}
.news-list-box .news-list-item:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  font-weight: bold;
  color: #ffffff;
}

.news-list-box .news-list-item span {
  /*color: #ffffff;*/
  white-space: nowrap;
  font-size: 0.16rem;
}

.news-list-box .news-list-item span:nth-child(1) {
  font-family: "DINPro";
  margin-right: 0.39rem;
  white-space: nowrap;
}
.news-list-box .news-list-item span:nth-child(2) {
  flex: 1;
  overflow: hidden;
  padding-right: 30px;
  text-overflow: ellipsis;
}
.news-list-box .news-list-item svg {
  position: absolute;
  right: 30px;
  transition: all 0.15s ease-in;
  opacity: 0;
  font-size: 0.3rem;
}

.news-list-box .news-list-item:hover svg {
  right: 0;
  opacity: 1;
}

.dot-box,
.dot-box .dot-item {
  justify-content: center;
  align-content: center;
  align-items: center;
}
.dot-box img {
  margin: 0 0.1rem;
  width: 0.12rem;
  height: auto;
}
.dot-box .dot-item i {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background-color: #c6c6c6;
  cursor: pointer;
  margin: 0 0.1rem;
  transform: scale(1);
  transition: all 0.15s ease-in;
}
.dot-box .dot-item i.active,
.dot-box .dot-item i:hover {
  transform: scale(1.4);
  background-color: #2178c9;
}
.news-center .news-content .left-side .title:hover {
  color: #2178c9;
}

.news-center .news-content .left-side .briefly:hover {
  color: #333333;
}

.news-list-item a {
  color: inherit;
  text-decoration: none;
}

.dot-box,
.dot-box .dot-item {
  display: flex;
}
.news-center .news-content .right-side .news-title-box span {
  margin-right: 0.32rem;
}
.news-center .news-content .right-side .news-list-box {
  height: 100%;
  overflow: hidden;
}
.news-list-box .news-list-item span {
  font-size: var(--text-size);
}
@media screen and (max-width: 960px) {
  .news-center .news-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .news-center .news-content {
    flex-direction: column;
  }
  .news-center .news-content .left-side {
    width: 100%;
  }
  .news-center .news-content .right-side {
    width: 100%;
    height: auto;
    border-radius: 0.2rem;
    margin-top: 2rem;
  }
  .news-center .news-content .left-side .pic-box {
    height: calc(100vw * 0.5);
  }
  .news-center .news-content .left-side .date-box {
    margin-bottom: 0;
    border-bottom: none;
  }
  .news-center .news-content .left-side .dot-box {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }
  .news-center .news-content .left-side .dot-box .dot-item i {
    width: 0.3rem;
    height: 0.3rem;
  }
  .news-center .news-content .left-side .dot-box .dot-page {
    font-size: 0.6rem;
  }
  .news-center .news-content .left-side .dot-box,
  .news-center .news-content .left-side .dot-box .dot-item {
    /*display: none;*/
  }

  .news-center .news-content .left-side .date-box::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(34, 34, 34, 0.2);
    transform: scaleY(0.5);
  }
  .news-center .news-content .right-side .news-title-box {
    padding-bottom: 0.3rem;
    font-size: var(--subtext-size);
  }
  .news-center .news-content .right-side .news-title-box::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    transform: scaleY(0.5);
  }
  .news-center .news-content .right-side .news-title-box i {
    width: calc(var(--subtitle-size) * 4);
    bottom: -1px;
    transform: scaleY(0.5);
  }
  .news-list-box .news-list-item {
    padding: 0.5rem 0;
  }
  .news-list-box .news-list-item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    transform: scaleY(0.5);
  }

  .news-list-box .news-list-item span:nth-child(1) {
    margin-right: 0.8rem;
  }

  .news-center .news-content .right-side .news-title-box span {
    margin-right: 1rem;
  }
  .news-center .news-content .right-side .news-list-box {
    margin-top: 0.5rem;
  }
  .news-center .news-content .left-side .date-box .arrow-icon {
    font-size: 0.5rem;
  }
}
</style>
