<template>
  <div class="solution-page">
    <page-banner
      image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/CYvN1MJK.png"
      small-image-url="https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/vvR5LaH5.png"
    ></page-banner>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner/PageBanner";
export default {
  name: "SolutionPage",
  components: { PageBanner },
};
</script>
<style>
.solution-page {
}
</style>
<style scoped>
.solution-page {
  background-color: #f8f8f8;
}
</style>
