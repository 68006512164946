import Router from "vue-router";
import routeConfig from "./routeConfig";

export function createRouter() {
  return new Router({
    mode: "history",
    routes: [...routeConfig],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {};
      }
    },
  });
}
