<template>
  <div class="my-swiper-pagination" v-if="dotNum > 1">
    <svg-icon
      :className="value == 0 ? 'disabled' : ''"
      name="left"
      @click="handelChangePrev"
    ></svg-icon>
    <div class="dot-list">
      <template v-for="(item, index) in dotNum">
        <i
          :index="index"
          :item="item"
          :class="{ active: value == index }"
          :key="index"
          @click="handelClickDian(index)"
        ></i>
      </template>
    </div>
    <svg-icon
      :className="value == dotNum - 1 ? 'disabled' : ''"
      name="right"
      @click="handelChangeNext"
    ></svg-icon>
  </div>
</template>

<script>
export default {
  name: "SwiperPagination",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    pageNum: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dotNum() {
      return this.pageNum;
    },
  },
  data() {
    return {
      caseRoundIndex: 0,
    };
  },
  mounted() {
    // this.dotNum = Math.ceil(this.quantity / this.pageSize);
    // console.log(this.dotNum);
  },
  methods: {
    handelClickDian(index) {
      // console.log(index);
      this.pageChange(index);
    },
    pageChange(index) {
      this.caseRoundIndex = index;
      this.$emit("change", index);
      this.$emit("input", index);
    },
    handelChangePrev() {
      if (this.value > 0) {
        this.pageChange(this.value - 1);
      }
    },
    handelChangeNext() {
      if (this.value < this.dotNum - 1) {
        this.pageChange(this.value + 1);
      }
    },
  },
};
</script>

<style scoped>
.my-swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.25rem;
  overflow: hidden;
  height: 0.3rem;
  width: 100%;
}
.my-swiper-pagination .svg-icon {
  color: #007aff;
  cursor: pointer;
  margin: 0 10px;
}
.my-swiper-pagination .svg-icon.disabled {
  color: #c6c6c6;
  cursor: no-drop;
}

.my-swiper-pagination .dot-list {
  font-size: 0;
}
.my-swiper-pagination .dot-list i {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background-color: #c6c6c6;
  cursor: pointer;
  margin: 0 0.1rem;
  transform: scale(1);
  transition: all 0.15s ease-in;
  display: inline-block;
}
.my-swiper-pagination .dot-list i.active,
.my-swiper-pagination .dot-list i:hover {
  transform: scale(1.4);
  background-color: #2178c9;
}
@media screen and (max-width: 1200px) {
  .my-swiper-pagination {
    height: 0.5rem;
    font-size: 0.5rem;
  }
  .my-swiper-pagination .dot-list i {
    width: 0.2rem;
    height: 0.2rem;
  }
}
@media screen and (max-width: 960px) {
  .my-swiper-pagination {
    height: 0.8rem;
    font-size: 0.6rem;
  }
  .my-swiper-pagination .dot-list i {
    width: 0.25rem;
    height: 0.25rem;
  }
}

@media screen and (max-width: 750px) {
  .my-swiper-pagination {
    height: 0.8rem;
    font-size: 0.6rem;
  }
  .my-swiper-pagination .dot-list i {
    width: 0.3rem;
    height: 0.3rem;
  }
}
</style>
