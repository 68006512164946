<template>
  <li class="accordion-menu-li">
    <template v-if="itemData.children && itemData.children.length > 0">
      <div class="accordion-menu-item children-label">
        <div class="accordion-menu-info" @click="handelOpenSubMenu">
          <p>
            <span>{{ itemData.menuName }}</span>
            <svg-icon
              :name="subOpen ? 'reduce' : 'plus'"
              class-name="plus-icon"
            ></svg-icon>
          </p>
        </div>
        <ul class="accordion-sub-menu" :class="{ open: subOpen }">
          <template v-for="childrenData in itemData.children">
            <a-menu-item
              :item-data="childrenData"
              :key="childrenData.id"
            ></a-menu-item>
          </template>
        </ul>
      </div>
    </template>
    <template v-else>
      <div class="accordion-menu-item children-info">
        <div class="accordion-menu-info">
          <a :href="itemData.menuRoute" :title="itemData.menuName">
            <p>
              {{ itemData.menuName }}
            </p>
          </a>
        </div>
      </div>
    </template>
  </li>
</template>

<script>
export default {
  name: "AMenuItem",
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subOpen: false,
    };
  },
  methods: {
    handelOpenSubMenu() {
      this.subOpen = this.subOpen ? false : true;
    },
  },
};
</script>

<style>
@media all and (max-width: 960px) {
}
</style>
