<template>
  <div class="case-component">
    <item-title
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="case-image-list" id="caseImageSwiper">
      <swiper
        ref="mySwiper"
        :options="swiperOpting"
        @slideChange="handleSwiperChange"
      >
        <template v-for="(item, index) in setting.caseListData">
          <swiper-slide :key="item.id">
            <div
              :data-wow-delay="`${0.1 * (index + 1)}s`"
              class="swiper-slide wow fadeInRight"
            >
              <div class="cases-item">
                <a href=""></a>
                <div class="image">
                  <img :src="item.imgUrl" />
                </div>
                <p>{{ item.descText }}</p>
              </div>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <div class="pagination-com">
      <swiper-pagination
        :quantity="setting.caseListData.length"
        :page-size="setting.swiperPageSize"
        v-model="swiperPage"
        @change="handelChangePage"
      ></swiper-pagination>
    </div>
  </div>
</template>

<script>
import SwiperPagination from "@/components/SwiperPagination/SwiperPagination";
import ItemTitle from "@/components/ItemTitle/ItemTitle";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "CaseList",
  components: { SwiperPagination, ItemTitle, Swiper, SwiperSlide },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    swiperOpting() {
      return {
        slidesPerView: 2,
        spaceBetween: 10,
        slidesPerGroup: 1,
        breakpoints: {
          961: {
            slidesPerView: this.setting.swiperPageSize || 3,
            spaceBetween: 50,
            slidesPerGroup: this.swiperPageGroup,
          },
        },
      };
    },
  },
  data() {
    return {
      caseImageSwiper: null,
      swiperPage: 0,
      pageSize: this.setting.swiperPageSize || 3,
    };
  },
  mounted() {
    // this.initSwiper();
  },
  methods: {
    // initSwiper(swiper) {
    // console.log(swiper);
    // let that = this;
    // eslint-disable-next-line no-undef
    // this.caseImageSwiper = new Swiper("#caseImageSwiper .swiper", {
    //   slidesPerView: 3,
    //   spaceBetween: 50,
    //   slidesPerGroup: that.swiperPageGroup,
    //   on: {
    //     slideChange: function () {
    //       that.swiperPage = Math.floor(
    //         this.activeIndex / that.swiperPageGroup
    //       );
    //     },
    //   },
    // });
    // },
    handleSwiperChange() {
      // console.log(this.swiper);
      this.swiperPage = Math.floor(
        this.swiper.activeIndex / this.swiperPageGroup
      );
    },
    handelChangePage(index) {
      if (this.caseImageSwiper) {
        this.caseImageSwiper.slideTo(index * this.swiperPageGroup);
      }
    },
  },
};
</script>

<style scoped>
.case-component {
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/mIgSptMI.png");
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 1rem;
  box-sizing: border-box;
}
.case-image-list {
  margin-top: 0.6rem;
}
.cases-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.cases-item .image {
  overflow: hidden;
  font-size: 0;
  border-radius: 0.2rem;
}

.cases-item img {
  width: 100%;
  transition: transform 0.2s linear;
}
.cases-item p {
  margin-top: 0.3rem;
  font-size: var(--text-size);
  text-align: center;
  font-weight: 400;
  color: #222222;
  transition: color 0.2s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cases-item:hover p {
  color: #2178c9;
}
.cases-item:hover .image img {
  transform: scale(1.1);
}
.pagination-com {
  margin-top: 0.6rem;
  width: 100%;
}
</style>
