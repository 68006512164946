import { getPageContentDataApi } from "@/api/public";

export default {
  namespaced: true,
  state: () => ({
    pageContent: null,
    pagePath: null,
    pageName: null,

    title: null,
    keywords: null,
    description: null,
    options: null,
  }),
  mutations: {
    setPageContent(state, data) {
      state.pageContent = data.content;
      state.pagePath = data.url;
      state.pageName = data.pageName;
    },
    setPageTDK(state, data) {
      state.title = data.title;
      state.keywords = data.keywords;
      state.description = data.description;
      state.options = data.options;
    },
  },
  actions: {
    getPageContent({ commit, rootState }, path) {
      let timer = null;
      if (process.client) {
        timer = setTimeout(() => {
          rootState.pageLoading = true;
        }, 1000);
      }
      return getPageContentDataApi(path)
        .then((res) => {
          let { data } = res;
          let contentData = [];
          let url = path;
          let name = null;
          if (data) {
            try {
              contentData = JSON.parse(data.content);
              url = data.url;
              name = data.name;
            } catch (e) {
              console.log("json parse error:", e);
            }
          }
          commit("setPageContent", {
            content: contentData,
            url: url,
            pageName: name,
          });
          commit("setPageTDK", {
            title: data.title,
            keywords: data.keywords,
            description: data.description,
            options: data.options,
          });
        })
        .catch((e) => {
          console.log("getPageContentDataApi:", e);
        })
        .finally(() => {
          if (timer) clearTimeout(timer);
          rootState.pageLoading = false;
        });
    },
    resetPageData({ commit }) {
      commit("setPageContent", {
        pageContent: null,
        pagePath: null,
        pageName: null,
      });
      commit("setPageTDK", {
        title: null,
        keywords: null,
        description: null,
        options: null,
      });
    },
  },
  getters: {
    pageData: (state) => {
      return {
        pageContent: state.pageContent,
        pagePath: state.pagePath,
        pageName: state.pageName,
      };
    },
    pageTdk: (state) => {
      return {
        title: state.title,
        keywords: state.keywords,
        description: state.description,
        options: state.options,
      };
    },
  },
};
