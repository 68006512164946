var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-page"},[_c('page-banner',{attrs:{"image-url":"https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/xMN6PT9d.jpg","small-image-url":"https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/file/PfI5yPVn.jpg"}}),_vm._v(" "),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"crumbs",attrs:{"padhide":""}},[_c('ul',{staticClass:"wow fadeInLeft"},[_c('li',{staticClass:"icon"},[_c('svg-icon',{attrs:{"name":"home"}})],1),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('li',{staticClass:"icon"},[_c('svg-icon',{attrs:{"name":"right"}})],1),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('li',{staticClass:"icon"},[_c('svg-icon',{attrs:{"name":"right"}})],1),_vm._v(" "),_c('li',[_vm._v(_vm._s(_vm.title))])])]),_vm._v(" "),_c('div',{staticClass:"tab"},[_c('ul',[_c('li',{class:[
            _vm.$route.path == '/news/enterprise' ? 'active' : '',
            'wow',
            'fadeInRight',
          ],attrs:{"data-wow-delay":"0.1s"}},[_c('router-link',{attrs:{"to":"/news/enterprise","title":"Corporate Information"}},[_vm._v("Company Information")])],1),_vm._v(" "),_c('li',{class:[
            _vm.$route.path == '/news/industry' ? 'active' : '',
            'wow',
            'fadeInRight',
          ],attrs:{"data-wow-delay":"0.3s"}},[_c('router-link',{attrs:{"to":"/news/industry"}},[_vm._v(" Industry information ")])],1)])])]),_vm._v(" "),_c('div',{staticClass:"page-content"},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"label-hover"},[_c('a',{attrs:{"href":"/","title":"HOMEPAGE"}},[_vm._v(" HOMEPAGE ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"label-hover"},[_c('a',{attrs:{"href":"/news/enterprise","title":"NEWS CENTER"}},[_vm._v("NEWS CENTER")])])
}]

export { render, staticRenderFns }