import { request } from "../utils/request";

export function getConfigApi(params = {}) {
  return request({
    method: "get",
    url: "/web/config/get_map",
    params,
  });
}

export function submitMessage(data = {}) {
  return request({
    method: "post",
    url: "/web/message/send",
    data,
  });
}

export function getBanner(params = {}) {
  return request({
    method: "get",
    url: "/web/banner/get",
    params,
  });
}

export function getProduct(params = {}) {
  return request({
    method: "get",
    url: "/web/product/list",
    params,
  });
}

export function getMenu(params = {}) {
  return request({
    method: "get",
    url: "/web/menu/get",
    params,
  });
}

export function getScene(params = {}) {
  return request({
    method: "get",
    url: "/web/scene/list",
    params,
  });
}

export function getProjectType(params = {}) {
  return request({
    method: "get",
    url: "/web/project/type",
    params,
  });
}

export function getProjectList(params = {}) {
  return request({
    method: "get",
    url: "/web/project/get",
    params,
  });
}

export function getProjectDetail(params = {}) {
  return request({
    method: "get",
    url: "/web/project/detail",
    params,
  });
}
export function getProjectTypeList(params = {}) {
  return request({
    method: "get",
    url: "/web/project/list",
    params,
  });
}

export function getNewsList(params = {}) {
  return request({
    method: "get",
    url: "/web/new/get",
    params,
  });
}

export function getNewsDetail(params = {}) {
  return request({
    method: "get",
    url: "/web/new/detail",
    params,
  });
}

export function getNewsGroupList(params = {}) {
  return request({
    method: "get",
    url: "/web/new/group",
    params,
  });
}

export function getRecruitList(params = {}) {
  return request({
    method: "get",
    url: "/web/recruit/list",
    params,
  });
}

export function uploadFile(data = {}) {
  return request({
    method: "post",
    url: "/web/file/upload",
    data,
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  });
}

export function resume(data = {}) {
  return request({
    method: "post",
    url: "/web/resume/send",
    data,
  });
}

export function sendResumeApi(data = {}) {
  return request({
    method: "post",
    url: "/web/resume/send",
    data,
  });
}

export function getHonorList(params = {}) {
  return request({
    method: "get",
    url: "/web/honor/list",
    params,
  });
}

export function getSolutionList(params = {}) {
  return request({
    method: "get",
    url: "/web/solution/list",
    params,
  });
}

export function getPageContentData(params = {}) {
  return request({
    method: "get",
    url: "/web/page/settings",
    params,
  });
}

export function getPageContentDataApi(params = {}) {
  return request({
    method: "get",
    url: "/web/page/settings",
    params,
  });
}

export function sendRecord(params = {}) {
  return request({
    method: "get",
    url: "/web/record/send",
    params,
  });
}

export function getPageList() {
  return request({
    method: "get",
    url: "/web/page/list",
  });
}

export function getPageTemplateList() {
  return request({
    method: "get",
    url: "/web/page/template_list",
  });
}
