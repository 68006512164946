const files = require.context("./components", true, /\.vue$/);

const modules = files.keys().reduce((modules, path) => {
  // './app.js' => 'app'
  const reqCom = files(path).default;
  const name = reqCom.name || path.replace(/\.\/(.*)\.vue/, "$1");
  modules[name] = reqCom;
  return modules;
}, {});

export default {
  modules,
};
