<template>
  <div class="specification">
    <item-title
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="specification-table">
      <table class="wow fadeInUp">
        <thead>
          <tr>
            <th>Model</th>
            <th>Capacity(m³/d)</th>
            <th>Installation Type</th>
            <th>Size（m）</th>
            <th>Operating Power（W）</th>
            <th>Power Supply Mode</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in setting.specificationData">
            <tr :key="index">
              <td>{{ item.productName }}</td>
              <td>{{ item.scale }}</td>
              <td>{{ item.installType }}</td>
              <td>{{ item.productSize }}</td>
              <td>{{ item.runPower }}</td>
              <td>{{ item.powerSupplyMethod }}</td>
            </tr>
          </template>
          <tr class="table-footer">
            <td colspan="6">
              {{ setting.tableDesc }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";

export default {
  name: "SpecificationTable",
  components: { ItemTitle },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.specification {
  padding: 1rem;
  overflow: hidden;
}
.specification-table table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: var(--text-size);
  margin-top: 0.8rem;
}
.specification-table table thead tr {
  background-color: #2178c9;
}
.specification-table table tr {
  height: 0.6rem;
}
.specification-table table thead tr th {
  font-size: var(--text-size);
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
}
.specification-table table tbody tr:nth-child(2n) {
  background-color: #dde6ef;
}
.table-footer td {
  text-align: right;
  padding-right: 0.7rem;
}
@media all and (max-width: 960px) {
  .specification-table {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
  .specification-table table {
    min-width: 30rem;
  }
  .specification-table table td,
  .specification-table table th {
    /*height: 1rem;*/
    white-space: nowrap;
    padding: 0.5rem;
  }
}
</style>
