<template>
  <div class="image-display-component">
    <div class="title-box" v-if="setting.title">
      <h1 class="name-zh wow fadeInLeft">
        {{ setting.title }}
      </h1>
      <span class="name-en wow fadeInRight" v-if="setting.subtitle">
        {{ setting.subtitle }}
      </span>
    </div>
    <ul class="image-box">
      <template v-for="(item, index) in setting.listData">
        <li
          class="wow fadeInUp"
          :data-wow-delay="`${0.1 * (index + 1)}s`"
          :style="`--colNum:${setting.colNum}`"
          :key="index"
        >
          <div
            class="pic-box"
            :style="{ backgroundImage: `url(${item.imageUrl})` }"
          ></div>
          <p class="text-box">
            <span>{{ item.titleCn }}</span>
            <span>{{ item.titleEn }}</span>
          </p>
          <div class="layer-box"></div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ImageDisplay",
  props: {
    setting: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
.image-box {
  --colNum: 5;
}
.image-box li .text-box span:nth-child(2) {
  font-family: "DINPro";
}
.image-display-component .title-box {
  display: flex;
}
.image-display-component .title-box {
  padding: 1rem 1rem 0.8rem 1rem;
  justify-content: space-between;
  align-items: center;
}

.image-display-component .title-box .name-en::after {
  content: "";
  width: 1rem;
  height: 0.01rem;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -1.2rem;
}

.image-display-component {
  background-color: #f8f8f8;
}
.image-display-component .title-box .name-zh {
  font-size: var(--h1title-size);
  color: #222222;
  font-weight: bold;
}
.image-display-component .title-box .name-en {
  font-size: var(--subtitle-size);
  color: #888888;
  font-family: "en-regular.otf";
  position: relative;
  display: flex;
  align-items: center;
}

.image-display-component .title-box .name-en::after {
  content: "";
  width: 1rem;
  height: 0.01rem;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -1.2rem;
}
.image-box {
  display: flex;
  flex-wrap: wrap;
}
.image-box li {
  width: calc(100% / var(--colNum));
  height: calc(100vw * 0.2);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.image-box li .pic-box,
.image-box li .layer-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  z-index: 1;
  transition: all 0.15s ease-in;
  transform: scale(1);
}
.image-box li:hover .pic-box {
  transform: scale(1.1);
}
.image-box li .layer-box {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.15s ease-in;
  /*box-shadow: inset 0px -1px 0px 0px rgb(0, 0, 0);*/
}
.image-box li .layer-box:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 0;
  width: 100%;
  box-shadow: 1px 1px 60px 60px #000000;
}
.image-box li:hover .layer-box {
  background-color: rgba(0, 0, 0, 0);
}
.image-box li .text-box {
  font-size: var(--text-size);
  z-index: 8;
  color: #ffffff;
  position: absolute;
  /*left: 15.62%;*/
  bottom: 7.5%;
  width: 100%;
  padding-left: 15.62%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-box li .text-box span:nth-child(2) {
  font-size: var(--subtext-size);
  margin-top: 0.08rem;
  opacity: 0.3;
}
.image-box li .text-box span {
  display: block;
}

@media screen and (max-width: 960px) {
  .image-display-component .title-box {
    flex-direction: column;
  }
  .image-display-component .title-box .name-en {
    margin-top: 10px;
  }
  .image-display-component .title-box .name-en::after {
    display: none;
  }
  .image-display-component {
    margin-top: -1px;
  }
  .image-box li {
    width: calc(100% / 2);
    height: calc(100vw / 2);
  }
  .image-box li .text-box span:nth-child(2) {
    margin-top: 0.2rem;
    opacity: 0.5;
  }
}
</style>
