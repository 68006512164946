<template>
  <div class="honorary-qualification">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <div class="honor-list">
      <div class="honor-swiper-display">
        <swiper
          ref="honorSwiper"
          :options="swiperOptions"
          @slideChange="handleSwiperChange"
        >
          <template v-for="(item, index) in honorList">
            <swiper-slide :key="item.id" :sindex="index">
              <div class="honor-list-item">
                <a :href="item.imageUrl">
                  <scale-image
                    :img-url="item.imageUrl"
                    :img-alt="item.honorName"
                    scale="73%"
                  ></scale-image>
                  <p>{{ item.honorName }}</p>
                </a>
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="change-round">
          <div class="change-button-left nav-btn">
            <svg-icon name="left"></svg-icon>
          </div>
          <div class="nav-dian">
            <template v-for="(item, index) in pageNum">
              <i
                :class="{ active: currenIndex == index }"
                :key="item"
                :index="index"
                @click.stop="handleDianClick(index)"
              ></i>
            </template>
          </div>
          <div class="change-button-right nav-btn">
            <svg-icon name="right"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import ItemTitle from "@/components/ItemTitle/ItemTitle";
import ScaleImage from "@/components/ScaleImage/ScaleImage";
import { mapGetters } from "vuex";
export default {
  name: "HonorSwiper",
  components: {
    ScaleImage,
    Swiper,
    SwiperSlide,
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 10,
        slidesPerGroup: 1,
        breakpoints: {
          1201: {
            //当屏幕宽度大于等于320
            slidesPerView: 5,
            spaceBetween: 20,
          },
          961: {
            //当屏幕宽度大于等于768
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },
        navigation: {
          prevEl: ".change-button-left",
          nextEl: ".change-button-right",
        },
        on: {
          init: () => {
            this.setPageNum();
          },
          breakpoint: () => {
            this.setPageNum();
          },
        },
      },
      currenIndex: 0,
      pageNum: 0,
      swiperPageGroup: 1,
    };
  },
  computed: {
    ...mapGetters(["honorList"]),
    honorSwiper() {
      return this.$refs.honorSwiper.$swiper;
    },
    pageSize() {
      if (this.$store.getters.windowsWidth > 1200) {
        return 5;
      } else if (
        this.$store.getters.windowsWidth > 960 &&
        this.$store.getters.windowsWidth <= 1200
      ) {
        return 3;
      } else {
        return 2;
      }
    },
  },
  async serverPrefetch() {
    await this.$store.dispatch("getHonorList");
  },
  mounted() {
    if (this.honorList && this.honorList.length <= 0) {
      this.$store.dispatch("getHonorList");
    }
  },
  methods: {
    setPageNum() {
      if (this.honorList.length) {
        this.pageNum = Math.ceil(
          (this.honorList.length - this.pageSize) / this.swiperPageGroup + 1
        );
      } else {
        this.pageNum = 2;
      }
    },
    handleDianClick(index) {
      this.honorSwiper.slideTo(index * 5);
    },
    handleSwiperChange() {
      this.currenIndex = this.honorSwiper.snapIndex;
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.honorary-qualification {
  background-color: #f8f8f8;
  padding-bottom: 0.7rem;
}
.honor-list {
  padding: 0 1rem;
  overflow: hidden;
}
.honor-swiper-display {
  position: relative;
}
.honor-list-item a {
  color: inherit;
}
.honor-list-item a > div {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
  transition: all 0.3s ease-in;
}
.honor-list-item a > div:hover {
  border-color: #2178c9;
}
.honor-list-item a > div img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
.honor-list-item a > p {
  font-size: var(--text-size);
  color: #222222;
  display: block;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.change-round {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-top: 0.7rem;
}
.change-round .nav-btn {
  width: 0.46rem;
  height: 0.46rem;
  border-radius: 0.23rem;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 0.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}
.change-round .nav-btn:not(.swiper-button-disabled):hover {
  background-color: #007aff;
  color: #ffffff;
}
.change-round .nav-btn.swiper-button-disabled {
  color: #cccccc;
  cursor: no-drop;
}
.change-round .nav-dian {
  font-size: 0.3rem;
}
.change-round .nav-dian i {
  width: 0.1rem;
  height: 0.1rem;
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  border-radius: 0.05rem;
  margin: 0 0.1rem;
  transform: scale(1);
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.change-round .nav-dian i.active {
  transform: scale(1.4);
  background-color: #2178c9;
}
@media all and (max-width: 960px) {
  .honorary-qualification {
    padding-bottom: 1.5rem;
  }
  .change-round {
    margin: 0;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%;
  }
  .change-round .nav-dian {
    display: none;
  }
  .change-round .change-button-right {
    transform: translateX(50%);
  }
  .change-round .change-button-left {
    transform: translateX(-50%);
  }
  .change-round .nav-btn {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    font-size: 0.5rem;
  }
}
</style>
