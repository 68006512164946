var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-advantage"},[_c('img',{staticClass:"top-image",attrs:{"src":"https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/rAptpZt4.png"}}),_vm._v(" "),_c('div',{staticClass:"advantage-content"},[_c('item-title',{attrs:{"title":_vm.setting.title,"sub-title":_vm.setting.subTitle,"title-color":"#ffffff","sub-title-color":"#ffffff"}}),_vm._v(" "),_c('div',{staticClass:"advantage-list",style:({
        '--item-size': _vm.setting.DescribeCardData.length,
        '--pc-row-size': _vm.setting.DescribeCardData.length,
        '--mobile-row-size': Math.floor(_vm.setting.DescribeCardData.length / 2),
      })},[_vm._l((_vm.setting.DescribeCardData),function(item,index){return [_c('div',{key:index,staticClass:"card-item wow fadeInRight",attrs:{"data-wow-delay":`${0.1 * (index + 1)}s`}},[_c('div',{staticClass:"icon",class:_vm.setting.blockTitle ? 'block-title' : ''},[_c('img',{attrs:{"src":item.imgUrl}}),_vm._v(" "),(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._e()]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(item.desc)+"\n          ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formatIndex(index + 1, 2)))])])]})],2)],1),_vm._v(" "),_c('img',{staticClass:"bottom-image",style:({
      backgroundColor: _vm.setting.bottomBackColor
        ? _vm.setting.bottomBackColor
        : '#f9f9f9',
    }),attrs:{"src":"https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/c6GeequC.png"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }