<template>
  <div class="accordion-menu" v-if="menuData && menuData.length > 0">
    <ul class="menu-box wow fadeInUp">
      <template v-for="menuItem in menuData">
        <a-menu-item :item-data="menuItem" :key="menuItem.id"></a-menu-item>
      </template>
    </ul>
  </div>
</template>

<script>
import AMenuItem from "@/components/AccordionMenu/AMenuItem";
export default {
  name: "AccordionMenu",
  components: {
    AMenuItem,
  },
  props: {
    menuData: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {},
};
</script>

<style>
.accordion-menu .menu-box {
  display: flex;
  align-content: center;
  align-items: center;
}
.accordion-menu-li {
  position: relative;
  cursor: pointer;
  margin-right: 0.2rem;
  color: #ffffff;
  font-size: var(--text-size);
}
.accordion-menu-info {
  padding: 50% 0;
}
.accordion-menu-info a,
.accordion-menu-info a:hover,
.accordion-menu-info a:link {
  color: inherit;
  text-underline: none;
  text-decoration: none;
  font-style: normal;
}
.accordion-menu-info p {
  white-space: nowrap;
  text-align: center;
}
.accordion-menu-info:hover p {
  color: var(--primary-color);
}
.accordion-menu-li:hover > .accordion-menu-item > .accordion-sub-menu {
  visibility: visible;
  opacity: 1;
  bottom: 80%;
}
.accordion-sub-menu {
  visibility: hidden;
  opacity: 0;
  display: block;
  position: absolute;
  bottom: 100%;
  padding: 0.1rem 0.15rem;
  border-radius: 0.06rem;
  background-color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s ease-out 0s;
}
.accordion-sub-menu .accordion-menu-li {
  color: #222222;
  margin-right: 0rem;
}
.accordion-sub-menu .accordion-menu-info {
  padding: 0.05rem 0;
}
.plus-icon {
  display: none;
}
@media all and (max-width: 960px) {
  .accordion-menu .menu-box {
    flex-direction: column;
  }
  .accordion-menu .accordion-menu-li {
    width: 100%;
    margin-right: 0;
  }
  .accordion-menu-info,
  .accordion-sub-menu .accordion-menu-info {
    padding: 0.5rem 0;
  }
  .children-label .plus-icon {
    display: block;
  }
  .accordion-menu-item .accordion-menu-info p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .accordion-sub-menu .accordion-menu-li {
    color: #ffffff;
  }
  .accordion-sub-menu {
    height: 0;
    overflow: hidden;
    visibility: visible;
    opacity: 0;
    display: block;
    position: static;
    padding: 0 0 0 0.5rem;
    border-radius: 0;
    background-color: inherit;
    transform: translateX(0%);
    transition: height 0.2s ease-out 0s;
  }
  .accordion-sub-menu.open {
    height: auto;
    opacity: 1;
  }
}
</style>
