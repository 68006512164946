<template>
  <div class="core-idea-component">
    <div class="radian-background"></div>
    <div class="core-idea" id="core-idea">
      <item-title
        class="title-box"
        :title="setting.title"
        :sub-title="setting.subTitle"
      ></item-title>
      <div class="core-content">
        <div class="left-side wow fadeInLeft">
          <img :src="setting.imageUrl" :alt="setting.descTitle" />
        </div>
        <div class="right-side">
          <h1 class="wow fadeInUp" data-wow-delay="0.1s">
            {{ setting.descTitle }}
          </h1>
          <p class="wow fadeInUp" data-wow-delay="0.3s">
            {{ setting.descText }}
          </p>
          <ul class="advantage">
            <template v-for="(item, index) in setting.descIcons">
              <li class="wow fadeInUp" data-wow-delay="0.4s" :key="index">
                <img :src="item.iconUrl" :alt="item.iconText" />
                <span>{{ item.iconText }}</span>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="radian-background-white"></div>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "CoreIdea",
  components: {
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.radian-background {
  width: 100%;
  height: 0.8rem;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/KvEIQXJi.png");
  background-size: 100% 100%;
}
.radian-background-white {
  width: 100%;
  height: 0.8rem;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url("https://fairylands-public.oss-cn-hangzhou.aliyuncs.com/image/1UnwHvbc.png");
  background-size: 100% 100%;
}
.core-idea {
  background-color: #f8f8f8;
}
.core-content {
  padding: 0 1rem 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 0;
}

.core-content .right-side {
  width: 8.3rem;
}

.core-content .right-side h1 {
  font-size: var(--h3title-size);
  color: #2178c9;
  font-weight: normal;
  margin-bottom: 0.35rem;
}
.core-content .right-side h1 {
  color: #222222;
}

.core-content .right-side p {
  font-size: var(--text-size);
  color: #222222;
  text-align: justify;
  margin-bottom: 0.45rem;
  word-break: break-all;
}
.core-content .right-side p {
  margin-bottom: 0.8rem;
}
.core-content .left-side {
  width: 8.3rem;
}
.core-content .left-side img {
  width: 100%;
}
.core-content .right-side .advantage {
  display: flex;
}
.core-content .right-side .advantage li {
  /*position: relative;*/
  /*margin-right: 0.8rem;*/
  /*text-align: center;*/
  /*width: 2rem;*/
  position: relative;
  text-align: center;
  flex: 1;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.core-content .right-side .advantage li img {
  width: 1rem;
}
.core-content .right-side .advantage li span {
  font-size: var(--text-size);
  color: #222222;
  display: block;
}
@media all and (max-width: 960px) {
  .core-content {
    flex-direction: column;
  }
  .core-content .left-side {
    width: 100%;
    order: 2;
  }
  .core-content .right-side {
    width: 100%;
    order: 1;
    margin-bottom: 0.8rem;
  }
  .core-content .right-side .advantage li {
    width: 3rem;
  }
  .core-content .right-side .advantage li img {
    width: 1.5rem;
  }
  .core-content .right-side .advantage li span {
    font-size: 0.4rem;
  }
}
</style>
