<template>
  <div class="error-404">
    <div class="error-contant">
      <img src="../../assets/images/logo-black.png" />
      <div class="err-code">404</div>
      <div class="err-text">
        The page you are looking for does not exist.<br />You can click the button below to return to the home page.
      </div>
      <FlButton @click="handelToHome">Home</FlButton>
    </div>
  </div>
</template>

<script>
import FlButton from "@/components/FlButton/FlButton";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Error404",
  components: {
    FlButton,
  },
  computed: {},
  mounted() {},
  methods: {
    handelToHome() {
      window.location.href = "/";
      // this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.error-404 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-contant {
  max-width: 50%;
  text-align: center;
  font-size: 16px;
}
.error-contant .err-code {
  font-size: 36px;
  font-weight: 600;
  color: #135294;
  margin: 10px 0;
}
.error-contant .err-text {
  margin: 10px 0;
}
</style>
