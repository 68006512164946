<template>
  <div class="global-mask" @click="handleMaskClick"></div>
</template>

<script>
export default {
  name: "GlobalMask",
  methods: {
    handleMaskClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.global-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  background-color: #00000073;
}
</style>
