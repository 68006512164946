var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-header",class:{
    'top-fixed': _vm.topFixed,
    'absolute-position': _vm.positionStatus,
    'open-menu': _vm.openMenu,
  }},[_c('div',{staticClass:"header-logo"},[_c('a',{attrs:{"href":"/","title":"Home"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.topFixed || _vm.openMenu
            ? _vm.logoImageUrls.colorLogo
            : _vm.logoImageUrls.whiteLogo}})])]),_vm._v(" "),_c('div',{staticClass:"mobile-control-btn"},[_c('svg-icon',{attrs:{"name":_vm.openMenu ? 'menu-close-btn' : 'menu-btn'},on:{"click":function($event){return _vm.handleControlMenu(false)}}})],1),_vm._v(" "),_c('div',{staticClass:"header-menu"},[_c('nav-menu',{attrs:{"menu-data":_vm.menuData,"topFixed":_vm.topFixed},on:{"mobileMenuChange":_vm.changeHeaderStatus}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }