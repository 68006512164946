import {
  Pagination,
  Input,
  Empty,
  Icon,
  notification,
  Spin,
} from "ant-design-vue";
import Footer from "@/components/Footer/Footer";
import SvgIcon from "@/components/SvgIcon/SvgIcon";
import PageComponent from "@/components/PageComponent/PageComponent.jsx";
import Router from "vue-router";
import Vuex from "vuex";

export default {
  install(Vue) {
    if (Vue._use_components) {
      return;
    }
    Vue.use(Router);
    Vue.use(Vuex);
    Vue.prototype.$notification = notification;
    Vue.component(Pagination.name, Pagination);
    Vue.component(Spin.name, Spin);
    Vue.component(Input.name, Input);
    Vue.component(Empty.name, Empty);
    Vue.component(Icon.name, Icon);
    // eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
    Vue.component("Footer", Footer);
    Vue.component("SvgIcon", SvgIcon);
    Vue.component(PageComponent.name || "PageComponent", PageComponent);
    Vue._use_components = true;
  },
};
