<template>
  <div class="contact-us-container">
    <div class="radian-background-darkBlue"></div>
    <div class="contact-us">
      <home-item-title
        class="title-content"
        :title="setting.title"
        :sub-title="setting.subTitle"
        sub-title-color="#ffffff"
        title-color="#ffffff"
      ></home-item-title>
      <div class="contact-info">
        <div class="left-side">
          <ul class="company-info">
            <li data-wow-delay="0.2s" class="number-info wow fadeInUp">
              <img
                src="../../../../assets/images/service-phone-icon.png"
                alt=""
                srcset=""
              />
              <div class="content-text">
                <span>Tel.:</span>
                <span>{{
                  configData["service_phone"] || "0755-33162009"
                }}</span>
              </div>
            </li>
            <li data-wow-delay="0.3s" class="number-info wow fadeInUp">
              <img
                src="../../../../assets/images/customer-service-phone-icon.png"
                alt=""
                srcset=""
              />
              <div class="content-text">
                <span>Product Hotline:</span>
                <span>{{
                  configData["customer_service_phone"] || "400-0299-959"
                }}</span>
              </div>
            </li>
            <li data-wow-delay="0.2s" class="number-info wow fadeInUp">
              <img
                src="../../../../assets/images/zip-code-icon.png"
                alt=""
                srcset=""
              />
              <div class="content-text">
                <span>Zip Code:</span>
                <span>{{ configData["zip_code"] || "518 055" }}</span>
              </div>
            </li>
            <li data-wow-delay="0.2s" class="wow fadeInUp">
              <img
                src="../../../../assets/images/company-email-icon.png"
                alt=""
                srcset=""
              />
              <div class="content-text">
                <span>Email Address:</span>
                <span>{{
                  configData["content_us_email"] || "market@fairylands.com.cn"
                }}</span>
              </div>
            </li>
            <li data-wow-delay="0.4s" class="address wow fadeInUp">
              <img
                src="../../../../assets/images/contact-address-icon.png"
                alt=""
                srcset=""
              />
              <div class="content-text">
                <span>Address:</span>
                <span>{{
                  configData["company_address"] ||
                  "11/F, Block A, Building 8, Shenzhen International Innovation Valley, Nanshan District, Shenzhen"
                }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="right-side wow fadeInUp">
          <input
            type="text"
            placeholder="Name"
            v-model="leaveName"
            maxlength="10"
          />
          <input type="text" placeholder="Email" v-model="mobile" />
          <textarea
            name=""
            id=""
            placeholder="Message"
            v-model="content"
          ></textarea>
          <FlButton
            class="contact-us-btn"
            @click="submitMessage"
            :loading="sendLoad"
          >
            Submit
          </FlButton>
          <div class="privacy-policy-text">
            <a href="/about/privacy-policy">
              click here for our privacy policy >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "ant-design-vue/lib/notification";
import { getCookie } from "@/utils/util";
import { submitMessage } from "@/api/public";
import FlButton from "@/components/FlButton/FlButton.vue";
import HomeItemTitle from "@/components/HomeItemTitle/HomeItemTitle.vue";
import { mapGetters } from "vuex";

export default {
  name: "ContactUs",
  components: {
    HomeItemTitle,
    FlButton,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
  data() {
    return {
      leaveName: "",
      mobile: "",
      content: "",
      sendLoad: false,
    };
  },
  computed: {
    ...mapGetters(["configData"]),
  },
  methods: {
    submitMessage() {
      if (this.$store.getters.getConfigValue("is_sure_message") == 2) {
        notification.error({
          message: "Kind reminder",
          description: "The message feature has been disabled.",
        });
        return;
      }
      let { leaveName, mobile, content } = this;
      if (leaveName == "") {
        notification.warning({
          message: "Kind reminder",
          description: "Please enter your name",
        });
        return;
      }
      if (leaveName.length > 50) {
        notification.warning({
          message: "Kind reminder",
          description:
            "The number of the entered name characters cannot exceed 50.",
        });
        return;
      }
      if (mobile == "") {
        notification.warning({
          message: "Kind reminder",
          description: "Please enter your mobilephone number.",
        });
        return;
      }
      if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(mobile)) {
        notification.warning({
          message: "Kind reminder",
          description: "Incorrect Email format",
        });
        return;
      }
      if (content == "") {
        notification.warning({
          message: "Kind reminder",
          description: "Please leave a message.",
        });
        return;
      }

      let sendMsgTime = getCookie("sendMsgTime");
      if (sendMsgTime) {
        let nowTime = parseInt(new Date().getTime() / 1000);
        sendMsgTime = parseInt(new Date(sendMsgTime - 0).getTime() / 1000);
        if (sendMsgTime - nowTime > 0) {
          notification.warning({
            message: "Kind reminder",
            description:
              "The interval between messages is too short. Please try again later!",
          });
          return;
        }
      }
      this.sendLoad = true;
      console.log({ leaveName, mobile, content });
      submitMessage({ leaveName, mobile, content })
        .then(() => {
          this.leaveName = "";
          this.mobile = "";
          this.content = "";
          let expdate = new Date();
          expdate.setTime(expdate.getTime() + 1 * 60 * 1000);
          document.cookie = `sendMsgTime=${expdate.getTime()};expires=${expdate.toGMTString()};path=/`;
          notification.success({
            message: "Kind reminder",
            description: "Message Successful.",
          });
        })
        .catch(() => {
          notification.error({
            message: "Kind reminder",
            description: "Message Failed.",
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
  },
};
</script>

<style scoped>
.radian-background-darkBlue {
  width: 100%;
  background: url("../../../../assets/images/radian-background-darkBlue.png")
    no-repeat center top #f8f8f8;
  background-size: 100% 100%;
  height: 0.8rem;
}

.contact-us {
  background-color: #042350;
  margin-top: -1px;
  padding-bottom: 1px;
}
.contact-us .title-content {
  padding: 1rem 1rem 0.7rem;
}
.contact-us .contact-info {
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 1.28rem 1rem;
  display: flex;
}
.contact-us .contact-info .left-side {
  width: calc(100vw * 0.5395);
}

.contact-us .contact-info .left-side .company-info {
  position: relative;
  /*padding-bottom: 0.55rem;*/
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.1);*/
  display: flex;
  /*justify-content: space-between;*/
  flex-wrap: wrap;
}
/*.contact-us .contact-info .left-side .company-info::after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  left: 0;*/
/*  top: calc(50% - 0.25rem);*/
/*  width: 100%;*/
/*  height: 1px;*/
/*  background-color: rgba(255, 255, 255, 0.1);*/
/*  transform: scaleY(0.5);*/
/*}*/

.contact-us .contact-info .left-side .company-info li {
  display: flex;
  align-content: center;
  align-items: center;
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
  /*max-width: 50%;*/
}

.contact-us .contact-info .left-side .company-info li img,
.contact-us .contact-info .left-side .company-info-box li img {
  height: auto;
  margin-right: 0.2rem;
  width: 0.5rem;
}
.contact-us .contact-info .left-side .company-info li div span,
.contact-us .contact-info .left-side .company-info-box li div span {
  color: #ffffff;
  display: block;
  font-size: var(--text-size);
}
.company-info li .content-text span:nth-child(2),
.company-info-box li .content-text span:nth-child(2) {
  font-family: "DINPro";
}
.company-info-box li .content-text span:nth-child(2) {
  margin-top: 0.05rem;
}
.contact-us .contact-info .right-side {
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: calc(100vw * 0.3479);
  height: calc(100vw * 0.171875);
  border-radius: 0.1rem;
  padding: 0.6rem;
}

.contact-us .contact-info .right-side input {
  border: none;
  display: block;
  color: #2178c9;
  outline: none;
  font-family: "DINPro";
  position: relative;
  border-bottom: 1px solid rgba(136, 136, 136, 0.1);
  font-size: var(--text-size);
  padding: calc(100vw * 0.0104) 0;
  width: calc(50% - (100vw * 0.0104));
}
.contact-us .contact-info .right-side input:focus,
.contact-us .contact-info .right-side textarea:focus {
  border-bottom: 2px solid #2178c9;
}
.contact-us .contact-info .right-side textarea {
  border: none;
  border-bottom: 1px solid rgba(136, 136, 136, 0.1);
  width: 100%;
  outline: none;
  resize: none;
  font-size: var(--text-size);
  height: calc(100vw * 0.052);
  margin-top: calc(100vw * 0.026);
}
.contact-us .contact-info .right-side .contact-us-btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
}

.contact-us textarea:-ms-input-placeholder,
.contact-us input:-ms-input-placeholder {
  color: #757575;
  font-family: "DINPro";
}
@media all and (max-width: 1236px) {
  .contact-us .contact-info .left-side .company-info li {
    max-width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .radian-background-darkBlue {
    height: 2rem;
  }
  .contact-us .contact-info {
    padding: 0 0.5rem 5rem;
    flex-direction: column;
  }
  .contact-us .contact-info .left-side {
    width: 100%;
  }
  .contact-us .contact-info .left-side .company-info {
    padding: 2rem 0;
    /*flex-direction: column;*/
    flex-wrap: wrap;
    display: inline-flex;
  }

  .contact-us .contact-info .left-side .company-info::after {
    content: "";
    display: none;
  }
  .contact-us .contact-info .left-side .company-info li {
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-right: 0.5rem;
    width: calc(50% - 1.5rem);
    max-width: 100%;
  }
  .contact-us .contact-info .left-side .company-info li.address {
    width: 100%;
  }
  .contact-us .contact-info .left-side .company-info li img,
  .contact-us .contact-info .left-side .company-info-box li img {
    margin-right: 0.5rem;
    width: 2rem;
  }

  .contact-us .contact-info .left-side .company-info-box {
    padding: 2rem 0;
  }
  .contact-us .contact-info .right-side {
    width: 100%;
    border-radius: 0.5rem;
    padding: 1.8rem 1.5rem;
    height: auto;
  }
  .contact-us .contact-info .right-side input {
    padding: 0.2rem 0;
    width: calc(50% - (100vw * 0.0104));
  }
  .contact-us .contact-info .right-side textarea {
    height: 3rem;
    margin-top: 1.5rem;
  }
}
.privacy-policy-text {
  margin-top: 1rem;
  text-align: center;
  font-size: var(--text-size);
  color: #efefef;
  width: 100%;
  text-decoration: underline;
  transition: color 0.3s linear;
}
.privacy-policy-text:hover {
  color: #ffffff;
}
</style>
