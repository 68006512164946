<template>
  <div class="wave_grey_container_component">
    <div class="radian-box-top" v-if="setting.topBack != false"></div>
    <div class="slot_container">
      <page-component :page-data="setting.slotComponent"></page-component>
    </div>
    <div class="radian-box-bottom" v-if="setting.bottom != false"></div>
  </div>
</template>

<script>
export default {
  name: "WaveGrey",
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.slot_container {
  background-color: #f8f8f8;
}
.radian-box-top {
  background: url("../../../../assets/images/radian-background.png") no-repeat
    center top;
  background-size: 100% 100%;
}
.radian-box-bottom {
  width: 100%;
  background: url("../../../../assets/images/radian-background-bottom.png")
    no-repeat center top;
  background-size: 100% 100%;
}
@media screen and (min-width: 961px) {
  .radian-box-top {
    height: 0.9rem;
    margin-top: 1.1rem;
  }
  .radian-box-bottom {
    height: 0.8rem;
  }
}
@media screen and (max-width: 960px) {
  .radian-box-top {
    height: 2rem;
    margin-top: 1rem;
  }
  .radian-box-bottom {
    height: 2rem;
  }
}
</style>
