<template>
  <div class="market-component">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <p class="describe wow fadeInUp" v-html="setting.descText"></p>
    <ul class="complete-target" id="completeTarget">
      <li class="wow fadeInUp">
        <span>Country</span>
        <vue-count-to
          ref="countTo1"
          :startVal="0"
          :endVal="setting.nationNum || 4"
          :duration="1000"
          :autoplay="countToAutoplay"
          suffix="+"
        ></vue-count-to>
      </li>
      <li class="wow fadeInUp">
        <span>Province</span>
        <vue-count-to
          ref="countTo2"
          :startVal="0"
          :endVal="setting.provinceNum || 10"
          :duration="1000"
          :autoplay="countToAutoplay"
          suffix="+"
        ></vue-count-to>
      </li>
      <li class="wow fadeInUp">
        <span>Project</span>
        <vue-count-to
          ref="countTo3"
          :startVal="0"
          :endVal="setting.projectNum || 100"
          :duration="1000"
          :autoplay="countToAutoplay"
          suffix="+"
        ></vue-count-to>
      </li>
    </ul>
    <p
      class="complete-layout-region wow fadeInUp"
      v-html="setting.projectDesc"
    ></p>
    <img :src="setting.imageUrl" alt="" class="market_pic wow fadeInUp" />
  </div>
</template>

<script>
import VueCountTo from "@/components/vueCountTo/VueCountTo";
import ItemTitle from "@/components/ItemTitle/ItemTitle";
import { screenSee } from "@/utils/util";

export default {
  name: "MarketLayout",
  components: { VueCountTo, ItemTitle },
  props: {
    setting: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      countToAutoplay: false,
      countToStart: false,
    };
  },
  mounted() {
    if (this.$isServer) return;
    window.addEventListener("scroll", this.scroll);
    this.scroll();
  },
  methods: {
    scroll() {
      const isSee = screenSee(document.getElementById("completeTarget"));
      if (isSee && !this.countToStart) {
        this.countToStart = true;
        this.$refs.countTo1.start();
        this.$refs.countTo2.start();
        this.$refs.countTo3.start();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },
};
</script>

<style scoped>
@keyframes borderWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.describe {
  text-align: center;
  font-size: var(--describe-text-size);
}
.complete-target {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.complete-target {
  margin: 0.8rem 0 0.5rem;
}
.complete-target li {
  margin: 0 0.5rem;
  width: 80px;
}
.complete-target li span {
  font-size: var(--subtitle-size);
  display: block;
  text-align: center;
  color: #2178c9;
}
.complete-target li span:last-child {
  font-size: calc(var(--subtitle-size) * 2);
  /*margin-top: 0.5rem;*/
}
.complete-target li span:last-child em {
  font-style: normal;
  font-family: "DINPro";
}
.complete-layout-region {
  font-size: var(--text-size);
  color: #888888;
  text-align: center;
  padding: 0 2rem;
}
.complete-layout-region span {
  display: block;
}
.market_pic {
  margin: 1rem auto 2.4rem;
  display: block;
  width: calc(100vw / 1920 * 1349);
  max-width: 100%;
  text-align: center;
}
@media all and (max-width: 960px) {
  .market-component {
    padding: 0 0.5rem;
  }
  .market_pic {
    width: 100%;
  }
  .complete-layout-region span {
    display: inline;
  }
  .complete-target li {
    width: 8rem;
    margin: 0 0.5rem;
  }
  .complete-target li span:last-child {
    font-size: calc(var(--subtitle-size) * 2);
    margin-top: 0.5rem;
  }
  .complete-layout-region {
    /*text-align: left;*/
    padding: 0 1rem;
  }
}
</style>
