<template>
  <div class="patent-certificate" id="patent-certificate">
    <item-title
      class="title-box"
      :title="setting.title"
      :sub-title="setting.subTitle"
    ></item-title>
    <ul class="patent-box">
      <template v-for="(item, index) in setting.certificationsList">
        <li :key="index">
          <div class="wow fadeInLeft">
            <span>{{ item.title }}</span>
            <span>{{ item.subTitle }}</span>
          </div>
          <img class="wow fadeInRight" :src="item.imageUel" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ItemTitle from "@/components/ItemTitle/ItemTitle";
export default {
  name: "PatentCertifications",
  components: {
    ItemTitle,
  },
  props: {
    setting: {
      type: [String, Object],
      require: true,
    },
  },
};
</script>

<style scoped>
.title-box {
  padding: 1rem 1rem 0.7rem;
}
.patent-certificate {
  padding-bottom: 1.2rem;
}
.patent-certificate .patent-box {
  padding: 0 1rem;
  margin-top: 0.3rem;
}
.patent-certificate .patent-box li {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.patent-certificate .patent-box li:not(:last-child) {
  margin-bottom: 0.6rem;
}
.patent-certificate .patent-box li div span {
  display: block;
}
.patent-certificate .patent-box li div span:nth-child(1) {
  color: #222222;
  font-size: var(--h3title-size);
}
.patent-certificate .patent-box li div span:nth-child(2) {
  color: #888888;
  font-size: var(--text-size);
  margin-top: 0.2rem;
  font-family: "DINPro";
}
.patent-certificate .patent-box li img {
  width: 14.2rem;
}
@media all and (max-width: 960px) {
  .patent-certificate .patent-box li {
    flex-direction: column;
  }
  .patent-certificate .patent-box li img {
    width: 100%;
    margin-top: 0.5rem;
  }
  .patent-certificate .patent-box li div {
    text-align: center;
  }
}
</style>
