import { defineComponent } from "vue";
import PageComponentItem from "@/components/PageComponent/PageComponentItem.jsx";
// import { PageComponentContextKey } from "@/components/PageComponent/context";

export default defineComponent({
  name: "PageComponent",
  props: {
    pageData: {
      type: [Array, Object],
      required: true,
    },
    pagePath: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    return () => {
      let { pageData, pagePath } = props;
      const items = Array.isArray(pageData) ? pageData : [pageData];
      return (
        <div>
          {items.map((cItem, index) => (
            <PageComponentItem
              index={index}
              componentOptions={cItem}
              key={pagePath + cItem.componentName + index}
            />
          ))}
        </div>
      );
    };
  },
});
